import React, {useEffect, useState} from 'react';
import Colors from "../../../../global/styles/Colors";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";
import InputPhoneMask from "../../../../components/kit/InputPhoneMask/InputPhoneMask";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";
import ReactCodeInput from 'react-code-input'

const AuthUser = ({handleBackNav, handleBackByAuth}) => {

    const [phone, setPhone] = useState("");

    const [showCode, toggleShowCode] = useState(false);

    const [code, setCode] = useState('')

    let [time, setTime] = useState(20);

    useEffect(() => {
        let interval;

        if (time > 0 && showCode) {
            interval = setInterval(function () {
                setTime(time - 1)
            }, 1000)
        }

        return () => clearInterval(interval)
    }, [showCode, time])


    function handlePhoneChange(e) {
        e.persist();
        setPhone(e.target.value)
    }

    function onSendPhoneNumber() {
        // if (!showCode) {
        //     toggleShowCode(true)
        // }
        // else {
        handleBackByAuth(code)
        // }
    }

    function handlerCodeInput(value) {
        setCode(value)
    }

    function resendCode() {
        setTime(20)
    }

    const disabled = !showCode ? phone.length < 17 : code.length < 4;

    return (
        <div className="auth-user-online">
            <h3 className="service-variant-root__title" style={{color: Colors.NEUTRAL['black']}}>
                <span className="record-nav-back-icon">
                   <ArrowLargeIcon onClick={handleBackNav}/>
               </span>
                <span>{showCode ? "СМС код" : "Номер"}</span>
                <span className="empty"/>
            </h3>
            <p className="auth-user-online__sub-title">
                {showCode ? "Введите смс код для подтверждения записи" : "Укажите номер для подтверждения"}
            </p>

            <div className="auth-user-online__phone-number">
                    <InputPhoneMask
                        className="select-date-root__phone-field"
                        value={phone}
                        propertyName="phone"
                        onChange={handlePhoneChange}
                    />
            </div>

            {showCode && (
                <GeneralButton
                    bgColor={time > 0 ? Colors.NEUTRAL['border'] : Colors.SEMANTIC['pink']}
                    className="auth-user-online__btn --repeat"
                    disabled={time > 0}
                    title="Повторить отправку кода"
                    textColor={time > 0 ? Colors.TEXT['text_color'] : Colors.NEUTRAL['white']}
                    onClick={resendCode}/>
            )}

            <GeneralButton
                bgColor={disabled ? Colors.NEUTRAL['border'] : Colors.SEMANTIC['pink']}
                className="auth-user-online__btn"
                disabled={disabled}
                title={"Далее"}
                textColor={disabled ? Colors.TEXT['text_color'] : Colors.NEUTRAL['white']}
                onClick={onSendPhoneNumber}/>
        </div>
    );
};

export default AuthUser;