import React from 'react';

const HeartPinkIcon = ({width, height, className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 16 15"
             fill="none">
            <path
                d="M16 5.60406C16 6.93465 15.4958 8.15323 14.4133 9.43773C13.4447 10.5872 12.053 11.7538 10.441 13.1046C9.89026 13.5661 9.26611 14.0893 8.61853 14.6467C8.44885 14.7926 8.23145 14.8737 8.00562 14.875H8C7.77222 14.875 7.55261 14.7938 7.38147 14.6464C6.73511 14.0905 6.11194 13.5679 5.56213 13.107L5.55933 13.105C3.94763 11.754 2.5553 10.5872 1.58691 9.43773C0.50415 8.15323 0 6.93465 0 5.60406C0 4.31116 0.450806 3.11815 1.26929 2.24497C2.09766 1.36135 3.23413 0.875 4.46973 0.875C5.39343 0.875 6.23901 1.16194 6.98376 1.72825C7.35962 2.01422 7.70007 2.36383 8 2.77215L8.00562 2.76482C8.30432 2.35963 8.64294 2.01242 9.01648 1.72825C9.76099 1.16194 10.6069 0.875 11.5303 0.875C12.7661 0.875 13.9026 1.36135 14.7307 2.24497C15.5492 3.11815 16 4.3108 16 5.60406Z"
                fill="#FF647C"/>
        </svg>
    );
};

export default HeartPinkIcon;