import React from 'react';
import {ClipLoader} from "react-spinners";
import './index.scss';

const CommonSpinner = ({isLoading, size = 20, color = "#0084F4", centered, className}) => {
    return (
        <div className={["common-spinner",
            `${centered ? "common-spinner--centered" : ""}`,
            className].join(" ")}>
            <ClipLoader
                size={size}
                color={color}
                loading={isLoading}
            />
        </div>
    );
};

export default CommonSpinner;