import axios from "../../Api/axios-api";
import {ROUTE_MASTER_PAGE} from "../../Api/Routes";

export const FETCH_PAGE_MASTER_REQUEST = 'FETCH_PAGE_MASTER_REQUEST'
export const FETCH_PAGE_MASTER_SUCCESS = 'FETCH_PAGE_MASTER_SUCCESS'

const fetchPageMasterRequest = () => ({type: FETCH_PAGE_MASTER_REQUEST})
const fetchPageMasterSuccess = data => ({type: FETCH_PAGE_MASTER_SUCCESS, value: data})

export const getListMaster = () => {
    return dispatch => {
        dispatch(fetchPageMasterRequest())
        return axios.get(ROUTE_MASTER_PAGE)
            .then(response => {
                dispatch(fetchPageMasterSuccess(response.data))
            })
    }
}

export const FETCH_PAGE_PAGINATION_REQUEST = 'FETCH_PAGE_PAGINATION_REQUEST'
export const FETCH_PAGE_PAGINATION_SUCCESS = 'FETCH_PAGE_PAGINATION_SUCCESS'

const fetchPagePaginationRequest = () => ({type: FETCH_PAGE_PAGINATION_REQUEST})
const fetchPagePaginationSuccess = data => ({type: FETCH_PAGE_PAGINATION_SUCCESS, value: data})

export const getListMasterPagination = (url) => {
    return dispatch => {
        dispatch(fetchPagePaginationRequest())
        return axios.get(url)
            .then(response => {
                dispatch(fetchPagePaginationSuccess(response.data))
            })
    }
}

