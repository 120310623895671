import React from 'react';
import Colors from "../../../global/styles/Colors";
import './index.scss';

const TimeButton = ({time, disabled, selected, onClick}) => {
    const textColor = selected ? Colors.NEUTRAL['white'] : Colors.NEUTRAL['gray_purple'];
    const bgColor = selected ? Colors.PRIMARY['purple'] : Colors.NEUTRAL['white'];
    return (
        <button className="time-button-root" disabled={disabled} onClick={() => onClick(time)}
                style={{backgroundColor: bgColor}}>
            <span className="time-button-root__time"
                  style={{color: disabled ? Colors.NEUTRAL['gray_purple'] : textColor}}>{time}</span>
        </button>
    );
};

export default TimeButton;