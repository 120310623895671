import React from 'react';
import {InputBase, withStyles} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import './index.scss'
import Colors from "../../../global/styles/Colors";

const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${Colors.NEUTRAL['border']}`,
        fontSize: 16,
        padding: '19px 26px 19px 12px',
        width: "100%",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#3f51b5',
            outline: 'none',
            borderWidth: '2px'
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: "10px 0",
        width: "100%"
    },
}));

const CustomSelect = (
    {
        value,
        handleChange,
        options,
        defaultValue,
        className,
        propertyName,
        placeholder,
        ...props
    }
) => {

    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={[classes.margin, "custom-select", className].join(" ")}>
            <InputLabel id={`${propertyName}-label`}>{placeholder}</InputLabel>
            <Select
                value={value}
                name={propertyName}
                onChange={handleChange}
                // label="Salon"
                labelId={`${propertyName}-label`}
                input={<BootstrapInput/>}
                {...props}
            >
                {
                    options && options.map((option, ndx) => (
                        <MenuItem key={`${ndx}_${Math.random()}`} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export default CustomSelect;