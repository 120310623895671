import {
  FETCH_SALON_PAY_METHOD_REQUEST,
  FETCH_SALON_PAY_METHOD_SUCCESS,
  FETCH_SALON_PAY_METHOD_FAILURE,
} from "./payMethodAction";

const initialState = {
  payMethods: null,
  response: false,
  error: false,
};

export const payMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SALON_PAY_METHOD_REQUEST:
      return { ...state, response: true };
    case FETCH_SALON_PAY_METHOD_SUCCESS:
      return { ...state, payMethods: action.payMethod, response: false };
    case FETCH_SALON_PAY_METHOD_FAILURE:
      return { ...state, error: true };
    default:
      return state;
  }
};
