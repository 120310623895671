const MONTH_CASED_TRANSLATE = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря'
];

export const convertDateWithMonthName = (datetime, withYear) => {
    const date = new Date(datetime);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const monthTranslate = MONTH_CASED_TRANSLATE[month];

    return `${day} ${monthTranslate} ${withYear ? year : ""}`
};