import React from 'react';

import './index.scss'

const Description = ({text, title}) => {

    return (
        <div className="description information-salon">
            <h4>{title}</h4>

            <p>{text}</p>
        </div>
    );

};

export default Description;