export const baseUrl = 'https://api.via-online.com/';
export const baseURLMain = 'https://via-online.com/'

export const ROUTE_SALONS = '/salons/my/'
export const ROUTE_CURRENT_TARRIF = '/current_tariff/'
export const ROUTE_AVAILABLE_TARIFFS = '/available_tariffs/'
export const ROUTE_CHANGE_TARIFF = '/change_tariff/'



export const ROUTE_SALONS_ROOT = 'salons/'
export const ROUTE_CALENDAR_EVENTS = 'get_mtime/'

export const ROUTE_PAY_METHOD = '/payment/methods/'

export const ROUTE_PAY_MBANK_METHOD = '/payment/mbank/'

export const ROUTE_APPLICATIONS = '/applications/'

export const ROUTE_STAFFS = '/employees/'

export const ROUTE_CATEGORIES = '/categories/'

export const ROUTE_SERVICES = '/services/'

export const ROUTE_REPORT = '/report/masters/'

export const ROUTE_AUTH = '/rest-auth/login/'

export const ROUTE_FREE_TIME_GET = '/master/free-time/'

export const ROUTE_MASTER_AVAILABLE_TIMES = '/get_m_time_mob/'

export const MAIN_PAGE = 'main/'

export const ROUTE_CATEGORIES_PAGE = '/main/categories/'

export const ROUTE_MASTER_PAGE = '/main/employees/'

export const ROUTE_SALON_PAGE = '/main/salons/'

export const ROUTE_FILTER = '/main/sub_categories/'

export const ROUTE_FILTER_SALON = '/main/salons/?sub_categories='

export const ROUTE_FILTER_MASTER = '/main/employees/?sub_categories='

// Online
export const ROUTE_APPLICATION_CREATE = 'applications/'
export const ROUTE_APPLICATION_CLIENT = 'applications/client/'

//Refresh token
export const ROUTE_REFRESH_TOKEN = '/user/firebase_token/add/'

//Otp
export const ROUTE_SEND_OTP = '/send_otp/'
export const ROUTE_CHECK_OTP = '/check_otp/'

//List Masters and Salon
export const ROUTE_LIST_MASTERS_OF_SERVICES = '/main/employees/?main_category='
export const ROUTE_LIST_SALONS_OF_SERVICES = '/main/salons/?main_category='

//Balance
export const ROUTE_GET_BALANCE = '/payment/nikita_balance/'