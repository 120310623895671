export const dateFormatter = value => {
    const date = new Date(value);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
};

export const getHourByMinutes = (minutes) => {
    const result = Math.floor(Number(minutes) / 60)
    return `${result > 0 ? result.toFixed(0) : 0}ч`
}

export const getRemainingMinutes = minutes => {
    return `${Number(minutes) % 60}мин`
}

export const groupBy = (array, f) => {
    const groups = {};
    Array.isArray(array) && array.forEach(function (o) {
        const group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    })
};

export const getServices = (data) => {
    const result = []

    data.forEach(item => {
        let service = item?.filter(it => it?.employees?.length > 0)

        if(service.length){
            result.push(service)
        }
    })

    return result
}

export const generateTimeOptions = (min = 6, max = 23) => {
    const times = [];

    for (let i = Number(min); i <= Number(max); i++) {
        const hour = {
            label: i < 10 ? `0${i}:00:00` : `${i}:00:00`,
            id: i < 10 ? `0${i}:00:00` : `${i}:00:00`,
        }

        times.push(hour)
        if (i !== max) {
            const half = {
                label: i < 10 ? `0${i}:30:00` : `${i}:30:00`,
                id: i < 10 ? `0${i}:30:00` : `${i}:30:00`,
            }
            times.push(half)
        }
    }

    return times

}

export const getDefaultPage = (route) => {
    switch (route){
        case 'default':
            return true
        case undefined:
            return undefined
        default: return true
    }
}