import React from 'react';
import {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import './index.scss';
import {showMonthName} from "../../../global/Helpers/showMonthForDatePicker";
import Colors from "../../../global/styles/Colors";
import ArrowIcon from "../Icons/ArrowIcon";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";

registerLocale("ru", ru);
const ServiceDatePicker = ({onChange, selectedDate, calendarHandleNextClick, calendarHandlePrevClick}) => {

    const month = selectedDate.getMonth();

    const prevDate = moment(selectedDate).isBefore(moment(new Date()))
    return (
        <div className="date-picker-root">
            <div className="date-picker-root__month" style={{color: Colors.NEUTRAL['purple_black']}}>
                {!prevDate ? (
                        <span
                            className="date-picker-root__left-arrow"
                            style={{backgroundColor: Colors.PRIMARY['purple_3']}}
                            onClick={calendarHandlePrevClick}>
                                <ArrowIcon width={8} height={9} color={Colors.NEUTRAL['purple_black']}/>
                </span>
                    ) :
                    <span className="date-picker-root__left-arrow"
                          style={{backgroundColor: Colors.PRIMARY['purple_3']}}>
                            <ArrowIcon width={8} height={9} color={Colors.NEUTRAL['gray_purple']}/>
                    </span>
                }
                <h4 className="date-picker-root__month-title" style={{color: Colors.NEUTRAL['purple_black']}}>
                    {showMonthName(month)}
                </h4>
                <span className="date-picker-root__right-arrow" style={{backgroundColor: Colors.PRIMARY['purple_3']}}
                      onClick={calendarHandleNextClick}>
                    <ArrowIcon width={8} height={9} color={Colors.PRIMARY['purple']}/>
                </span>
            </div>
            <Calendar
                onChange={onChange}
                value={selectedDate}
                className="date-picker-root__calendar"
                next2Label={false}
                prev2Label={false}
                minDate={new Date()}
                onClickDay={onChange}
            />
        </div>
    );
};

export default ServiceDatePicker;