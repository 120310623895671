import axios from "../Api/axios-api";
import axiosBase from 'axios'
import {baseUrl, ROUTE_APPLICATION_CLIENT, ROUTE_APPLICATION_CREATE, ROUTE_APPLICATIONS, ROUTE_SEND_OTP, ROUTE_CHECK_OTP} from "../Api/Routes";
import {dateFormatter} from "../../global/Helpers/helpers";
import {NotificationManager} from 'react-notifications';
import {ERROR_TEXT} from "../../global/Constants/Constants";

export const FETCH_APPLICATIONS_REQUEST = 'FETCH_APPLICATIONS_REQUEST';
export const FETCH_APPLICATIONS_GET = 'FETCH_APPLICATIONS_GET';
const fetchApplicationsRequest = () => ({type: FETCH_APPLICATIONS_REQUEST});
const fetchApplicationsGetSuccess = applications => ({type: FETCH_APPLICATIONS_GET, applications});
export const fetchApplicationsGet = filters => {
    let url = `?page=${filters.page || 1}&salon=${filters.salon || ""}&is_confirmed=${filters.status || ""}`
    if (filters.date) {
        url += `&appointed_date=${dateFormatter(filters.date)}`
    }
    if (filters.search) {
        url += `&search=${filters.search}`
    }
    return dispatch => {
        dispatch(fetchApplicationsRequest())
        return axios.get(ROUTE_APPLICATIONS + url).then(
            response => {
                dispatch(fetchApplicationsGetSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_APPLICATION_INFO = 'FETCH_APPLICATION_INFO';
export const fetchApplicationInfo = info => ({type: FETCH_APPLICATION_INFO, info})
export const getApplication = applicationId => {
    return dispatch => {
        return axios.get(ROUTE_APPLICATIONS + applicationId + "/").then(
            response => {
                dispatch(fetchApplicationInfo(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const CLEAR_APPLICATION = 'CLEAR_APPLICATION'
export const clearApplication = () => ({type: CLEAR_APPLICATION})

export const fetchChangeApplicationStatus = (id, data) => {
    return dispatch => {
        return axios.put(ROUTE_APPLICATIONS + id + "/status/", data).then(
            () => {
                NotificationManager.success("Статус успешно изменен");
            },
            (error) => {
                NotificationManager.error(error.response.data.message || ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_CREATE_APPLICATIONS_SUCCESS = 'FETCH_CREATE_APPLICATIONS_SUCCESS';
export const FETCH_CREATE_APPLICATIONS_CLOSE = 'FETCH_CREATE_APPLICATIONS_CLOSE';
const fetchCreateApplicationSuccess = application => ({type: FETCH_CREATE_APPLICATIONS_SUCCESS, application})

export const closeCreatedModal = () => ({type: FETCH_CREATE_APPLICATIONS_CLOSE})
export const fetchCreateApplicationByOnline = data => {
    const clientKey = localStorage.getItem('client_key');

    return dispatch => {
        return axiosBase.post(baseUrl + ROUTE_APPLICATION_CREATE, data, {
            headers: {
                Authorization: clientKey ? `Token ${clientKey}` : ""
            }
        }).then(
            (response) => {
                dispatch(fetchCreateApplicationSuccess(response.data))
                if (response.data.token) {
                    localStorage.setItem('client_key', response.data.token)
                }
                return response.status
            },
            (error) => {
                NotificationManager.error(JSON.stringify(error.response.data))
            }
        ).catch(error => {
            if(error.response.status === 401){
                localStorage.removeItem('client_key')
            }
            return error.status
        })
    }
}


export const fetchEditClientApplication = (applicationId, data) => {
    const clientKey = localStorage.getItem('client_key');

    return dispatch => {
        return axiosBase.put(baseUrl + ROUTE_APPLICATION_CREATE + applicationId + "/", data,
            {
                headers: {
                    Authorization: clientKey ? `Token ${clientKey}` : ""
                }
            }).then(
            (response) => {
                dispatch(fetchCreateApplicationSuccess(response.data))
            },
            error => {
                NotificationManager.error(JSON.stringify(error.response.data))
            }
        ).catch(error => {
            if(error.response.status === 401){
                localStorage.removeItem('client_key')
            }
        })
    }
}

export const FETCH_CLIENT_RECORDS = 'FETCH_CLIENT_RECORDS';
const fetchClientRecords = records => ({type: FETCH_CLIENT_RECORDS, records})

export const fetchGetClientRecords = () => {
    return dispatch => {
        const clientKey = localStorage.getItem('client_key')
        return axiosBase.get(baseUrl + ROUTE_APPLICATION_CLIENT,
            {
                headers: {
                    Authorization: clientKey ? `Token ${clientKey}` : ""
                }
            }).then(response => {dispatch(fetchClientRecords(response.data))}
        ).catch(error => {
            if(error.response.status === 401){
                localStorage.removeItem('client_key')
            }
        })
    }
}

export const FETCH_SEND_CODE_NUMBER_REQUEST = 'FETCH_SEND_CODE_NUMBER_REQUEST'
const fetchSendCodeNumberRequest = () => ({type: FETCH_SEND_CODE_NUMBER_REQUEST})

export const fetchGetCodeRequest = (phone, salonId) => {
    return dispatch => {
        dispatch(fetchSendCodeNumberRequest)
        axios.post(ROUTE_SEND_OTP, {phone: phone, salon_id: salonId})
    }
}

export const FETCH_CHECK_NUMBER_REQUEST = 'FETCH_CHECK_NUMBER_REQUEST'
export const FETCH_CHECK_NUMBER_SUCCESS = 'FETCH_CHECK_NUMBER_SUCCESS'


const fetchCheckNumberRequest = () => ({type: FETCH_CHECK_NUMBER_REQUEST})
const fetchCheckNumberSuccess = () => ({type: FETCH_CHECK_NUMBER_SUCCESS})

export const fetchCheckCodeRequest = (phone, code) => {
    return dispatch => {
        dispatch(fetchCheckNumberRequest())
        axios.post(ROUTE_CHECK_OTP, {phone: phone, code: code})
            .then(response => {
                dispatch(fetchCheckNumberSuccess())
            })
            .catch(error => {
                console.log('[error]',error)
            })
    }
}


// ROUTE_CHECK_OTP