import React from 'react';
import {Map, Marker, TileLayer} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const coordinates = [42.8667, 74.5667];


const LeafletMap = ({onChange, initCoordinates}) => {


    const addMarker = (e) => {
        onChange(e.latlng)
    }

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });

    L.Marker.prototype.options.icon = DefaultIcon;

    return (
        <div id="map-root">
            <Map
                center={coordinates}
                zoom={11}
                onClick={addMarker}
            >
                <TileLayer attribution='&copy; <a href="http://osm.org/copyright"/>'
                           url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'/>
                {initCoordinates && (
                    <Marker position={initCoordinates}/>
                )}
            </Map>
        </div>
    );
};

export default LeafletMap;