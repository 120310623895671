import React from 'react';
import Colors from "../../../global/styles/Colors";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import './index.scss';
import moment from "moment";
import TimeIcon from "../../../components/kit/Icons/TimeIcon";

const FinanceMessageElement = ({data}) => {
    return (
        <TableRow>
            <TableCell align="left">
                <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data?.id}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    <TimeIcon width={16} height={16} color={Colors.NEUTRAL['gray_purple']}
                              className="dashboard-notifications-root__item-time-icon"/>
                    {moment(data?.created_date).format('DD-MM-YYYY')}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data?.phone}
                </p>
            </TableCell>
        </TableRow>
    );
};

export default FinanceMessageElement;