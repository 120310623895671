import React from 'react';

const SearchIcon = ({width, height, color, className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 16 16"
             fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.3335 2.66659C4.75617 2.66659 2.66683 4.75592 2.66683 7.33325C2.66683 9.91058 4.75617 11.9999 7.3335 11.9999C9.91083 11.9999 12.0002 9.91058 12.0002 7.33325C12.0002 4.75592 9.91083 2.66659 7.3335 2.66659ZM1.3335 7.33325C1.3335 4.01954 4.01979 1.33325 7.3335 1.33325C10.6472 1.33325 13.3335 4.01954 13.3335 7.33325C13.3335 10.647 10.6472 13.3333 7.3335 13.3333C4.01979 13.3333 1.3335 10.647 1.3335 7.33325Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.6284 10.6286C10.8887 10.3683 11.3108 10.3683 11.5712 10.6286L14.4712 13.5286C14.7315 13.789 14.7315 14.2111 14.4712 14.4714C14.2108 14.7318 13.7887 14.7318 13.5284 14.4714L10.6284 11.5714C10.368 11.3111 10.368 10.889 10.6284 10.6286Z"
                  fill={color}/>
        </svg>
    );
};

export default SearchIcon;