import React, {useEffect, useState} from 'react';
import {connect} from "react-redux"

import Arrow from "../../../../components/kit/Arrow/Arrow";
import ListServices from "../../../../components/List/ListService/ListServices";
import Filter from "../../../../components/kit/Filter/Filter";
import {getCategories} from "../../../../store/Main/services/servicesAction";
import LoadingComponent from "../../OnlineRecord/LoadingComponent/LoadingComponent";

import './index.scss'

const RecordsServices = ({listCategories, response, getCategories, history, defaultPage}) => {

    const [defaultCheck, setDefaultCheck] = useState(null)
    const [searchTitle, setSearchTitle] = useState('')

    useEffect(() => {
        getCategories()
        setDefaultCheck(defaultPage)
    }, [])


    const setTitle = e => setSearchTitle(e.target.value)

    const onSubmitSearch = () => {
        history.push(`/search_result/services/${defaultCheck ? 'default' : ''}?search=${searchTitle}&page=1`)
    }

    if(response) return <LoadingComponent />

    return (

        <div className="container">
            <div className="online-record-root">
                <Arrow
                    title="Услуги"
                    history={history}
                />

                <Filter
                    placeholder={'Список услуг'}
                    filter={false}
                    setTitle={setTitle}
                    onSubmit={onSubmitSearch}
                    value={searchTitle}
                />

                <div className="services-items">
                    <ListServices
                        defaultCheck={defaultPage}
                        services={listCategories}
                    />
                </div>
            </div>
        </div>

    );
};

const mapStateToProps = state => ({
    response: state.listCategories.response,
    listCategories: state.listCategories.listCategories
})

const mapDispatchToProps = dispatch => ({
    getCategories: () => dispatch(getCategories())
})

export default connect(mapStateToProps, mapDispatchToProps)(RecordsServices);