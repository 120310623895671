import axios from "../Api/axios-api";
import {ROUTE_CATEGORIES} from "../Api/Routes";
import {NotificationManager} from "react-notifications";
import {ERROR_TEXT} from "../../global/Constants/Constants";
import SwalModal from "../../components/SwalModal/SwalModal";
import swal from "@sweetalert/with-react";
import React from "react";

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_GET_SUCCESS = 'FETCH_CATEGORIES_GET_SUCCESS'
const fetchCategoriesRequest = () => ({type: FETCH_CATEGORIES_REQUEST});
const fetchCategoriesSuccess = categories => ({type: FETCH_CATEGORIES_GET_SUCCESS, categories});
export const fetchCategories = (params) => {

    let url = "?"

    if (params.page) {
        url += `page=${params.page}&`
    }
    if (params.search) {
        url += `search=${params.search}&`
    }
    if (params.salon) {
        url += `salon=${params.salon}`
    }

    return dispatch => {
        dispatch(fetchCategoriesRequest())
        return axios.get(ROUTE_CATEGORIES + url).then(
            response => {
                dispatch(fetchCategoriesSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchCreateCategory = data => {
    return dispatch => {
        return axios.post(ROUTE_CATEGORIES, data).then(
            () => {
                const el = <SwalModal title="Успешно добавлено!"
                                      subTitle={`Вы добавили категорию ${data.name} успешно!`}
                                      swal={swal}/>;
                swal({buttons: false, content: el});
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchRemoveCategory = categoryId => {
    return dispatch => {
        return axios.delete(ROUTE_CATEGORIES + categoryId + "/").then(
            () => {
                NotificationManager.success("Категория успешно удалена")
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchEditCategory = (categoryId, data) => {
    return dispatch => {
        return axios.put(ROUTE_CATEGORIES + categoryId + "/", data).then(
            () => {
                NotificationManager.success("Категория успешно изменена")
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}