import React from 'react';
import Colors from "../../../global/styles/Colors";
import DotsIcon from "../../../components/kit/Icons/DotsIcon";
import ReactTooltip from "react-tooltip";
import PencilIcon from "../../../components/kit/Icons/PencilIcon";
import TrashIcon from "../../../components/kit/Icons/TrashIcon";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {getHourByMinutes, getRemainingMinutes} from "../../../global/Helpers/helpers";

const ServiceElement = ({data, onEdit, onRemove}) => {
    return (

        <TableRow>
            <TableCell align="left">
                <p className="dashboard-services-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.name}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-services-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.category_name}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-services-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {getHourByMinutes(data.time)}
                    <span className="dashboard-services-root__dot"
                          style={{backgroundColor: Colors.SEMANTIC['blue_3']}}/>
                    {getRemainingMinutes(data.time)}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-services-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.price} сом
                </p>
            </TableCell>
            <TableCell align="left">

                <div className="dashboard-services-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                            <span data-tip data-for={`service-tooltip-${data.id}`} data-event='click focus'>
                            <DotsIcon width={24} height={24} color={Colors.NEUTRAL['black']}/>
                            <ReactTooltip id={`service-tooltip-${data.id}`} place="bottom" globalEventOff='click'
                                          clickable={true}
                                          effect="solid" isCapture={true}>
                                <div className="dashboard-services-root__tooltip">
                                    <button onClick={onEdit} className="dashboard-services-root__tooltip-element">
                                        <PencilIcon width={14} height={16} color={Colors.NEUTRAL['white']}
                                                    className="dashboard-services-root__tooltip-element--edit"/>
                                        Редактировать</button>
                                    <button onClick={onRemove} className="dashboard-services-root__tooltip-element">
                                        <TrashIcon width={13} height={14} color={Colors.NEUTRAL['white']}
                                                   className="dashboard-services-root__tooltip-element--remove"/>
                                        Удалить
                                    </button>
                                </div>
                            </ReactTooltip>
                            </span>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default ServiceElement;