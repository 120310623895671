import {FETCH_LOGOUT_USER, FETCH_USER_AUTHORIZATION_FAILURE, FETCH_USER_AUTHORIZATION_SUCCESS} from "./userAction";

const initialState = {
    user: null,
    userError: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USER_AUTHORIZATION_SUCCESS:
            return {...state, user: action.auth, userError: null}
        case FETCH_USER_AUTHORIZATION_FAILURE:
            return {...state, userError: action.error}
        case FETCH_LOGOUT_USER:
            return {...state, user: null}
        default:
            return state;
    }
};

export default userReducer;
