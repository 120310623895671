import React, {useEffect, useState} from 'react';

import AboutSalon from "./AboutSalon/AboutSalon";
import AboutMaster from "./AboutMaster/AboutMaster";

import {getDefaultPage} from "../../../global/Helpers/helpers";

const PAGE_SALON = 'salon'
const PAGE_MASTER = 'master'

const About = (props) => {
    const paramId = props.match.params.id
    const type = props.match.params.type
    const history = props.history
    const [defaultCheck, setDefaultCheck] = useState(null)

    useEffect(() => {
        setDefaultCheck(getDefaultPage(props.match.params.default))
    }, [])

    switch (type){
       case PAGE_SALON:
           return <AboutSalon
                defaultCheck={defaultCheck}
                paramsId={paramId}
                history={history}
           />
       case PAGE_MASTER:
           return <AboutMaster
               defaultCheck={defaultCheck}
               paramsId={paramId}
               history={history}
           />
       default:
           props.history.push('/main')
           return null
   }
};

export default About;