import React, {useEffect, useState} from 'react';

import {NavLink} from "react-router-dom";
import Carousel from "react-multi-carousel";
import {connect} from "react-redux";

import TitleBlockMainPage from "./TitleBlockMainPage/TitleBlockMainPage";
import ListServices from "../../../components/List/ListService/ListServices";
import ListMaster from "../../../components/List/ListMasterMainPage/ListMaster";
import LoadingComponent from "../OnlineRecord/LoadingComponent/LoadingComponent";

import {getMainPage} from "../../../store/Main/MainPage/mainPageAction";
import {getDefaultPage} from "../../../global/Helpers/helpers";

import {
    LINK_NEW_ENTRY_DEFAULT,
    LINK_NEW_ENTRY,
    LINK_RECORDS_SERVICES_DEFAULT,
    LINK_RECORDS_SERVICES,
    LINK_MERCHANTS_DEFAULT,
    LINK_MERCHANTS,
    LINK_ABOUT_SALON,
    LINK_ABOUT_SALON_DEFAULT,
    LINK_ALL_MASTER_DEFAULT,
    LINK_ALL_MASTER,
    LINK_ABOUT_MASTER_DEFAULT,
    LINK_ABOUT_MASTER, 
    LINK_MAIN_DEFAULT, 
    LINK_MAIN
} from "../../../global/Helpers/Links";

import userIcon from '../../../assets/userLogo.png'

import 'react-multi-carousel/lib/styles.css';
import './index.scss'

const RESPONSIVE_CAROUSEL = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};
const RESPONSIVE_CAROUSEL_SERVICES = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const MainPage = ({mainPage, fetchGetMainPage, match, history}) => {

    const {requested} = mainPage
    const {me, meAppointments} = mainPage
    const {promos, popularServices, topMasters, bestSalons} = mainPage
    const [defaultCheck, setDefaultCheck] = useState(null)

    useEffect(() => {
        fetchGetMainPage()
        setDefaultCheck(getDefaultPage(match.params.default))
    }, [])

    if(requested) return <LoadingComponent />

    return (
        <div className="container">

            <div className="online-record-root">

                <div className="header-user">

                    {!me &&
                        <div className="user-info">
                            <img src={userIcon} alt=""/>
                            <p>К.А. Морозов</p>
                        </div>
                    }

                    <div className="links-recording">

                        {meAppointments &&
                            <NavLink to={'/records'} className="link-recording_my link-recording">
                                <span>Мои записи</span>
                            </NavLink>
                        }

                        <NavLink to={defaultCheck ? LINK_NEW_ENTRY_DEFAULT : LINK_NEW_ENTRY}
                                 className="link-recording_record link-recording" >
                            <span>Записаться</span>
                        </NavLink>
                    </div>

                </div>

                <div className="list-discounts">
                    <Carousel
                        responsive={RESPONSIVE_CAROUSEL_SERVICES}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        infinite={true}
                    >
                        {promos?.map((item, index) => {
                            return (
                                <div className="discount" key={index}>
                                    <NavLink to={defaultCheck ? LINK_MAIN_DEFAULT : LINK_MAIN}>
                                        <div className="discount-text">
                                            <h3>{item.title}</h3>
                                            <p>{item.small_title}</p>
                                        </div>
                                        <div className="discount-img">
                                            <img src={item.images[index].image} alt=""/>
                                        </div>
                                    </NavLink>
                                </div>
                            )
                        })}
                    </Carousel>
                </div>

                <div className="popular-services">
                    <TitleBlockMainPage
                        title={'Популярные услуги'}
                        link={defaultCheck ? LINK_RECORDS_SERVICES_DEFAULT : LINK_RECORDS_SERVICES}
                    />
                    <ListServices
                        defaultCheck={defaultCheck}
                        services={popularServices}
                    />
                </div>

                <div className="list-salons">
                    <TitleBlockMainPage
                        title={'Лучшие салоны'}
                        link={defaultCheck ? LINK_MERCHANTS_DEFAULT : LINK_MERCHANTS}
                    />

                        <div className="salon-item" >
                            <Carousel
                                responsive={RESPONSIVE_CAROUSEL}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                infinite={true}
                            >
                            {bestSalons?.map((item, index) => {
                               return(
                                   <NavLink className="salon-item-link"
                                            to={defaultCheck ? `${LINK_ABOUT_SALON_DEFAULT + item.id}` :
                                                `${LINK_ABOUT_SALON + item.id}`}
                                            key={index}>
                                       {item.logo ?
                                           <img src={item.logo} className="salon-item-image" alt=""/>
                                       :
                                           <div className="none-logo">
                                               <span>{item.name[0]}</span>
                                           </div>
                                       }

                                       <p className="salon-item-title">{item.title}</p>
                                       <h3 className="salon-item-name">{item.name}</h3>

                                       <div className="salons-item-rating">
                                           <span className="salon-rating">{item.rating || 0}</span>
                                           <span className="salon" />
                                           <span className="salon-comments" >{item.reviews_count}</span>
                                       </div>
                                   </NavLink>
                               )
                            })}
                            </Carousel>
                        </div>
                </div>

                <div className="list-specialists">
                    <TitleBlockMainPage
                        title={'Топ 5 мастеров'}
                        link={defaultCheck ? LINK_ALL_MASTER_DEFAULT : LINK_ALL_MASTER}
                    />
                    <ListMaster
                        listMasters={topMasters}
                        link={defaultCheck ? LINK_ABOUT_MASTER_DEFAULT : LINK_ABOUT_MASTER}
                    />
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    mainPage: state.mainPage
})

const mapDispatchToProps = dispatch => ({
    fetchGetMainPage: () => dispatch(getMainPage())
})

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);