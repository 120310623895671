import React, { useEffect } from "react";
import Colors from "../../../../global/styles/Colors";
import SelectFormOption from "../../../../components/kit/SelectFormExpandPanel/SelectFormOption/SelectFormOption";
import TimeIcon from "../../../../components/kit/Icons/TimeIcon";

import WalletIcon from "../../../../components/kit/Icons/WalletIcon";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";
import WomanIcon from "../../../../components/kit/Icons/WomanIcon";
import moment from "moment";
import "moment/locale/ru";
import CalendarIcon from "../../../../components/kit/Icons/CalendarIcon";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";
import selectIMG from "../../../../assets/Icons/select-services.svg";
import { useTelegram } from "../../../../hooks/useTelegram";

import "../index.scss";

const Payment = ({
  selectedOptions,
  selectedTime,
  onSubmit,
  master,
  selectedDate,
  handleBackNav,
  initOrder,
  payMethods,
  typePay,
  onSelectTypePay,
  defaultCheck,
  data,
}) => {
  let date = moment(selectedDate);
  date.locale("ru");
  let totalSum = 0;
  selectedOptions.forEach((el) => (totalSum += parseInt(el.price)));
  const { tg, isTgWebApp } = useTelegram();

  useEffect(() => {
    if (isTgWebApp) {
      tg.MainButton.show();
      tg.MainButton.text = "Записаться";
      tg.MainButton.color = "#FF647C";
      tg.MainButton.textColor = "#FFFFFF";
      tg.onEvent("mainButtonClicked", () => {
        tg.sendData(JSON.stringify(data));
      });
    }
    return () => {
      if (isTgWebApp) {
        tg.Mainbutton.hide && tg.Mainbutton.hide();
      }
    };
  });

  return (
    <div className="payment-form-root">
      <h3
        className="payment-form-root__title"
        style={{ color: Colors.NEUTRAL["black"] }}
      >
        <span className="record-nav-back-icon">
          <ArrowLargeIcon onClick={handleBackNav} />
        </span>
        <span>{initOrder ? "Информация о записи" : "Итого к оплате"}</span>
        <span className="empty" />
      </h3>
      <p
        className="payment-form-root__sub-title"
        style={{ color: Colors.TEXT["icon"] }}
      >
        Услуги
      </p>
      {selectedOptions &&
        selectedOptions.map((el, ndx) => (
          <SelectFormOption
            key={ndx}
            item={el}
            isChecked={true}
            onSelect={() => null}
          />
        ))}
      <div
        className="payment-form-root__time"
        style={{
          borderTop: `1px solid ${Colors.NEUTRAL["border"]}`,
        }}
      >
        <p
          className="payment-form-root__sub-title"
          style={{ color: Colors.TEXT["icon"] }}
        >
          <TimeIcon width={20} height={20} />
          <span className="payment-form-root__sub-title__txt">Время</span>
        </p>
        <p
          className="payment-form-root__time__selected-time"
          style={{ color: Colors.NEUTRAL["black"] }}
        >
          {selectedTime || ""}
        </p>
      </div>
      <div
        className="payment-form-root__time"
        style={{
          borderTop: `1px solid ${Colors.NEUTRAL["border"]}`,
        }}
      >
        <p
          className="payment-form-root__sub-title"
          style={{ color: Colors.TEXT["icon"] }}
        >
          <CalendarIcon
            color={Colors.NEUTRAL["gray_purple"]}
            width={19}
            height={20}
          />
          <span className="payment-form-root__sub-title__txt">Дата</span>
        </p>
        <p
          className="payment-form-root__time__selected-time"
          style={{ color: Colors.NEUTRAL["black"] }}
        >
          {date.format("D MMMM YYYY") || ""} г.
        </p>
      </div>
      {master && (
        <div
          className="payment-form-root__time"
          style={{
            borderTop: `1px solid ${Colors.NEUTRAL["border"]}`,
          }}
        >
          <p
            className="payment-form-root__sub-title"
            style={{ color: Colors.TEXT["icon"] }}
          >
            <WomanIcon
              width={20}
              height={20}
              color={Colors.NEUTRAL["gray_purple"]}
            />
            <span className="payment-form-root__sub-title__txt">
              Специалист
            </span>
          </p>
          <p
            className="payment-form-root__time__selected-time"
            style={{ color: Colors.NEUTRAL["black"] }}
          >
            {master.name || ""}
          </p>
        </div>
      )}
      <div
        className="payment-form-root__total-amount"
        style={{
          borderTop: `1px solid ${Colors.NEUTRAL["border"]}`,
        }}
      >
        <p
          className="payment-form-root__sub-title"
          style={{ color: Colors.TEXT["icon"] }}
        >
          <WalletIcon width={20} height={20} />
          <span className="payment-form-root__sub-title__txt">Итого</span>
        </p>
        <p
          className="payment-form-root__time__selected-time"
          style={{ color: Colors.NEUTRAL["black"] }}
        >
          {totalSum || "1800"} сом
        </p>

        {!initOrder && (
          <div className="wrapper-btns-pay-method">
            <p>Выберите тип оплаты:</p>
            <div className="btns-pay-method">
              {payMethods.map((item, index) => {
                return (
                  <button
                    key={index}
                    className={
                      typePay === item?.slug ? "active-btn-pay-method" : ""
                    }
                    onClick={() => onSelectTypePay(item.slug)}
                  >
                    {typePay === item?.slug && (
                      <img src={selectIMG} alt="select" />
                    )}
                    {item.name}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {!isTgWebApp ? (
        <GeneralButton
          bgColor={
            typePay === null
              ? Colors.NEUTRAL["border"]
              : Colors.SEMANTIC["pink"]
          }
          className="service-variant-root__btn"
          title={initOrder ? "Изменить" : "Записаться"}
          textColor={
            typePay === null
              ? Colors.TEXT["text_color"]
              : Colors.NEUTRAL["white"]
          }
          onClick={onSubmit}
          disabled={typePay === null}
        />
      ) : null}
    </div>
  );
};

export default Payment;
