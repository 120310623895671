import React from 'react';

import {NavLink} from "react-router-dom";

import './index.scss'

import star from '../../../assets/Icons/Star.svg'

const ListSalonsRecording = ({listItems, btnActive, response, links}) => {

    if(!listItems)
        return(
            <div className="load-page">
                <div className="lds-dual-ring" />
            </div>
        )

    return (
        <div className="list-salons-items" >

            {listItems.map((item, index) => {
                return(
                    <NavLink
                        to={btnActive ? links[btnActive] + item.id : links[btnActive] + item.id}
                        key={index}
                    >
                        <div className="item-salon">

                            {item.image || item.logo ?
                                <div className="img-salon">
                                    <img src={item.image || item.logo} alt=""/>
                                </div>
                                :
                                <div className="img-none" >
                                    <span>{item.name[0] ? item.name[0] : ''}</span>
                                </div>
                            }

                            <div className="information-salon">
                                <p>{item.name}</p>
                                <span><img src={star} alt=""/><p>{item.rating}</p></span>
                            </div>

                        </div>
                    </NavLink>
                )
            })}

            {response &&
                <div className="load-page">
                    <div className="lds-dual-ring" />
                </div>
            }
        </div>
    );

};

export default ListSalonsRecording;