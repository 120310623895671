import React from 'react';
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Colors from "../../../global/styles/Colors";

import './index.scss';
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${Colors.NEUTRAL['border']}`,
        borderRadius: '6px',
        color: Colors.NEUTRAL['black'],
    },
}));

const InputForm = ({propertyName, onChange, className, label, ...props}) => {
    const classes = useStyles();
    return (
        <FormControl className={[classes.root, "input-form", className].join(' ')}>
            <TextField
                label={label}
                variant="outlined"
                name={propertyName}
                onChange={onChange}
                {...props}
            />
        </FormControl>
    );
};

export default InputForm;