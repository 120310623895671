import React from 'react';

const TrashIcon = ({width, height, color, className, opacity}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} opacity={opacity}
             viewBox="0 0 14 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.333496 3.99984C0.333496 3.63165 0.631973 3.33317 1.00016 3.33317H13.0002C13.3684 3.33317 13.6668 3.63165 13.6668 3.99984C13.6668 4.36803 13.3684 4.6665 13.0002 4.6665H1.00016C0.631973 4.6665 0.333496 4.36803 0.333496 3.99984Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.66683 1.99984C5.49002 1.99984 5.32045 2.07007 5.19543 2.1951C5.0704 2.32012 5.00016 2.48969 5.00016 2.6665V3.33317H9.00016V2.6665C9.00016 2.48969 8.92992 2.32012 8.8049 2.1951C8.67988 2.07007 8.51031 1.99984 8.3335 1.99984H5.66683ZM10.3335 3.33317V2.6665C10.3335 2.13607 10.1228 1.62736 9.74771 1.25229C9.37264 0.877218 8.86393 0.666504 8.3335 0.666504H5.66683C5.1364 0.666504 4.62769 0.877218 4.25262 1.25229C3.87754 1.62736 3.66683 2.13607 3.66683 2.6665V3.33317H2.3335C1.96531 3.33317 1.66683 3.63165 1.66683 3.99984V13.3332C1.66683 13.8636 1.87754 14.3723 2.25262 14.7474C2.62769 15.1225 3.1364 15.3332 3.66683 15.3332H10.3335C10.8639 15.3332 11.3726 15.1225 11.7477 14.7474C12.1228 14.3723 12.3335 13.8636 12.3335 13.3332V3.99984C12.3335 3.63165 12.035 3.33317 11.6668 3.33317H10.3335ZM3.00016 4.6665V13.3332C3.00016 13.51 3.0704 13.6795 3.19543 13.8046C3.32045 13.9296 3.49002 13.9998 3.66683 13.9998H10.3335C10.5103 13.9998 10.6799 13.9296 10.8049 13.8046C10.9299 13.6795 11.0002 13.51 11.0002 13.3332V4.6665H3.00016Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.66683 6.6665C6.03502 6.6665 6.3335 6.96498 6.3335 7.33317V11.3332C6.3335 11.7014 6.03502 11.9998 5.66683 11.9998C5.29864 11.9998 5.00016 11.7014 5.00016 11.3332V7.33317C5.00016 6.96498 5.29864 6.6665 5.66683 6.6665Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.3335 6.6665C8.70169 6.6665 9.00016 6.96498 9.00016 7.33317V11.3332C9.00016 11.7014 8.70169 11.9998 8.3335 11.9998C7.96531 11.9998 7.66683 11.7014 7.66683 11.3332V7.33317C7.66683 6.96498 7.96531 6.6665 8.3335 6.6665Z"
                  fill={color}/>
        </svg>
    );
};

export default TrashIcon;