import React from 'react';

import {NavLink} from "react-router-dom";

import cup from "../../../assets/Icons/cup.png";

import './index.scss'

const ListMaster = ({listMasters, link = '/main'}) => {

    const listMaster = listMasters

    return (
        <div className="specialists-items">

            {listMaster?.map((item, index) => {
                return(
                    <NavLink
                        to={`${link + item.id}`}
                        key={index}
                    >
                        <div className="specialist-item">

                            <div className="order-item-specialist">
                                <span>{index + 1}</span>
                                <img src={cup} alt=""/>
                            </div>

                            {item.image ?
                                <div className="image-item-specialist">
                                    <img src={item.image} alt=""/>
                                </div>
                                :
                                <div className="image-item-none-logo">
                                    <span>{item.full_name[0]}</span>
                                </div>
                            }

                            <div className="information-item-specialist">
                                <span className="category">{item.main_categories[0]}</span>
                                <p className="name-specialist" >{item.full_name}</p>
                            </div>

                        </div>

                    </NavLink>
                )
            })}

        </div>
    );
};

export default ListMaster;