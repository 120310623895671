import React, {useCallback, useEffect, useState} from 'react';

import DashboardHeader from "../../../components/DasboardHeader/DashboardHeader";
import Colors from "../../../global/styles/Colors";
import DashboardModal from "../../../components/DashboardModal/DashboardModal";
import InputForm from "../../../components/kit/InputForm/InputForm";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";
import {Formik, isEmptyArray} from "formik";
import './index.scss';
import CreateBlock from "../../../components/CreateBlock/CreateBlock";
import CategoryElement from "./CategoryElement";
import CommonPagination from "../../../components/CommonPagintation/CommonPagintation";
import {
    fetchCategories,
    fetchCreateCategory,
    fetchEditCategory,
    fetchRemoveCategory
} from "../../../store/categories/categoriesActions";
import {connect} from "react-redux";
import {INITIAL_PAGE} from "../Applications/Applications";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {useDebounce} from "../../../components/UseDebounce/UserDebounce";
import NoResultIcon from "../../../components/kit/Icons/NoResultIcon";
import {changeSalonAction} from "../../../store/core/coreActions";

const Categories = (
    {
        categories,
        requested,
        salons,
        salon,

        fetchCategories,
        fetchCreateCategory,
        fetchRemoveCategory,
        fetchEditCategory,
        changeSalonAction
    }
) => {
    const [state, setState] = useState({
        isOpenModal: false,
        name: ''
    })

    const [params, setParams] = useState({
        page: INITIAL_PAGE,
        search: "",
        salon: ""
    })

    const [editingItem, setItem] = useState(null)

    const debouncedSearchTerm = useDebounce(params.search, 700);


    useEffect(function () {
        getData()
    }, [params.page, debouncedSearchTerm, params.salon])

    useEffect(function () {
        if (salons && salons[0]) {
            setParams(prevState => {
                return {...prevState, salon: !!salon ? salon.id : salons[0].id}
            })
        }
    }, [salon, salons])

    const onChangeSalon = useCallback(e => {
        setParams(prevState => {
            return {...prevState, salon: e.target.value, page: INITIAL_PAGE}
        })
        const salon = salons.find(salon => salon.id === e.target.value);
        changeSalonAction(salon)

    }, [changeSalonAction, salons])

    const getData = useCallback(() => {
        const filter = {...params};

        if (params.salon) {
            filter.salon = params.salon
            fetchCategories(filter)
        }
    }, [fetchCategories, params.salon])

    const toggleModal = useCallback(() => {
        setState(prevState => {
            return {...prevState, isOpenModal: !state.isOpenModal}
        })
    }, [state.isOpenModal])

    const onSubmit = useCallback(async values => {
        toggleModal();
        if (editingItem) {
            await fetchEditCategory(editingItem.id, {name: values.name})
        } else {
            await fetchCreateCategory({name: values.name, salon: params.salon})
        }
        await getData()
        setItem(null)
        setState(prevState => ({...prevState, name: ""}))
    }, [editingItem, fetchCreateCategory, fetchEditCategory, getData, toggleModal])

    const onChangePage = useCallback((e, page) => {
        setParams(prevState => {
            return {...prevState, page: page}
        })
    }, [])

    const searchHandler = useCallback((e) => {
        e.persist()
        setParams(prevState => {
            return {...prevState, search: e.target.value}
        })
    }, [])

    const onRemoveCategory = useCallback(async id => {
        await fetchRemoveCategory(id)
        await getData()
    }, [fetchRemoveCategory, getData])

    const onEditCategory = useCallback(item => {
        setState(prevState => {
            return {...prevState, name: item.name}
        })
        setItem(item)
        toggleModal()
    }, [toggleModal])

    return (
        <React.Fragment>
            <DashboardHeader
                title="Категории услуг"
                buttonText="Добавить категорию"
                onClickAdd={toggleModal}
                onSearch={searchHandler}
                selectOptions={salons}
                selectValue={params.salon}
                fieldName="salon"
                handleChange={onChangeSalon}
            />

            <div className="dashboard-page-root">
                <Table className="dashboard-page-root__table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <p className="dashboard__title dashboard__categories-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Название категории
                                </p>
                            </TableCell>
                            <TableCell align="center">
                                <p className="dashboard__title dashboard__categories-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Действия
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!requested && categories && categories.count > 0 && categories.results && categories.results.map(category => (
                            <CategoryElement
                                onRemove={() => onRemoveCategory(category.id)}
                                onEdit={() => onEditCategory(category)}
                                data={category}
                                key={category.id}
                            />
                        ))}
                    </TableBody>
                </Table>
                {!requested && categories && categories.count === 0 && !params.search && (
                    <CreateBlock
                        title="Создайте категорию услуг"
                        subTitle="Категории нужны для быстрого поиска услуг. Например несколько видов маникюра можно
                        объединить под одной категорией — маникюр."
                        onSubmit={toggleModal}
                        buttonText="Добавить категорию"
                    />
                )}

                {!requested && debouncedSearchTerm && categories &&
                categories.results &&
                isEmptyArray(categories.results) &&
                <div className="no-result">
                    <NoResultIcon width={100} height={140}/>
                    <p className="no-result__text">Результатов не найдено</p>
                </div>
                }

                {requested && (
                    <CommonSpinner isLoading={requested} centered/>
                )}

            </div>

            {!requested && categories && categories.page_count > INITIAL_PAGE && (
                <CommonPagination
                    count={categories.page_count}
                    currentPage={params.page}
                    onChange={onChangePage}
                />
            )}

            {state.isOpenModal &&
            <DashboardModal
                isOpen={state.isOpenModal}
                onClose={toggleModal}
                title="Добавить категорию"
            >
                <Formik
                    initialValues={state}
                    validate={values => {
                        const error = {};
                        if (!values.name) {
                            error.name = "Обязательное поле"
                        }
                        return error
                    }}
                    onSubmit={(values) => {
                        onSubmit(values)
                    }}
                >
                    {
                        ({
                             values,
                             errors,
                             touched,
                             handleChange,
                             dirty,
                             handleSubmit,
                         }) => (
                            <form className="dashboard-form-categories" onSubmit={handleSubmit}>
                                <InputForm
                                    onChange={handleChange}
                                    value={values.name}
                                    propertyName="name"
                                    label="Введите название категории"
                                    className="dashboard-form-categories__text-field"
                                />

                                {errors.name && touched.name && errors.name &&
                                <span className="field-error">{errors.name}</span>
                                }

                                <GeneralButton
                                    type="submit"
                                    title={editingItem ? "Сохранить" : "Добавить"}
                                    disabled={!dirty}
                                    className="dashboard-form-categories__button"
                                    bgColor={dirty ? Colors.SEMANTIC['green'] : Colors.NEUTRAL['border']}
                                    textColor={Colors.NEUTRAL['white']}/>
                            </form>
                        )
                    }
                </Formik>
            </DashboardModal>
            }
        </React.Fragment>

    );
}

const mapStateToProps = state => ({
    categories: state.categories.categories,
    requested: state.categories.requested,
    salons: state.core.salons,
    salon: state.core.salon,
});

const mapDispatchToProps = dispatch => ({
    fetchCategories: (params) => dispatch(fetchCategories(params)),
    fetchCreateCategory: data => dispatch(fetchCreateCategory(data)),
    fetchRemoveCategory: categoryId => dispatch(fetchRemoveCategory(categoryId)),
    fetchEditCategory: (categoryId, data) => dispatch(fetchEditCategory(categoryId, data)),
    changeSalonAction: salon => dispatch(changeSalonAction(salon))
})

export default connect(mapStateToProps, mapDispatchToProps)(Categories);