import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { NavLink } from "react-router-dom";
import Colors from "../../../global/styles/Colors";
import Button from "@material-ui/core/Button";
import CompanySettings from "./CompanySettings";
import Role from "./Role";
import { connect } from "react-redux";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import {
    changeSalonAction,
    fetchClearSalon,
    fetchCreateSalon,
    fetchCurrnetTarifGet,
    fetchEditSalonInfo, fetchGetBalance,
    fetchRemoveSalon,
    fetchSalonGet, fetchSalonsGet
} from "../../../store/core/coreActions";
import WorkTime from "./WorkTime";
import { history } from "../../../store/configureStore";
import { ROUTE_SETTINGS, ROUTE_SETTINGS_ADD } from "../../../global/Constants/Constants";
import Tarifs from './Tarifs';



const SETTING_TAB = 0;
const ROLES_TAB = 1;
const WORK_TIME_TAB = 2;
const TARIFS = 3;

const Settings = (
    {
        fetchCurrnetTarifGet,
        current_tarif,
        salons,
        fetchSalonGet,
        salon,
        balance,
        fetchEditSalonInfo,
        fetchCreateSalon,
        requested,
        fetchClearSalon,
        fetchRemoveSalon,
        fetchSalonsGet,
        changeSalonAction,
        fetchGetBalance,

        ...props
    }
) => {
    const salonId = props.match.params.salonId
    const TAB_NAMES = current_tarif ? ["Настройки компании", "Роли", "Время", "Тарифы"] : ["Настройки компании", "Роли", "Время"]

    const [activeTab, setStab] = useState(SETTING_TAB);

    useEffect(function () {
        if (salonId) {
            fetchCurrnetTarifGet(salonId)
            fetchSalonGet(salonId)
            fetchGetBalance(salonId)
        }

        // return function () { // TODO why i did it ???
        //     fetchClearSalon()
        // }
    }, [fetchSalonGet, salonId])

    const createNewBranch = useCallback(() => {
        history.push(ROUTE_SETTINGS_ADD)
        setStab(SETTING_TAB)
    }, [])

    const onRemoveSalon = useCallback(async () => {
        await fetchRemoveSalon(salonId)
        await fetchSalonsGet()
        const salonSorted = await salons.sort((a, b) => a.id - b.id)
        history.push(ROUTE_SETTINGS + `${salonSorted[0].id}`)
    }, [fetchRemoveSalon, fetchSalonsGet, salonId, salons])

    const onChangeSalon = (e, salon) => {
        e.preventDefault();
        changeSalonAction(salon)
        history.push(`/settings_main/${salon.id}`)
    }

    if (!salons) {
        return <CommonSpinner isLoading={!salons} centered />
    }

    return (
        <div className="settings-root">
            <div className="settings-root__header" style={{ borderBottom: `1px solid ${Colors.NEUTRAL['border']}` }}>
                {salons.sort((a, b) => a.id - b.id).map(salon => (
                    <NavLink
                        key={salon.id}
                        className="settings-root__header-nav-link"
                        to={`/settings_main/${salon.id}`}
                        activeClassName="active"
                        onClick={e => onChangeSalon(e, salon)}
                    >
                        {salon.name}
                    </NavLink>
                ))}

                <Button className="settings-root__add_branch" variant="contained" onClick={createNewBranch}>
                    Добавить филиал
                </Button>
            </div>

            <div className="settings-root__container">
                <div className="settings-root__company-settings">
                    <div className="settings-root__title-block">
                        {!salonId &&
                            <h3 className={`settings-root__title-tab --active`}>
                                Настройки компании
                            </h3>
                        }
                        {!!salonId && TAB_NAMES.map((item, ndx) => (
                            <h3 key={ndx}
                                onClick={() => setStab(ndx)}
                                className={`settings-root__title-tab 
                                ${activeTab === ndx ? "--active" : ""}`}>
                                {item}
                            </h3>
                        ))}
                    </div>

                    {requested && <CommonSpinner isLoading={requested} centered />}

                    {!requested && activeTab === SETTING_TAB &&
                        <CompanySettings
                            data={salonId ? salon : null}
                            requested={requested}
                            hasRemoveButton={salons.length > 1}
                            onRemoveSalon={onRemoveSalon}
                            balance={balance}
                        />
                    }

                    {!requested && activeTab === ROLES_TAB && salon && !!salonId && <Role {...props} />}
                    {!requested && activeTab === WORK_TIME_TAB && salon && !!salonId && <WorkTime {...props} />}
                    {!requested && activeTab === TARIFS && salon && !!salonId && <Tarifs {...props} />}
                </div>
            </div>

        </div>
    );
};

const mapStateToProps = state => ({
    salons: state.core.salons,
    salon: state.core.salon,
    requested: state.core.requested,
    balance: state.core.balance,
    current_tarif: state.core.current_tarif,
})

const mapDispatchToProps = dispatch => ({
    fetchCurrnetTarifGet: (salonId) => dispatch(fetchCurrnetTarifGet(salonId)),
    fetchSalonGet: salonId => dispatch(fetchSalonGet(salonId)),
    fetchEditSalonInfo: (salonId, data) => dispatch(fetchEditSalonInfo(salonId, data)),
    fetchCreateSalon: data => dispatch(fetchCreateSalon(data)),
    fetchClearSalon: () => dispatch(fetchClearSalon()),
    fetchRemoveSalon: salonId => dispatch(fetchRemoveSalon(salonId)),
    fetchSalonsGet: () => dispatch(fetchSalonsGet()),
    changeSalonAction: salon => dispatch(changeSalonAction(salon)),
    fetchGetBalance: id => dispatch(fetchGetBalance(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings);