import React, {useEffect, useState} from 'react';

import {NavLink} from "react-router-dom";

import star from '../../../assets/Icons/Star.svg'

import './index.scss'

const DETAIL_MASTER = '/about/master/'
const DETAIL_SALON = '/about/salon/'

const ListNewRecording = ({
    btnActive = 0,
    listMaster,
    listSalon,
    nextPage,
    paginationResponse,
    links,
    moreInformation
}) => {

    const [limitationCategory, setLimitation] = useState()
    const [filterCategory, setCategory] = useState([])

    useEffect(() => {
        if(listMaster && listSalon) {
            btnActive ? setCategory(listSalon) : setCategory(listMaster)
            setLimitation(new Array(listMaster.length).fill(4))
        }else if (listMaster) {
            setCategory(listMaster)
            setLimitation(new Array(listMaster.length).fill(4))
        }
    }, [btnActive, listMaster, listSalon])

    const viewAllServices = (e, index) => {
        e.preventDefault()
        const limit = [...limitationCategory]
        limit[index] = filterCategory[index].service_list.length
        setLimitation(limit)
    }

    return (
        <div className="list-new-record">

            {filterCategory.map((item, indexArr) => {
                return(
                    <NavLink
                        to={btnActive ? links[btnActive] + item.id : links[btnActive] + item.id}
                        key={indexArr}
                    >
                        <div className="item-new-record">
                            <div className="item-image">
                                {(item.image || item.logo) ?
                                    <img src={item.image || item.logo} alt=""/>
                                    :
                                    <div className="item-logo">
                                        <span>{item.name[0]}</span>
                                    </div>
                                }
                                <span><img src={star} alt=""/>{item.rating}</span>
                            </div>

                            <div className="item-information">
                                <div className="direction-name-item">
                                    <span>{item.main_category}</span>
                                    <p>{item.name}</p>
                                </div>

                                <div className="category-items">
                                    {item.service_list.map((item, indexServices) => {
                                        if(indexServices < limitationCategory[indexArr]) {
                                            return (
                                                <div
                                                    key={indexServices}
                                                    className="category"
                                                >
                                                    <span>{item}</span>
                                                </div>
                                            )
                                        }
                                    })}
                                    {item.service_list.length > limitationCategory[indexArr] &&
                                        <span
                                            className="more-category"
                                            onClick={(e) => viewAllServices(e, indexArr)}
                                        >
                                            {`Еще ${item.service_list.length - 4}`}
                                        </span>
                                    }
                                </div>
                            </div>

                        </div>

                    </NavLink>
                )
            })}

            {nextPage[btnActive] &&
                <div className="more-information">
                    {paginationResponse[btnActive] && <div className="lds-dual-ring" />}
                </div>
            }

        </div>
    );
};

export default ListNewRecording;