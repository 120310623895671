import axios from "../../Api/axios-api";
import {ROUTE_SALON_PAGE} from '../../Api/Routes'

export const FETCH_GET_SALONS_REQUEST = 'FETCH_GET_SALONS_REQUEST'
export const FETCH_GET_SALONS_SUCCESS = 'FETCH_GET_SALONS_SUCCESS'

const fetchGetSalonsRequest = () => ({type: FETCH_GET_SALONS_REQUEST})
const fetchGetSalonsSuccess = data => ({type: FETCH_GET_SALONS_SUCCESS, value: data})

export const getListSalons = () => {
    return dispatch => {
        dispatch(fetchGetSalonsRequest())
        axios.get(ROUTE_SALON_PAGE)
            .then(response => {
                dispatch(fetchGetSalonsSuccess(response.data))
            })
    }
}

export const FETCH_PAGE_SALON_PAGINATION_REQUEST = 'FETCH_PAGE_SALON_PAGINATION_REQUEST'
export const FETCH_PAGE_SALON_PAGINATION_SUCCESS = 'FETCH_PAGE_SALON_PAGINATION_SUCCESS'

const fetchPageSalonPaginationRequest = () => ({type: FETCH_PAGE_SALON_PAGINATION_REQUEST})
const fetchPageSalonPaginationSuccess = data => ({type: FETCH_PAGE_SALON_PAGINATION_SUCCESS, value: data})

export const getListSalonPagination = (url) => {
    return dispatch => {
        dispatch(fetchPageSalonPaginationRequest())
        return axios.get(url)
            .then(response => {
                dispatch(fetchPageSalonPaginationSuccess(response.data))
            })
    }
}
