import React from 'react';

const ArrowGlyphIconDown = ({width, height, className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 7" fill="none"
             className={className}>
            <path
                d="M0.41506 1.65079L4.24084 6.1142C4.60026 6.53353 5.23156 6.58209 5.65089 6.22267C5.68977 6.18934 5.72603 6.15308 5.75935 6.1142L9.58513 1.65079C9.94455 1.23147 9.89599 0.600166 9.47667 0.240743C9.29542 0.0853922 9.06459 0 8.82587 0L1.17432 0C0.622032 0 0.174316 0.447715 0.174316 1C0.174316 1.23871 0.259709 1.46955 0.41506 1.65079Z"
                fill="#BE52F2"/>
        </svg>
    );
};

export default ArrowGlyphIconDown;