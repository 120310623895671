import React, { useCallback, useEffect, useState } from 'react';
import InputForm from "../../../components/kit/InputForm/InputForm";
import CopyIcon from "../../../components/kit/Icons/CopyIcon";
import DownloadIcon from "../../../components/kit/Icons/DownloadIcon";
import LeafletMap from "./LeafletMap/LeafletMap";
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import { fetchCreateSalon, fetchEditSalonInfo, fetchGetBalance, fetchRemoveSalon } from "../../../store/core/coreActions";
import { connect } from "react-redux";
import topImage from "../../../assets/dybenko-095.png";
import radiance_logo from "../../../assets/radiance_logo.png";
import CustomSwitch from "../../../components/kit/Switch/CustomSwitch";
import axios from "../../../store/Api/axios-api";

const CompanySettings = ({ fetchCreateSalon, fetchEditSalonInfo, onRemoveSalon, hasRemoveButton, fetchGetBalance, ...props }) => {
    const { data, requested, balance } = props

    console.log(data, '[data]')

    const [isActive, toggleActive] = useState(false)

    const [copiedText, setText] = useState('')

    const [state, setState] = useState({
        name: "",
        address: "",
        type: "",
        logo: "",
        background: "",
        latitude: "",
        longitude: "",
        admin_orders: false,
        is_whatsapp: false,
        is_sms: false
    })

    useEffect(() => {
        if (data) {
            setState({
                name: data.name || "",
                address: data.address || "",
                type: data.type || "",
                latitude: data.latitude || "",
                longitude: data.longitude || "",
                admin_orders: data.admin_orders || false,
                is_whatsapp: data.is_whatsapp || false,
                is_sms: data.is_sms || false,
                instagram: data.instagram || ""
            })
        }
    }, [data])


    const validateForm = useCallback(values => {
        const error = {};
        if (!values.name) {
            error.name = "Обязательное поле"
        }
        if (!values.address) {
            error.address = "Обязательное поле"
        }

        return error
    }, [])

    const fileHandleChange = useCallback(setFieldValue => (e) => {
        e.persist();
        if (e.target.files && e.target.files[0]) {
            setFieldValue([e.target.name], e.target.files[0])
        }
    }, [])

    const handleMarker = useCallback(coordinates => {
        setState(prevState => {
            return { ...prevState, longitude: coordinates.lng, latitude: coordinates.lat }
        })
        toggleActive(true)
    }, [])

    const onSubmit = useCallback(values => {
        const datas = { ...values }
        if (values.logo) {
            datas.logo = values.logo
        }
        datas.latitude = state.latitude
        datas.longitude = state.longitude

        const formData = new FormData();


        Object.keys(datas).forEach(key => {
            formData.append(key, datas[key])
        })


        if (data) {
            fetchEditSalonInfo(data.id, formData).then((item) => {
                fetchGetBalance(data.id)
            })
        } else {
            fetchCreateSalon(formData).then((item) => {
                fetchGetBalance(data.id)
            })
        }



        console.log(balance?.balance, "[balance?.balance]")

        toggleActive(false)


    }, [data, fetchCreateSalon, fetchEditSalonInfo, state.latitude, state.longitude])

    const onSelectCopy = useCallback(() => {
        navigator.clipboard.writeText(data.url)
        setText(data.url)
    }, [])

    const switchHandlerAdmin = useCallback(() => {
        setState(prevState => ({ ...prevState, admin_orders: !state.admin_orders }))
        toggleActive(true)
    }, [state.admin_orders])

    const switchHandlerWA = useCallback(() => {
        setState(prevState => ({ ...prevState, is_whatsapp: !state.is_whatsapp }))
        toggleActive(true)
    }, [state.is_whatsapp])

    const switchHandlerSMS = useCallback(() => {
        setState(prevState => ({ ...prevState, is_sms: !state.is_sms }))
        toggleActive(true)
    }, [state.is_sms])

    const replenishTheBalance = () => {
        axios.get(`/payment/generate_payment_link/?salon_id=${data.id}`)
            .then(response => window.open(response.data.url))
    }

    if (requested) {
        return <CommonSpinner isLoading={requested} centered />
    }


    return (
        <div className="settings-root__company">
            <Formik
                enableReinitialize={data}
                initialValues={state}
                validate={values => validateForm(values)}
                onSubmit={(values) => {
                    onSubmit(values)
                }}
            >
                {
                    ({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        dirty
                    }) => (
                        <form className="settings-root__form" onSubmit={handleSubmit}>

                            {/*{balance === }*/}
                            <div className="settings-root__company-form">
                                {
                                    (balance?.balance === 0 || balance?.balance) ?
                                        <>
                                            <p>Баланс: <strong>{balance?.balance} KGS</strong></p>
                                            <div className="settings-root__company-form__pay" onClick={replenishTheBalance}>Пополнить</div>
                                        </>
                                        :
                                        <p>Попросите администратора VIA подключить кошелек</p>
                                }
                            </div>

                            <div className="settings-root__company-names-root">
                                <div className="settings-root__company-form --w-50">
                                    <p className="settings-root__company-form-label">
                                        Название компании:
                                    </p>
                                    <InputForm
                                        className="settings-root__company-form-field"
                                        propertyName="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    <span className="field-error field-error-by-staff">
                                        {errors.name && touched.name && errors.name}
                                    </span>
                                </div>
                                <div className="settings-root__company-form --w-50">
                                    <p className="settings-root__company-form-label">
                                        Тип салона:
                                    </p>
                                    <InputForm
                                        className="settings-root__company-form-field"
                                        propertyName="type"
                                        value={values.type}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="settings-root__company-form">
                                <p className="settings-root__company-form-label">
                                    Адрес салона:
                                </p>
                                <InputForm
                                    className="settings-root__company-form-field"
                                    propertyName="address"
                                    value={values.address}
                                    onChange={handleChange}
                                />
                                <span className="field-error field-error-by-staff">
                                    {errors.address && touched.address && errors.address}
                                </span>
                            </div>
                            <div className="settings-root__company-form">
                                <p className="settings-root__company-form-label">
                                    Instagram салона:
                                </p>
                                <InputForm
                                    className="settings-root__company-form-field"
                                    propertyName="instagram"
                                    value={values.instagram}
                                    onChange={handleChange}
                                />
                            </div>

                            {data && data.url && (
                                <div className="settings-root__company-form">
                                    <p className="settings-root__company-form-label">
                                        Ссылка на запись:
                                    </p>
                                    <InputForm
                                        type="url"
                                        className="settings-root__company-form-field --short-length"
                                        propertyName="url"
                                        value={data.url}
                                        onChange={handleChange}
                                    />
                                    <span onClick={onSelectCopy}
                                        className="settings-root__company-form-copy">
                                        {copiedText ? "Скопировано" : "Скопировать"}
                                        <CopyIcon />
                                    </span>
                                </div>
                            )}

                            <div className="settings-root__company-form">
                                <p className="settings-root__company-form-label">
                                    Фоновая картина:
                                </p>
                                <InputForm
                                    type="file"
                                    id="upload-bg"
                                    propertyName="background"
                                    accept=".png, .jpg, .jpeg"
                                    className="settings-root__company-form-field --short-length --upload"
                                    onChange={fileHandleChange(setFieldValue)}
                                />
                                <label htmlFor="upload-bg" className="settings-root__company-form-copy">
                                    Загрузить <DownloadIcon />
                                </label>
                            </div>

                            <div className="settings-root__company-form">
                                <p className="settings-root__company-form-label">
                                    Логотип:
                                </p>
                                <InputForm
                                    type="file"
                                    id="upload"
                                    propertyName="logo"
                                    accept=".png, .jpg, .jpeg"
                                    className="settings-root__company-form-field --short-length --upload"
                                    onChange={fileHandleChange(setFieldValue)}
                                />
                                <label htmlFor="upload" className="settings-root__company-form-copy">
                                    Загрузить <DownloadIcon />
                                </label>
                            </div>

                            {data && data.background && (
                                <div className="settings-root__preview-img">
                                    <img src={data.background || topImage} alt="#" />
                                    <img src={data.logo || radiance_logo} alt="" className="logo-icon" />
                                </div>
                            )}
                            <div className="settings-root__company-form switch-root">
                                <p className="settings-root__company-form-label">
                                    Подтверждать заявки администратором:
                                </p>
                                <CustomSwitch
                                    className="switch-form"
                                    checked={state.admin_orders}
                                    handleChange={switchHandlerAdmin}
                                />
                            </div>
                            <div className="settings-root__company-form switch-root">
                                <p className="settings-root__company-form-label">
                                    Отправлять напоминания клиенту на WhatsApp?
                                </p>
                                <CustomSwitch
                                    className="switch-form"
                                    checked={state.is_whatsapp}
                                    handleChange={switchHandlerWA}
                                />
                            </div>
                            <div className="settings-root__company-form switch-root">
                                <p className="settings-root__company-form-label">
                                    Подтверждение номера клиента через SMS?
                                </p>
                                <CustomSwitch
                                    className="switch-form"
                                    checked={state.is_sms}
                                    handleChange={switchHandlerSMS}
                                />
                            </div>
                            <div className="settings-root__company-form">
                                <p className="settings-root__company-form-label">
                                    Месторасположение на карте:
                                </p>
                                <span className="settings-root__company-form-sub-label">
                                    Укажите месторасположение вашего салона
                                </span>
                                <div className="leaflet-map" id="map">
                                    <LeafletMap
                                        onChange={handleMarker}
                                        initCoordinates={{ lat: state.latitude, lng: state.longitude }}
                                    />
                                </div>
                            </div>

                            <Button disabled={!isActive && !dirty}
                                type="submit"
                                variant="contained"
                                className="settings-root__company-form-btn"
                            >
                                Сохранить
                            </Button>
                        </form>
                    )
                }
            </Formik>

            {/*/!*{hasRemoveButton && data && !data.is_main && (*!/ // TODO waiting api*/}
            {/*    <Button*/}
            {/*        type="button"*/}
            {/*        onClick={onRemoveSalon}*/}
            {/*        variant="contained"*/}
            {/*        className="settings-root__company-form-btn --red"*/}
            {/*    >*/}
            {/*        Удалить*/}
            {/*    </Button>*/}
            {/*)}*/}

        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    fetchCreateSalon: data => dispatch(fetchCreateSalon(data)),
    fetchEditSalonInfo: (salonId, data) => dispatch(fetchEditSalonInfo(salonId, data)),
    fetchGetBalance: id => dispatch(fetchGetBalance(id))
})

export default connect(null, mapDispatchToProps)(CompanySettings);