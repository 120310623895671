import React from 'react';

import Colors from "../../../global/styles/Colors";
import HelpCircleIcon from "../../../components/kit/Icons/HelpCircleIcon";
import {SIDE_BAR_NAV_ICONS} from '../../../global/strategies/pageStrategy'
import MenuIcon from "../../../components/kit/Icons/MenuIcon";
import {history} from "../../../store/configureStore";
import {ROUTE_HOME, ROUTE_SETTINGS} from "../../../global/Constants/Constants";
import ReactTooltip from "react-tooltip";

const SideBar = ({activeMenu, salons, salon}) => {
    const onSelectElement = (link) => {
        if (link === ROUTE_SETTINGS && salons && salons[0]) {
            history.push(`${ROUTE_SETTINGS}${!!salon ? salon.id : salons[0].id}`)
        } else {
            history.push(link)
        }
    };

    return (
        <div className="side-bar-root" style={{backgroundColor: Colors.SEMANTIC['blue']}}>
            <div className="side-bar-root__icon">
                <MenuIcon width={24} height={24} color={Colors.NEUTRAL['white']} opacity={1}/>
            </div>
            {SIDE_BAR_NAV_ICONS.map((el, ndx) => (
                <div
                    onClick={() => onSelectElement(el.link)}
                    className={`
                    side-bar-root__icon`
                    }
                    key={ndx}

                    style={{
                        backgroundColor: `${activeMenu === el.link ? Colors.SEMANTIC['pink'] : 'transparent'}`
                    }}>
                    <div data-tip data-for={`nav-tooltip-${ndx}`} className="side-bar-root__nav-item">
                        <span>
                        {el.icon}
                        </span>
                        <ReactTooltip id={`nav-tooltip-${ndx}`}
                                      place="right"
                                      className="side-bar-tooltip"
                                      effect="solid">
                            {el.title}
                        </ReactTooltip>
                    </div>

                </div>
            ))}
            {activeMenu === ROUTE_HOME &&
            <div className="side-bar-root__icon--questions">
                <HelpCircleIcon
                    width={24} height={24}
                    color={Colors.NEUTRAL['white']} opacity={1}/>
            </div>
            }
        </div>
    );
};

export default SideBar;