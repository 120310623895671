import React from 'react';
import filterIcon from '../../../assets/Icons/filterIcon.svg'

import './index.scss'

const Filter = (
    {
        placeholder = '',
        filter = true,
        onClick,
        setTitle,
        onSubmit,
        value
    }
    ) => {
    return (
        <div className="wrapper-filter">
            <input
                type="text"
                placeholder={placeholder}
                onChange={setTitle}
                onKeyPress={e => e.key === 'Enter' && value.trim().length && onSubmit()}
                value={value}
            />

            { filter &&
                <div className="button" onClick={onClick}>
                    <img src={filterIcon} alt=""/>
                </div>
            }
        </div>
    );
};

export default Filter;