import React from 'react';
import Pagination from "@material-ui/lab/Pagination";

import './index.scss';

const CommonPagination = ({count, currentPage, onChange}) => {
    return (
        <Pagination
            className="common-pagination-root"
            count={count}
            page={currentPage}
            variant="outlined"
            onChange={onChange}
            shape="rounded"/>
    );
};

export default CommonPagination;