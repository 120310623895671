import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CheckIcon from "../../../../components/kit/Icons/CheckIcon";
import Colors from "../../../../global/styles/Colors";

import '../index.scss';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "white",
        borderRadius: '28px 28px 0px 0px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 0),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'absolute',
        bottom: '-1px',
        width: '85%'
    },
    icon: {
        width: '64px',
        height: '64px',
        borderRadius: '50%',
        backgroundColor: Colors.PRIMARY['purple_3'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        color: Colors.PRIMARY['purple'],
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '20px',
        width: '100%',
        padding: '18px 0',
        borderRadius: '0 0 12px 12px',
        textAlign: 'center',
        background: 'none',
        border: 'none',
        margin: '28px 0 0',
        cursor: 'pointer'
    },
    title: {
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'center',
        width: '70%',
        margin: '16px 0 0'
    },
    subTitle: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        width: '70%',
        margin: '16px 0 0'

    }
}));

const OnlineRecordModal = ({isOpenModal, onClose}) => {
    const classes = useStyles();
    return (
        <div className="online-record-modal">
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={[classes.modal, "online-record-modal__modal"].join(' ')}
                open={isOpenModal}
                onClose={onClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpenModal}>
                    <div className={[classes.paper, "online-record-modal__paper"].join(' ')}>
                        <div className={classes.icon}>{
                            <CheckIcon
                                width={18}
                                height={13}
                                color={Colors.PRIMARY['purple']}/>}
                        </div>
                        <h2 className={classes.title} style={{color: Colors.NEUTRAL['black']}}>Заявка на
                            запись отправлено успешно!</h2>
                        <p className={classes.subTitle} style={{color: Colors.NEUTRAL['purple_black']}}>В
                            течении 5 минут менеджер салона вам перезвонит
                            для подтверждения записи</p>
                        <button className={classes.button} onClick={onClose}>OK</button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

export default OnlineRecordModal;