import React from 'react';
import SearchIcon from "../Icons/SearchIcon";
import Colors from "../../../global/styles/Colors";

import './index.scss';

const SearchField = (
    {
        placeholder = "Поиск...",
        color = Colors.SEMANTIC['blue'],
        handleChange,
        value,
        name = ""
    }) => {
    return (
        <div className="search-field"
             style={{
                 backgroundColor: Colors.NEUTRAL['white'],
                 border: `1px solid ${Colors.NEUTRAL['border']}`
             }}>
            <span className="search-field__icon">
                <SearchIcon width={16} height={16} color={color}/>
            </span>
            <input type="text" value={value} name={name} className="search-field__input" placeholder={placeholder}
                   onChange={handleChange}/>
        </div>
    );
};

export default SearchField;