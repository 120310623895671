import axios from "../../Api/axios-api";
import {ROUTE_LIST_MASTERS_OF_SERVICES, ROUTE_LIST_SALONS_OF_SERVICES} from "../../Api/Routes";

//Get master of services
export const FETCH_GET_LIST_MASTERS_SERVICES_REQUEST = 'FETCH_GET_LIST_MASTERS_SERVICES_REQUEST'
export const FETCH_GET_LIST_MASTERS_SERVICES_SUCCESS = 'FETCH_GET_LIST_MASTERS_SERVICES_SUCCESS'

const fetchGetListServicesRequest = () => ({type: FETCH_GET_LIST_MASTERS_SERVICES_REQUEST})
const fetchGetListServicesSuccess = data => ({type: FETCH_GET_LIST_MASTERS_SERVICES_SUCCESS, value: data})

export const getListMastersServices = (id) => {
    return dispatch => {
        dispatch(fetchGetListServicesRequest())
        return axios.get(ROUTE_LIST_MASTERS_OF_SERVICES + id)
            .then(response => {
                dispatch(fetchGetListServicesSuccess(response.data))
            })
            .catch(() => {
                console.log('[here]')
            })
    }
}

// get pagination master
export const FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_REQUEST = 'FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_REQUEST'
export const FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_SUCCESS = 'FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_SUCCESS'

const fetchPagePaginationOfMasterServicesRequest = () => ({type: FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_REQUEST})
const fetchPagePaginationOfMasterServicesSuccess = data => ({type: FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_SUCCESS, value: data})

export const getListMasterPaginationOfServices = (url) => {
    return dispatch => {
        dispatch(fetchPagePaginationOfMasterServicesRequest())
        return axios.get(url)
            .then(response => {
                dispatch(fetchPagePaginationOfMasterServicesSuccess(response.data))
            })
    }
}


// get salons of services
export const FETCH_GET_LIST_SALONS_SERVICES_REQUEST = 'FETCH_GET_LIST_SALONS_SERVICES_REQUEST'
export const FETCH_GET_LIST_SALONS_SERVICES_SUCCESS = 'FETCH_GET_LIST_SALONS_SERVICES_SUCCESS'

const fetchGetListSalonsServicesRequest = () => ({type: FETCH_GET_LIST_SALONS_SERVICES_REQUEST})
const fetchGetListSalonsServicesSuccess = data => ({type: FETCH_GET_LIST_SALONS_SERVICES_SUCCESS, value: data})

export const getListSalonsServices = (id) => {
    return dispatch => {
        dispatch(fetchGetListSalonsServicesRequest())
        return axios.get(ROUTE_LIST_SALONS_OF_SERVICES + id)
            .then(response => {
                dispatch(fetchGetListSalonsServicesSuccess(response.data))
            })
    }
}

// get pagination salon
export const FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_REQUEST = 'FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_REQUEST'
export const FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_SUCCESS = 'FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_SUCCESS'

const fetchPagePaginationOfSalonsServicesRequest = () => ({type: FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_REQUEST})
const fetchPagePaginationOfSalonsServicesSuccess = data => ({type: FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_SUCCESS, value: data})

export const getListSalonsPaginationOfServices = (url) => {
    return dispatch => {
        dispatch(fetchPagePaginationOfSalonsServicesRequest())
        return axios.get(url)
            .then(response => {
                dispatch(fetchPagePaginationOfSalonsServicesSuccess(response.data))
            })
    }
}