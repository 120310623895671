import React from 'react';
import Colors from "../../../global/styles/Colors";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import './index.scss';

const FinanceElement = ({data, onSelect}) => {

    function onSelectItem() {
        // onSelect(data.id)
    }
    return (
        <TableRow onClick={onSelectItem}>
            <TableCell align="left">
                <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.name}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.count}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.earned}
                </p>
            </TableCell>
        </TableRow>
    );
};

export default FinanceElement;