import {FETCH_GET_SALON_DETAIL_REQUEST, FETCH_GET_SALON_DETAIL_SUCCESS} from './detailedSalonAction'

const initialState = {
    response: false,
    salonDetail: null
}

export const detailSalon = (state = initialState, action) => {
    switch (action.type){
        case FETCH_GET_SALON_DETAIL_REQUEST:
            return {...state, response: true}
        case FETCH_GET_SALON_DETAIL_SUCCESS:
            return {...state, response: false, salonDetail: action.value}
        default: return state
    }
}