import React from 'react';

const ClockIcon = ({width = 16, height = 16, color = "#FFA26B"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.00033 1.99984C4.68662 1.99984 2.00033 4.68613 2.00033 7.99984C2.00033 11.3135 4.68662 13.9998 8.00033 13.9998C11.314 13.9998 14.0003 11.3135 14.0003 7.99984C14.0003 4.68613 11.314 1.99984 8.00033 1.99984ZM0.666992 7.99984C0.666992 3.94975 3.95024 0.666504 8.00033 0.666504C12.0504 0.666504 15.3337 3.94975 15.3337 7.99984C15.3337 12.0499 12.0504 15.3332 8.00033 15.3332C3.95024 15.3332 0.666992 12.0499 0.666992 7.99984Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.00033 3.33317C8.36852 3.33317 8.66699 3.63165 8.66699 3.99984V7.58781L10.9651 8.73689C11.2945 8.90154 11.4279 9.30199 11.2633 9.63131C11.0986 9.96063 10.6982 10.0941 10.3688 9.92945L7.70218 8.59612C7.47633 8.48319 7.33366 8.25235 7.33366 7.99984V3.99984C7.33366 3.63165 7.63214 3.33317 8.00033 3.33317Z"
                  fill={color}/>
        </svg>
    );
};

export default ClockIcon;