import React, {useEffect, useState} from 'react';
import DashboardHeader from "../../../components/DasboardHeader/DashboardHeader";
import Colors from "../../../global/styles/Colors";
import CreateBlock from "../../../components/CreateBlock/CreateBlock";
import './index.scss';
import ApplicationElement from "./ApplicationElement";
import CommonPagination from "../../../components/CommonPagintation/CommonPagintation";
import {connect} from "react-redux";
import {fetchApplicationsGet, fetchChangeApplicationStatus} from "../../../store/applications/applicationsAction";
import {history} from "../../../store/configureStore";
import {APPLICATION_ACCEPTED, APPLICATION_NEW} from "../../../global/Constants/Constants";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import {useDebounce} from "../../../components/UseDebounce/UserDebounce";
import {isEmptyArray} from "formik";
import NoResultIcon from "../../../components/kit/Icons/NoResultIcon";
import {changeSalonAction} from "../../../store/core/coreActions";


export const STATUS_OPTIONS = [
    {
        name: "Все",
        status: ""
    },
    {
        name: "Обработанные",
        status: APPLICATION_ACCEPTED
    },
    {
        name: "Необработанные",
        status: APPLICATION_NEW
    }
]

export const INITIAL_PAGE = 1;

const Applications = (
    {
        salons,
        salon,

        fetchApplicationsGet,
        applicationsList,

        requested,

        fetchChangeApplicationStatus,
        changeSalonAction
    }
) => {

    const [state, setState] = useState({
        salon: "",
        date: new Date(),
        status: "",
        search: "",
        page: INITIAL_PAGE
    })

    const debouncedSearchTerm = useDebounce(state.search, 700);

    useEffect(function () {
        if (salons && salons[0]) {
            setState(prevState => {
                return {...prevState, salon: !!salon ? salon.id : salons[0].id}
            })
        }
    }, [salon, salons])

    useEffect(function () {
        const filter = {...state};
        filter.search = debouncedSearchTerm;
        if (state.salon) {
            fetchApplicationsGet(filter)
        }
    }, [state.date, state.salon, state.status, state.page, debouncedSearchTerm])

    function onChangeSalon(e) {
        setState(prevState => {
            return {...prevState, salon: e.target.value, page: INITIAL_PAGE}
        })
        const salon = salons.find(salon => salon.id === e.target.value)
        changeSalonAction(salon)
    }

    function handleDateChange(date) {
        setState(prevState => {
            return {...prevState, date, page: INITIAL_PAGE}
        })
    }

    function changeTab(item) {
        setState(prevState => {
            return {...prevState, status: item.status, page: INITIAL_PAGE}
        })
    }

    function onChangePage(e, page) {
        setState(prevState => {
            return {...prevState, page: page}
        })
    }

    function onSearch(e) {
        e.persist()
        setState(prevState => {
            return {...prevState, search: e.target.value, page: INITIAL_PAGE}
        })
    }

    function redirectToCalendar() {
        history.push(`/calendar/${state.salon}/${state.date}`)
    }

    async function onChangeStatus(id, status) {
        const data = {
            is_confirmed: status
        }
        await fetchChangeApplicationStatus(id, data)
        const filter = {...state};
        filter.search = debouncedSearchTerm;
        await fetchApplicationsGet(filter)
    }

    return (
        <React.Fragment>
            <DashboardHeader
                title="Список заявок" buttonText="Добавить запись"
                tabOptions={STATUS_OPTIONS}
                selectOptions={salons}
                selectValue={state.salon}
                fieldName="salon"
                handleChange={onChangeSalon}
                isActiveCalendar
                onChangeDate={handleDateChange}
                onClickAdd={redirectToCalendar}
                activeTab={state.status}
                activateTab={changeTab}
                onSearch={onSearch}
                searchValue={state.search}
            >
            </DashboardHeader>
            <div className="dashboard-notifications-root">
                <Table className="dashboard-notifications-root__table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Статус
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Дата записи
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Имя и фамилия
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Номер телефона
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Длительность
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Услуга
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Сумма
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__notification-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Действие
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!requested && applicationsList && applicationsList.count > 0 && applicationsList.results &&
                        applicationsList.results.map(data => (
                            <ApplicationElement
                                data={data}
                                key={data.id}
                                onChangeStatus={onChangeStatus}/>
                        ))}
                    </TableBody>
                </Table>

                {requested && (
                    <CommonSpinner isLoading={requested} centered/>
                )}


                {!requested && !!debouncedSearchTerm && applicationsList &&
                applicationsList.results &&
                isEmptyArray(applicationsList.results) &&
                <div className="no-result">
                    <NoResultIcon width={100} height={140}/>
                    <p className="no-result__text">Результатов не найдено</p>
                </div>
                }

                {!requested && !debouncedSearchTerm && applicationsList && applicationsList.count === 0 && (
                    <CreateBlock
                        title="Заявок нет на сегодня"
                        subTitle="Добавьте услуги, которые вы предоставляете вашим клиентам. без этого вы не сможете записывать клиентов"
                    />
                )}

                {!requested && applicationsList && applicationsList.page_count > INITIAL_PAGE && (
                    <CommonPagination count={applicationsList.page_count} currentPage={state.page}
                                      onChange={onChangePage}/>
                )}
            </div>
        </React.Fragment>
    )
}

export const mapStateToProps = state => ({
    salons: state.core.salons,
    salon: state.core.salon,
    requested: state.applications.requested,
    applicationsList: state.applications.applicationsList
})

export const mapDispatchToProps = dispatch => ({
    fetchApplicationsGet: filters => dispatch(fetchApplicationsGet(filters)),
    fetchChangeApplicationStatus: (id, data) => dispatch(fetchChangeApplicationStatus(id, data)),
    changeSalonAction: salon => dispatch(changeSalonAction(salon))
})

export default connect(mapStateToProps, mapDispatchToProps)(Applications);