import React, {useEffect, useState} from 'react';
import {Calendar, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment";
import TimeOrangeIcon from "../../../components/kit/Icons/TimeOrangeIcon";
import {APPLICATION_ACCEPTED} from "../../../global/Constants/Constants";
import ReactTooltip from "react-tooltip";
import CheckSimpleIcon from "../../../components/kit/Icons/CheckSimpleIcon";

const localizer = momentLocalizer(moment);

const MIN_DURATION = 30;

const CustomHeader = (data) => {
    return <div className="calendar__event-head" key={data.resource.resourceId}>
        <p className="calendar__event-head-name">
            {data.label}
        </p>
        <p className="calendar__event-head-status">
            {data.resource.apps && !data.resource.apps.length ? "Нет записей" :
                <>
                    <span className="iconCheck"><CheckSimpleIcon width={14} height={14} color="#95909B"/></span>
                    Есть запись
                    <span
                        className="count">{data.resource.apps && data.resource.apps.length}</span>
                </>
            }
        </p>
    </div>

}

const CustomEventComponent = (data) => {

    function approve() {
        data.event.onApprove(data.event.id)
    }

    function reject() {
        data.event.onReject(data.event.id)
    }

    function onSelectEvent() {
        data.event.onSelectEvent(data.event.id)
    }

    return (
        <div
            className={`event-custom-paper ${data.event.status === APPLICATION_ACCEPTED ? "--accepted" : "--waiting"}`}
            data-tip data-for={`event-tooltip-${data.event.id}`}
            onClick={onSelectEvent}>
            <div className={`head-line ${data.event.status === APPLICATION_ACCEPTED ? "--accepted" : "--waiting"}`}/>
            <div className="name">
                {data.title || ""}

                {data.event.duration === MIN_DURATION && (
                    <div className="service">
                        <span>{data.event.duration} мин</span>
                        <span>&nbsp;{data.event.total_sum} сом</span>
                    </div>
                )}
                <TimeOrangeIcon width={14} height={14}
                                color={data.event.status === APPLICATION_ACCEPTED ? "#FFA26B" : "#C2BCCE"}/>
            </div>
            <div className="service">
                <span>{data.event.duration} мин</span>
                <span>{data.event.total_sum} сом</span>
            </div>
            {data.event.status !== APPLICATION_ACCEPTED && (
                <ReactTooltip id={`event-tooltip-${data.event.id}`}
                              delayHide={100}
                              place="top" globalEventOff='click' clickable={true}
                              effect="solid" isCapture={true}
                              className="event-custom-paper__tooltip-root">
                    <p className="title-close">
                        Бронирование
                    </p>
                    <div className="event-custom-paper__tooltip">
                        <button
                            className="event-custom-paper__tooltip-element"
                            onClick={approve}>
                            Подтвердить
                        </button>
                        <button
                            className="event-custom-paper__tooltip-element"
                            onClick={reject}>
                            Отклонить
                        </button>
                    </div>
                </ReactTooltip>
            )}
        </div>
    )
}

function checkActiveDay(date, breakStart, breakFinish) {
    return moment(date).isBefore(moment()) ||
        (
            breakStart === moment(date).format("HH:mm:ss")
            ||
            (moment(date).format("HH:mm:ss") > breakStart && moment(date).format("HH:mm:ss") < breakFinish)
        )
}

const CustomEventCalendar = (
    {
        events,
        resources,
        startTimeAndDate,
        finishTimeAndDate,
        handleSelect,
        date,
        breakStart,
        breakFinish,
        onSelectEvent
    }
) => {

    const [eventData, setEventData] = useState(events)

    useEffect(function () {
        setEventData(eventData)
    }, [eventData, events])

    return (
        <Calendar
            events={eventData}
            localizer={localizer}
            defaultView={Views.DAY}
            views={['day']}
            step={30}
            defaultDate={date}
            resources={resources}
            resourceIdAccessor="resourceId"
            resourceTitleAccessor="resourceTitle"
            min={startTimeAndDate}
            max={finishTimeAndDate}
            renderable={true}
            toolbar={false}
            resizable={true}
            components={{
                event: CustomEventComponent,
                day: {
                    resourceHeader: CustomHeader
                }
            }}
            selectable
            onSelectSlot={handleSelect}
            slotPropGetter={date => checkActiveDay(date, breakStart, breakFinish) ?
                {className: "disabled-slot"} :
                {className: "active-slot"}}
        />
    );
};

export default CustomEventCalendar;