import {FETCH_GET_FILTER_SALON_REQUEST, FETCH_GET_FILTER_SALON_SUCCESS} from "./filterSalonAction";

const initialState = {
    response: false,
    listSalon: null,

    paginationResponse: false,
    nextPage: null,
    previousPage: null,
}

export const filterPageSalonReducer = (state = initialState, action) => {
    switch (action.type){
        case FETCH_GET_FILTER_SALON_REQUEST:
            return {...state, response: true}
        case FETCH_GET_FILTER_SALON_SUCCESS:
            return {...state, response: false, listSalon: action.value }
        default: return state
    }
}