import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {withStyles} from '@material-ui/core/styles';
import FormGroup from "@material-ui/core/FormGroup";


const BlueSwitch = withStyles({
    switchBase: {
        color: "#ECEBED",
        padding: '2px',
        '&$checked': {
            color: "#0084F4",
        },
        '&$checked + $track': {
            backgroundColor: "#fff",
        },
    },
    checked: {
        backgroundColor: "#fff",
    },
    track: {
        boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.07)',
        padding: 0,
        backgroundColor: "#fff",
    },
    thumb: {
        width: 24,
        height: 24,

    },
    root: {
        width: 48,
        height: 28,
        padding: 0,
        borderRadius: 16,
        boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.07)',
        backgroundColor: "#fff"
    },
})(Switch);

const CustomSwitch = ({checked, handleChange, name, className}) => {
    return (
        <FormGroup className={className}>
            <FormControlLabel
                control={<BlueSwitch checked={checked} onChange={handleChange} name={name}/>}
            />
        </FormGroup>
    );
};

export default CustomSwitch;