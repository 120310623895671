import React from 'react';

const TimeIcon = ({width, height, className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none"
             className={className}>
            <path
                d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM9 6.75H5.25V3H6.75V5.25H9V6.75Z"
                fill="#D0C9D6"/>
        </svg>
    );
};

export default TimeIcon;