import axios from "../Api/axios-api";
import {ROUTE_REPORT} from "../Api/Routes";
import {NotificationManager} from "react-notifications";
import {ERROR_TEXT} from "../../global/Constants/Constants";

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_GET = 'FETCH_REPORTS_GET';
export const FETCH_PAYBOX_REQUEST = 'FETCH_PAYBOX_REQUEST';
export const FETCH_PAYBOX_GET_SUCCESS = 'FETCH_PAYBOX_GET_SUCCESS'
export const FETCH_MESSAGE_REQUEST = 'FETCH_MESSAGE_REQUEST';
export const FETCH_MESSAGE_GET_SUCCESS = 'FETCH_MESSAGE_GET_SUCCESS'

const fetchReportsSuccess = reports => ({type: FETCH_REPORTS_GET, reports});
const fetchReportsRequest = () => ({type: FETCH_REPORTS_REQUEST})
const fetchPayboxRequest = () => ({type: FETCH_PAYBOX_REQUEST});
const fetchPayboxSuccess = paybox => ({type: FETCH_PAYBOX_GET_SUCCESS, paybox});
const fetchMessageRequest = () => ({type: FETCH_MESSAGE_REQUEST});
const fetchMessageSuccess = messages => ({type: FETCH_MESSAGE_GET_SUCCESS, messages});

export const fetchReportsGet = data => {
    return dispatch => {
        dispatch(fetchReportsRequest())
        return axios.post(ROUTE_REPORT, data).then(
            response => {
                dispatch(fetchReportsSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchPayboxReport = (params) => {
    return dispatch => {
        dispatch(fetchPayboxRequest())
        return axios.get(`/payments_report/?salon=${params.salon}&start_date=${params.start_date}&end_date=${params.end_date}`).then(
            response => {
                dispatch(fetchPayboxSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchMessageReport = (params) => {
    return dispatch => {
        dispatch(fetchMessageRequest())
        return axios.get(`/sms_report/?salon=${params.salon}&start_date=${params.start_date}&end_date=${params.end_date}`).then(
            response => {
                dispatch(fetchMessageSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}