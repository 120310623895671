import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ArrowGlyphIconDown from "../Icons/ArrowGlyphIconDown";
import Colors from "../../../global/styles/Colors";
import './index.scss';
import SelectFormOption from "./SelectFormOption/SelectFormOption";
import SimpleOption from "./SimpleOption/SimpleOption";
import SearchField from "../SearchField/SearchField";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        backgroundColor: Colors.NEUTRAL['white'],
        borderRadius: '6px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
        margin: '4px 0',
        paddingBottom: '10px'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    ExpansionPanelDetails: {
        padding: '0',
        display: 'initial',
    },
    ExpansionPanelSummary: {
        padding: '0 15px'
    }
}));

const SelectFormExpandPanel = (
    {
        title,
        options,
        selectedOptions,
        onSelect,
        onSelectSimpleOption,
        selectedSimpleOptions,
        optionVariant = "full",
        disabled
    }
) => {
    let totalCount = 0;
    for (let item of selectedOptions) {
        if (options.some(el => el.id === item.id)) {
            totalCount++;
        }
    }

    const [search, handleChange] = useState("")

    const filterOptions = options && search ?
        [...options].filter(it => it.label.toLowerCase().includes(search.toLowerCase()))
        : options

    const classes = useStyles();
    const listEmployees = selectedOptions?.[0]?.employees
    return (
        <div className={[classes.root, "select-expand-panel_root"].join(' ')}>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ArrowGlyphIconDown width={9} height={6}/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.ExpansionPanelSummary}
                >
                    <Typography className={classes.heading}>{title || ''}</Typography>
                    {totalCount > 0 &&
                    <span style={{color: Colors.PRIMARY['purple'], backgroundColor: Colors.PRIMARY['purple_3']}}
                          className="select-expand-panel_root__count">{totalCount}</span>
                    }
                </ExpansionPanelSummary>

                {optionVariant === "simple" && (
                    <SearchField
                        placeholder="Поиск"
                        color="#D0C9D6"
                        value={search}
                        handleChange={e => handleChange(e.target.value)}
                    />
                )}

                {optionVariant === "full" && options && options.map((el, ndx) => {
                  if(!el.employees.length){
                    return null
                  }

                  return (
                     <ExpansionPanelDetails className={ classes.ExpansionPanelDetails } key={ `${ el.id }-${ ndx }` }>
                       <SelectFormOption
                         onSelect={ onSelect }
                         item={ el }
                         isChecked={ selectedOptions.some(it => it.id === el.id) }
                         listEmployees={listEmployees}
                       />
                     </ExpansionPanelDetails>
                   )
                })}

                {optionVariant === "simple" && filterOptions && filterOptions.map((el, ndx) => (
                    <ExpansionPanelDetails className={classes.ExpansionPanelDetails} key={`${el.id}-${ndx}`}>
                        <SimpleOption
                            onSelect={onSelectSimpleOption}
                            item={el}
                            disabled={selectedSimpleOptions.some(service => service.pk === el.pk) || disabled}
                        />
                    </ExpansionPanelDetails>
                ))}

            </ExpansionPanel>
        </div>
    );
};

export default SelectFormExpandPanel;