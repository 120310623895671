import React from 'react';
import topImage from '../../../../assets/dybenko-095.png';
import radiance_logo from '../../../../assets/radiance_logo.png';
import Colors from "../../../../global/styles/Colors";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";
import WomanIcon from "../../../../components/kit/Icons/WomanIcon";
import {STEP_CHOOSE_MASTER, STEP_CHOOSE_SERVICE} from "../OnlineRecord";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";
import ServiceIcon from "../../../../components/kit/Icons/ServiceIcon";

const SelectServiceMaster = ({onSelect, salon, clientRecords, onSelectEdit, handleBackNav}) => {

    function onSelectService() {
        onSelect(STEP_CHOOSE_SERVICE)
    }

    function onSelectMaster() {
        onSelect(STEP_CHOOSE_MASTER)
    }

    return (
        <div className="select-service-root">
            <div className="select-service-root__top-info">
                <div style={{backgroundImage: `url(${salon.background || topImage})`}} className="select-service-root__top-info__top-image" />
                <img src={salon.logo || radiance_logo} alt="img" className="select-service-root__top-info__company_logo"/>
            </div>

            <h1 className="select-service-root__company_title" style={{color: Colors.NEUTRAL['black'], position: 'relative'}}>
                {salon.name}
                {/*<div onClick={handleBackNav} className="record-nav-back-icon">*/}
                {/*    <ArrowLargeIcon/> */}
                {/*</div>*/}
            </h1>
            <h4 className="select-service-root__company_address" style={{color: Colors.TEXT['text_color']}}>
                {salon.address}
            </h4>

            <div className="select-service-root__service-variant">
                <div className="select-service-root__service-variant--service" onClick={onSelectService}>
                    <div className="select-service-root__service-variant__circle"
                         style={{backgroundColor: Colors.PRIMARY['purple']}}>
                        <ServiceIcon width={55} height={55}/>
                    </div>
                    <p className="select-service-root__service-variant__title"
                       style={{color: Colors.NEUTRAL['black']}}>
                        Выбрать услуги
                    </p>
                </div>
                <div className="select-service-root__service-variant--master" onClick={onSelectMaster}>
                    <div className="select-service-root__service-variant__circle"
                         style={{backgroundColor: Colors.SEMANTIC['pink']}}>
                        <WomanIcon width={16} height={16} color={Colors.NEUTRAL['white']}/>
                    </div>
                    <p className="select-service-root__service-variant__title"
                       style={{color: Colors.NEUTRAL['black']}}>
                        Выбрать специалиста
                    </p>
                </div>
            </div>

            {clientRecords && clientRecords.length ? (
                <GeneralButton
                    title="Изменить запись"
                    bgColor={Colors.SEMANTIC['pink']}
                    textColor="#fff"
                    className="select-service-root__change-record-btn"
                    onClick={onSelectEdit}
                />
            ) : null}
        </div>
    );
};

export default SelectServiceMaster;