import React from 'react';
import RecordsServices from "./RecordsServices/RecordsServices";
import ListServices from "./ListServices/ListServices";

const ListServicesMain = (props) => {

    // Список Направления mBank
    if(!props.match.params.type){
        return (
            <RecordsServices
                props={props}
                history={props.history}
                defaultPage={false}
            />
        );
    // Список услуг по id mBank
    }else if (Number.isInteger(+props.match.params.type)) {
        return (
            <ListServices
                props={props}
                params={props.match.params.type}
                defaultPage={false}
                history={props.history}
            />
        );
    }
    //Список услуг по id Обычный
    else if (!Number.isInteger(+props.match.params.type) &&
        Number.isInteger(+props.match.params.id)) {
        return (
            <ListServices
                props={props}
                params={props.match.params.id}
                defaultPage={true}
                history={props.history}
            />
        );
    }
    //Список направлений обычный
    else if (!Number.isInteger(+props.match.params.type)){
        return (
            <RecordsServices
                props={props}
                history={props.history}
                defaultPage={true}
            />
        );
    }
};

export default ListServicesMain;