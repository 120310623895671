import axios from "../../Api/axios-api";
import {ROUTE_FILTER} from "../../Api/Routes"

export const FETCH_GET_FILTER_CATEGORY_REQUEST = 'FETCH_GET_FILTER_CATEGORY_REQUEST'
export const FETCH_GET_FILTER_CATEGORY_SUCCESS = 'FETCH_GET_FILTER_CATEGORY_SUCCESS'

const fetchGetFilterCategoryRequest = () => ({type: FETCH_GET_FILTER_CATEGORY_REQUEST})
const fetchGetFilterCategorySuccess = data => ({type: FETCH_GET_FILTER_CATEGORY_SUCCESS, value: data})

export const fetchGetFilter = () => {
    return dispatch => {
        dispatch(fetchGetFilterCategoryRequest())
        return axios.get(ROUTE_FILTER)
            .then(response => {
                dispatch(fetchGetFilterCategorySuccess(response.data))
            })
    }
}
