import React from 'react';
import Colors from "../../../../global/styles/Colors";
import CheckIcon from "../../Icons/CheckIcon";
import TimeIcon from "../../Icons/TimeIcon";

const isClickService = (employees, selected) => {
  let result

  if(!selected || !employees){
    return true
  }

  for (let employee of employees){
    for (let select of selected) {
      result = employee === select
    }
  }

  return result
}

const SelectFormOption = ({item, onSelect, isChecked, listEmployees}) => {
   const canSelect = isClickService(item?.employees, listEmployees)

    function select() {
     if(canSelect){
       onSelect(item)

     }
    }

    return (
        <div className={ canSelect ? "select-expand-panel_root__option" : "select-expand-panel_root__option__deff" }
             onClick={select}
             style={{backgroundColor: canSelect ? Colors.NEUTRAL['white'] : Colors.NEUTRAL['light_gray'] }}>
            <div className="select-expand-panel_root__checkbox_custom">
                {isChecked ?
                    <div className="select-expand-panel_root__checkbox_custom--checked"
                         style={{
                             border: `1px solid ${Colors.PRIMARY['purple']}`,
                             backgroundColor: Colors.PRIMARY['purple']
                         }}>
                        <CheckIcon width={10} height={7} color={Colors.NEUTRAL['white']}/>
                    </div>
                    : <div className="select-expand-panel_root__checkbox_custom--none"
                           style={{border: `1px solid ${Colors.NEUTRAL['border']}`}}/>
                }
            </div>
            <div className="select-expand-panel_root__title">
                <p style={{color: Colors.NEUTRAL['black']}}
                   className="select-expand-panel_root__title__label">{item.label || item.name}</p>
                <p style={{color: Colors.TEXT['text_color']}}
                   className="select-expand-panel_root__title__time">
                    <TimeIcon width={16} height={16} className="select-expand-panel_root__title__icon"/>
                    {item.time} мин
                </p>
            </div>
            <p className="select-expand-panel_root__amount"
               style={{color: !isChecked ? Colors.TEXT['text_color'] : Colors.SEMANTIC['green']}}>{item.price} сом</p>
        </div>
    );
};

export default SelectFormOption;