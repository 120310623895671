import React, {useState} from 'react';
import './index.scss';
import InputForm from "../../../components/kit/InputForm/InputForm";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";
import Colors from "../../../global/styles/Colors";
import {Formik} from "formik";
import InputPhoneMask from "../../../components/kit/InputPhoneMask/InputPhoneMask";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {fetchUserAuthorization} from "../../../store/user/userAction";
import {connect} from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const Login = ({fetchUserAuthorization, userError}) => {

    const [state, setState] = useState({username: "", password: "", showPassword: false})

    function onSubmit(values) {
        fetchUserAuthorization(values)
    }

    function handleShowPassword() {
        setState(prevState => ({...prevState, showPassword: !state.showPassword}))
    }

    return (
        <div className="login-root">
            <div className="login-root__form">
                <h3>
                    Удобная система управления для салонов красоты
                </h3>
                <h2>Вход</h2>
                <Formik
                    initialValues={{username: state.username, password: state.password}}
                    validate={values => {
                        const error = {};
                        if (!values.username) {
                            error.username = "Обязательное поле"
                        }
                        if (!values.password) {
                            error.password = "Обязательное поле"
                        }
                        return error
                    }}
                    onSubmit={(values) => {
                        onSubmit(values)
                    }}
                >
                    {
                        ({
                             values,
                             errors,
                             touched,
                             handleChange,
                             dirty,
                             handleSubmit,
                             isSubmitting,
                         }) => (
                            <form onSubmit={handleSubmit}>
                                <InputPhoneMask
                                    className="login-root__phone-field"
                                    value={values.username}
                                    propertyName="username"
                                    onChange={handleChange}
                                />
                                {errors.username && touched.username && errors.username &&
                                <span className="field-error">{errors.username}</span>
                                }
                                {!errors.username && userError && userError.non_field_errors &&
                                <span className="error-text">{userError.non_field_errors}</span>
                                }
                                <InputForm
                                    onChange={handleChange}
                                    value={values.password}
                                    propertyName="password"
                                    type={state.showPassword ? "text" : "password"}
                                    label="Пароль"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleShowPassword}
                                                onMouseDown={handleShowPassword}
                                            >
                                                {state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    className="login-root__text-field"/>
                                {errors.password && touched.password && errors.password &&
                                <span className="field-error">{errors.password}</span>
                                }
                                {!errors.password && userError && userError.non_field_errors &&
                                <span className="error-text">
                                {userError.non_field_errors}
                            </span>
                                }

                                <div className="login-root__forgot-pass">
                                    <FormControlLabel
                                        control={<Checkbox/>}
                                        color="default"
                                        label="Запомнить пароль"
                                    />

                                    {/*<button className="login-root__forgot-btn" type="button">*/}
                                    {/*    Забыли пароль?*/}
                                    {/*/!*</button>*!/ TODO waiting api*/}
                                </div>

                                <GeneralButton
                                    type="submit"
                                    title="Войти"
                                    disabled={!dirty}
                                    className="login-root__submit-button"
                                    bgColor={dirty ? Colors.SEMANTIC['green'] : Colors.NEUTRAL['border']}
                                    textColor={Colors.NEUTRAL['white']}/>
                            </form>
                        )
                    }
                </Formik>
            </div>
        </div>
    );
};

export const mapStateToProps = state => ({
    userError: state.user.userError
})

export const mapDispatchToProps = dispatch => ({
    fetchUserAuthorization: data => dispatch(fetchUserAuthorization(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);