import {FETCH_GET_FILTER_CATEGORY_REQUEST, FETCH_GET_FILTER_CATEGORY_SUCCESS} from './filterAction'

const initialState = {
    response: false,
    category: null
}

export const categoryReduce = (state = initialState, action) => {

    switch (action.type){
        case FETCH_GET_FILTER_CATEGORY_REQUEST:
            return {...state, response: true}
        case FETCH_GET_FILTER_CATEGORY_SUCCESS:
            return {...state, response: false, category: action.value}
        default: return state
    }

}