import React from 'react';

import './index.scss'

import {NavLink} from "react-router-dom";

const ListSearch = ({list, link, btnActive}) => {

    if(!list?.count) {
        return (
            <div className="load-page">
                <div className="lds-dual-ring"/>
            </div>
        )
    }

    return (
        <>
            {list?.results.map((item, index) => {
                return (
                    <NavLink
                        to={`${link[btnActive] + item.id}`}
                        key={index}
                    >
                        <div className="list-search-wrapper">

                            <div className="list-item">
                                {item.image || item.logo ?
                                    <div className="list-item__image">
                                        <img src={item.image || item.logo} alt=""/>
                                    </div>
                                    :
                                    <div className="list-item__image-none">
                                        <span>{item.name[0]}</span>
                                    </div>
                                }

                                <div className="list-item__title">
                                    <span>{item.name}</span>
                                    <span className="list-item__title__rating">{item.rating || 0}</span>
                                </div>

                            </div>

                        </div>
                    </NavLink>
                )
            })}
        </>

    );

};

export default ListSearch;