import axios from "../Api/axios-api";
import {ROUTE_AUTH} from "../Api/Routes";
import {ERROR_TEXT, ROLE_MASTER, ROUTE_NOTIFICATIONS} from "../../global/Constants/Constants";
import {NotificationManager} from "react-notifications";

const gtag = () => ('event', 'login', {
    'event_category': 'form',
    'event_label': 'success'
});



export const FETCH_USER_AUTHORIZATION_SUCCESS = 'FETCH_USER_AUTHORIZATION_SUCCESS';
export const FETCH_USER_AUTHORIZATION_FAILURE = 'FETCH_USER_AUTHORIZATION_FAILURE';

const fetchUserAuthSuccess = auth => ({type: FETCH_USER_AUTHORIZATION_SUCCESS, auth});
const fetchUserAuthFailure = error => ({type: FETCH_USER_AUTHORIZATION_FAILURE, error});

export const fetchUserAuthorization = data => {
    return dispatch => {
        return axios.post(ROUTE_AUTH, data).then(
            response => {
                dispatch(fetchUserAuthSuccess(response.data));
                if (response.data.user_type === ROLE_MASTER) {
                    NotificationManager.info("Нет доступа")
                } else {
                    gtag()
                    window.ym((71319463,'reachGoal','login'))
                    window.location.pathname = ROUTE_NOTIFICATIONS
                }
            },
            err => {
                dispatch(fetchUserAuthFailure(err.response.data))
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_LOGOUT_USER = 'FETCH_LOGOUT_USER'
export const fetchLogout = () => {
    return {type: FETCH_LOGOUT_USER}
}