import React, {Component, useCallback, useEffect, useState} from 'react';
import DashboardHeader from "../../../components/DasboardHeader/DashboardHeader";
import Colors from "../../../global/styles/Colors";
import {withStyles} from "@material-ui/core";
import DashboardModal from "../../../components/DashboardModal/DashboardModal";
import {Formik, isEmptyArray} from "formik";
import InputForm from "../../../components/kit/InputForm/InputForm";
import SelectForm from "../../../components/kit/SelectForm/SelectForm";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";

import SwalModal from "../../../components/SwalModal/SwalModal";
import swal from "@sweetalert/with-react";
import CreateBlock from "../../../components/CreateBlock/CreateBlock";
import ServiceElement from "./ServiceElement";
import CommonPagination from "../../../components/CommonPagintation/CommonPagintation";
import {connect} from "react-redux";
import {
    fetchCreateService,
    fetchEditService,
    fetchRemoveService,
    fetchServicesGet
} from "../../../store/service/serviceActions";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import StaffElement from "../Staffs/StaffElement";
import Table from "@material-ui/core/Table";
import {INITIAL_PAGE} from "../Applications/Applications";
import {useDebounce} from "../../../components/UseDebounce/UserDebounce";
import './index.scss';
import {fetchCategories} from "../../../store/categories/categoriesActions";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import NoResultIcon from "../../../components/kit/Icons/NoResultIcon";
import CustomSelect from "../../../components/kit/CustomSelect/CustomSelect";
import {changeSalonAction} from "../../../store/core/coreActions";

const Services = (
    {
        fetchServicesGet,
        services,
        requested,

        fetchCategories,
        fetchCreateService,
        categories,

        fetchEditService,
        fetchRemoveService,
        changeSalonAction,

        salons,
        salon,
    }
) => {

    const [isOpenModal, setOpenModal] = useState(false)

    const [state, setState] = useState({
        category: "",
        name: '',
        price: '',
        time: '30'
    })

    const [params, setParams] = useState({
        search: "",
        page: INITIAL_PAGE,
        editingItem: null,
        salon: ""
    })

    const debouncedSearchTerm = useDebounce(params.search, 700);

    useEffect(function () {
        if (salons && salons[0]) {
            setParams(prevState => {
                return {...prevState, salon: !!salon ? salon.id : salons[0].id}
            })
        }

    }, [salon, salons])

    useEffect(function () {
        if(params.salon){
            getData()
            params.salon && fetchCategories({salon: params.salon});
        }
    }, [params.page, debouncedSearchTerm, params.salon])

    const onChangeSalon = useCallback(e => {
        setParams(prevState => {
            return {...prevState, salon: e.target.value, page: INITIAL_PAGE}
        })

        const salon = salons.find(salon => salon.id === e.target.value);

        changeSalonAction(salon)

    }, [changeSalonAction, salons])

    const getData = useCallback(() => {
        if (params.salon > 0) {
            fetchServicesGet(params.page, params.search, params.salon)
        }
    }, [fetchServicesGet, params.page, params.salon, params.search])


    const handleSearch = useCallback(e => {
        e.persist();
        setParams(prevState => {
            return {...prevState, search: e.target.value, page: INITIAL_PAGE}
        })
    }, [])

    const toggleModal = useCallback(() => {
        setOpenModal(!isOpenModal)
    }, [isOpenModal])

    function validateForm(values) {
        const error = {};
        if (!values.category) {
            error.category = "Обязательное поле"
        }
        if (!values.name) {
            error.name = "Обязательное поле"
        }
        if (!values.time) {
            error.time = "Обязательное поле"
        }
        if (!values.price) {
            error.price = "Обязательное поле"
        } else if (values.price < 0) {
            error.price = "Цена не может быть отрицательной"
        }
        return error
    }

    const onSubmit = useCallback(async values => {
        const data = {...values}
        data.time = Math.abs(data.time);
        data.salon = params.salon;
        if (params.editingItem) {
            await fetchEditService(params.editingItem.id, data)
        } else {
            await fetchCreateService(data)
        }
        toggleModal();
        await getData()
        setParams(prevState => ({...prevState, editingItem: null}))
    }, [fetchCreateService, fetchEditService, getData, params.editingItem, params.salon, toggleModal])

    const onChangePage = useCallback((e, page) => {
        setParams(prevState => {
            return {...prevState, page: page}
        })
    }, [])

    const onRemove = useCallback(async serviceId => {
        await fetchRemoveService(serviceId)
        setParams(prevState => ({...prevState, page: INITIAL_PAGE}))
        getData()
    }, [fetchRemoveService, getData])

    const onEdit = useCallback(item => {
        setParams(prevState => ({...prevState, editingItem: item}))
        setState(prevState => {
            return {
                ...prevState,
                name: item.name,
                category: item.category,
                price: item.price,
                time: item.time
            }
        })
        toggleModal()
    }, [toggleModal])

    const onKeyDown = useCallback(e => {
        e.preventDefault()
    }, [])

    const categoryOptions = categories ? Array.isArray(categories) && categories.map(category => ({
        ...category,
        label: category.name
    })) : []

    return (
        <React.Fragment>
            <DashboardHeader
                title="Все услуги"
                buttonText="Добавить услугу"
                onClickAdd={toggleModal}
                onSearch={handleSearch}
                selectOptions={salons}
                selectValue={params.salon}
                fieldName="salon"
                handleChange={onChangeSalon}
            >
            </DashboardHeader>
            <div className="dashboard-services-root">
                <Table className="dashboard-services-root__table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Название услуги
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Категория
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Длительность
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Цена
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Действия
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!requested && services && services.count > 0 && services.results &&
                        services.results.map(service => (
                            <ServiceElement
                                data={service}
                                key={service.id}
                                onRemove={() => onRemove(service.id)}
                                onEdit={() => onEdit(service)}
                            />
                        ))}
                    </TableBody>
                </Table>

                {requested && (
                    <CommonSpinner isLoading={requested} centered/>
                )}


                {!requested && debouncedSearchTerm && services &&
                services.results &&
                isEmptyArray(services.results) &&
                <div className="no-result">
                    <NoResultIcon width={100} height={140}/>
                    <p className="no-result__text">Результатов не найдено</p>
                </div>
                }

                {!requested && services && services.count === 0 && (
                    <div className={services.count === 0 ? "" : "dashboard-page-root"}>
                        <CreateBlock
                            title="У вас еще нет услуг"
                            subTitle="Добавьте услуги, которые вы предоставляете вашим клиентам. без этого вы не сможете
                    записывать клиентов."
                            onSubmit={toggleModal}
                            buttonText="Добавить услугу"
                        />
                    </div>
                )}

                {!requested && services && services.page_count > INITIAL_PAGE && (
                    <CommonPagination
                        count={services.page_count}
                        currentPage={params.page}
                        onChange={onChangePage}/>
                )}

            </div>
            {isOpenModal &&
            <DashboardModal
                isOpen={isOpenModal}
                onClose={toggleModal}
                title="Добавить услугу"
            >
                <Formik
                    initialValues={state}
                    validate={values => validateForm(values)}
                    onSubmit={(values) => {
                        onSubmit(values)
                    }}
                >
                    {
                        ({
                             values,
                             errors,
                             touched,
                             handleChange,
                             dirty,
                             handleSubmit,
                         }) => (
                            <form className="dashboard-form-services" onSubmit={handleSubmit}>
                                <CustomSelect
                                    handleChange={handleChange}
                                    options={categoryOptions}
                                    propertyName="category"
                                    className="dashboard-form-services__text-field"
                                    placeholder="Категория"
                                    value={values.category}
                                />
                                {errors.category && touched.category && errors.category &&
                                <span className="field-error">{errors.category}</span>
                                }
                                <InputForm
                                    onChange={handleChange}
                                    value={values.name}
                                    propertyName="name"
                                    label="Название услуги"
                                    className="dashboard-form-services__text-field"/>
                                {errors.name && touched.name && errors.name &&
                                <span className="field-error">{errors.name}</span>
                                }
                                <InputForm
                                    onChange={handleChange}
                                    value={values.price ? Math.abs(values.price) : values.price}
                                    propertyName="price"
                                    label="Цена"
                                    type="number"
                                    inputProps={{
                                        min: 0
                                    }}
                                    className="dashboard-form-services__text-field"/>
                                {errors.price && touched.price && errors.price &&
                                <span className="field-error">{errors.price}</span>
                                }
                                <InputForm
                                    onChange={handleChange}
                                    value={values.time ? Math.abs(values.time) : 0}
                                    propertyName="time"
                                    label="Длительность"
                                    type="number"
                                    inputProps={{
                                        step: 30,
                                        min: 30
                                    }}
                                    onKeyDown={onKeyDown}
                                    placeholder="В минутах"
                                    className="dashboard-form-services__text-field"/>
                                {errors.time && touched.time && errors.time &&
                                <span className="field-error">{errors.time}</span>
                                }
                                <GeneralButton
                                    type="submit"
                                    title={params.editingItem ? "Сохранить" : "Добавить"}
                                    disabled={requested}
                                    className="dashboard-form-services__button"
                                    bgColor={!requested ? Colors.SEMANTIC['green'] : Colors.NEUTRAL['border']}
                                    textColor={Colors.NEUTRAL['white']}/>
                            </form>
                        )
                    }
                </Formik>
            </DashboardModal>
            }
        </React.Fragment>
    );
}

export const mapStateToProps = state => ({
    services: state.services.services,
    requested: state.services.requested,
    salons: state.core.salons,
    salon: state.core.salon,
    categories: state.categories.categories
})

export const mapDispatchToProps = dispatch => ({
    fetchServicesGet: (page, search, salon) => dispatch(fetchServicesGet(page, search, salon)),
    fetchCategories: (params) => dispatch(fetchCategories(params)),
    fetchCreateService: data => dispatch(fetchCreateService(data)),
    fetchEditService: (serviceId, data) => dispatch(fetchEditService(serviceId, data)),
    fetchRemoveService: serviceId => dispatch(fetchRemoveService(serviceId)),
    changeSalonAction: salon => dispatch(changeSalonAction(salon))
})

export default connect(mapStateToProps, mapDispatchToProps)(Services);