import {FETCH_GET_SALONS_REQUEST, FETCH_GET_SALONS_SUCCESS,
    FETCH_PAGE_SALON_PAGINATION_REQUEST, FETCH_PAGE_SALON_PAGINATION_SUCCESS} from './listSalonsAction'

const initialState = {
    response: false,
    listSalon: null,

    paginationResponse: false,
    nextPage: null,
    previousPage: null,
}

export const salonsReducer = (state = initialState, action) => {
    switch (action.type){
        case FETCH_GET_SALONS_REQUEST:
            return {...state, response: true}
        case FETCH_GET_SALONS_SUCCESS:
            return {...state, response: false, listSalon: action.value.results,
                nextPage: action.value.next, previousPage: action.value.previous}
        case FETCH_PAGE_SALON_PAGINATION_REQUEST:
            return {...state, paginationResponse: true}
        case FETCH_PAGE_SALON_PAGINATION_SUCCESS:
            const copyMaster = [...state.listSalon].concat([...action.value.results])
            return {...state, paginationResponse: false, nextPage: action.value.next, listSalon: copyMaster}
        default: return state
    }
}