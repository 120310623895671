import React from "react";
import Colors from "../styles/Colors";
import HomeIcon from "../../components/kit/Icons/HomeIcon";
import CalendarIcon from "../../components/kit/Icons/CalendarIcon";
import BellIcon from "../../components/kit/Icons/BellIcon";
import UsersIcon from "../../components/kit/Icons/UsersIcon";
import DollarIcon from "../../components/kit/Icons/DollarIcon";
import Scissors2Icon from "../../components/kit/Icons/Scissors_2Icon";
import SettingsIcon from "../../components/kit/Icons/SettingsIcon";
import {
    ROUTE_CALENDAR,
    ROUTE_CATEGORIES,
    ROUTE_HOME,
    ROUTE_NOTIFICATIONS, ROUTE_FINANCE, ROUTE_SERVICES, ROUTE_SETTINGS,
    ROUTE_USERS
} from "../Constants/Constants";
import BarChartIcon from "../../components/kit/Icons/BarChartIcon";
import ServiceIcon from "../../components/kit/Icons/ServiceIcon";

export const SIDE_BAR_NAV_ICONS = [
    // {
    //     id: 1,
    //     icon: <HomeIcon width={24} height={24} color={Colors.NEUTRAL['white']}/>,
    //     link: ROUTE_HOME
    // },
    {
        id: 3,
        icon: <CalendarIcon width={24} height={24} color={Colors.NEUTRAL['white']}/>,
        link: ROUTE_CALENDAR,
        title: "Календарь"
    },
    {
        id: 4,
        icon: <BellIcon width={24} height={24} color={Colors.NEUTRAL['white']}/>,
        link: ROUTE_NOTIFICATIONS,
        title: "Записи"
    },
    {
        id: 5,
        icon: <UsersIcon width={24} height={24} color={Colors.NEUTRAL['white']}/>,
        link: ROUTE_USERS,
        title: "Сотрудники"
    },
    {
        id: 6,
        icon: <DollarIcon width={24} height={24} color={Colors.NEUTRAL['white']}/>,
        link: ROUTE_FINANCE,
        title: "Финансовый отчет"
    },
    {
        id: 2,
        icon: <BarChartIcon width={24} height={24} color={Colors.NEUTRAL['white']}/>,
        link: ROUTE_CATEGORIES,
        title: "Категории"
    },
    {
        id: 7,
        icon: <ServiceIcon width={55} height={55} color={Colors.NEUTRAL['white']}/>,
        link: ROUTE_SERVICES,
        title: "Услуги"
    },
    {
        id: 8,
        icon: <SettingsIcon width={24} height={24} color={Colors.NEUTRAL['white']}/>,
        link: ROUTE_SETTINGS,
        title: "Настройки"
    }
];
