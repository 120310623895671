import React, { useEffect } from "react";
import Routes from "./Routes";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import "./App.scss";
import { fetchSalonsGet } from "./store/core/coreActions";

const App = ({ user, fetchSalonsGet }) => {
  useEffect(
    function () {
      if (
        !window.location.pathname.includes("record") &&
        !window.location.pathname.includes("login")
      ) {
        const user = JSON.parse(localStorage.getItem("via_key"));
        user && !!user.user && getSalons();
      }
    },
    [getSalons]
  );

  async function getSalons() {
    await fetchSalonsGet();
  }

  return (
    <>
      <Routes user={user} />
      <NotificationContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export const mapDispatchToProps = (dispatch) => ({
  fetchSalonsGet: () => dispatch(fetchSalonsGet()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
