import React from 'react';
import Colors from "../../../../global/styles/Colors";
import SelectFormExpandPanel from "../../../../components/kit/SelectFormExpandPanel/SelectFormExpandPanel";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";

import '../index.scss';
import { getServices, groupBy } from "../../../../global/Helpers/helpers";
import CommonSpinner from "../../../../components/kit/CommonSpinner/CommonSpinner";
import LargeCalendarIcon from "../../../../components/kit/Icons/LargeCalendarIcon";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";

const ChooseService = (
    {
        onSelect,
        selectedOptions,
        handleClickNext,
        serviceOptions,
        requested,
        handleBackNav
    }
) => {

    const disabledClassName = !selectedOptions.length ? "service-variant-root__btn--disabled" : "service-variant-root__btn--active";

    const groupedServices = getServices(groupBy(serviceOptions, service => service.category))

    return (
        <div className="service-variant-root">
            <h3 className="service-variant-root__title" style={{color: Colors.NEUTRAL['black']}}>
                <span className="record-nav-back-icon">
                   <ArrowLargeIcon onClick={handleBackNav}/>
               </span>
                <span>Выбрать услугу</span>
                <span className="empty"/>
            </h3>

            {requested && <CommonSpinner isLoading={requested} centered/>}

            {!requested && !groupedServices.length && (
            <div className="select-date-root__not-found">
                <LargeCalendarIcon/>
                <h3>Нет доступных услуг</h3>
            </div>
            )}

            {!requested && groupedServices.map((category, ndx) => (
                <SelectFormExpandPanel
                    key={ndx}
                    onSelect={onSelect}
                    options={category}
                    title={category[0].category_name}
                    selectedOptions={selectedOptions}/>
            ))}

            <GeneralButton
                bgColor={!selectedOptions.length ? Colors.NEUTRAL['border'] : Colors.SEMANTIC['pink']}
                className={["service-variant-root__btn", [disabledClassName]].join(' ')}
                title="Далее"
                disabled={!selectedOptions.length}
                textColor={!selectedOptions.length ? Colors.TEXT['text_color'] : Colors.NEUTRAL['white']}
                onClick={handleClickNext}/>
        </div>
    );
};

export default ChooseService;