import {FETCH_STAFFS_REQUEST, FETCH_STAFFS_SUCCESS} from "./staffActions";

const initialState = {
    requested: false,
    staffsList: null
};

const staffsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STAFFS_REQUEST:
            return {...state, requested: true}
        case FETCH_STAFFS_SUCCESS:
            return {...state, staffsList: action.staffs, requested: false}
        default:
            return state;
    }
};

export default staffsReducer;
