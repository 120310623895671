import React, {useEffect, useState} from 'react';
import {Formik} from "formik";
import InputForm from "../../../components/kit/InputForm/InputForm";
import InputPhoneMask from "../../../components/kit/InputPhoneMask/InputPhoneMask";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";
import Colors from "../../../global/styles/Colors";
import DashboardModal from "../../../components/DashboardModal/DashboardModal";
import SelectFormExpandPanel from "../../../components/kit/SelectFormExpandPanel/SelectFormExpandPanel";
import {Scrollbars} from "react-custom-scrollbars";
import CloseIcon from "../../../components/kit/Icons/CloseIcon";
import {fetchCreateApplication, fetchGetAvailableCategories} from "../../../store/calendar/calendarActions";
import {connect} from "react-redux";
import {dateFormatter} from "../../../global/Helpers/helpers";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import {APPLICATION_ACCEPTED} from "../../../global/Constants/Constants";
import NoResultIcon from "../../../components/kit/Icons/NoResultIcon";

const INITIAL_STEP = 1;
const FINISH_STEP = 2;

let TOTAL_TIME = 0;

const CalendarModal = (
    {
        isOpen,
        onClose,
        initValues,
        fetchGetAvailableCategories,
        availableCategories,
        fetchCreateApplication,
        getEvents
    }
) => {
    const [state, setValue] = useState(
        {
            name: '',
            surname: '',
            appointed_time__gte: initValues.time || '',
            number: ''
        }
    );

    const [step, setStep] = useState(INITIAL_STEP);

    const [services, setService] = useState([]);

    useEffect(function () {
        TOTAL_TIME = availableCategories && availableCategories.free_time;
    }, [availableCategories])

    function addService(item) {
        const copy = [...services];

        if ((TOTAL_TIME - item.time) >= 0) {
            copy.push(item)
            setService(copy)
            TOTAL_TIME -= item.time
        }


    }

    function removeService(item) {
        const copy = [...services];
        const index = copy.findIndex(it => it.pk === item.pk);
        copy.splice(index, 1)
        setService(copy)
        TOTAL_TIME += item.time
    }


    function validateForm(values) {
        const error = {};
        if (values.name === '') {
            error.name = "Обязательное поле"
        }
        if (values.appointed_time === '') {
            error.appointed_time = "Обязательное поле"
        }
        if (!values.number) {
            error.number = "Обязательное поле"
        } else if (values.number.length < 17) {
            error.number = "Некорректный номер"
        }
        return error
    }

    async function onSubmit(values) {
        const data = {...values}
        data.employee = initValues.employee
        data.salon = initValues.salon
        data.appointed_date = dateFormatter(initValues.appointed_date)
        await fetchGetAvailableCategories(data)
        setStep(FINISH_STEP)
        setValue(values)
    }

    async function createApplication() {
        const data = {...state};
        data.employee = initValues.employee
        data.salon = initValues.salon
        data.appointed_time = state.appointed_time__gte
        data.appointed_date = dateFormatter(initValues.appointed_date)
        data.is_confirmed = APPLICATION_ACCEPTED
        const serviceIds = [...services].map(service => service.pk)
        data.service = serviceIds;
        await fetchCreateApplication(data)
        await getEvents()
        await onClose()
    }


    return (
        <DashboardModal
            isOpen={isOpen}
            onClose={onClose}
            title=""
            className="calendar__modal-root"
            classNamePaper="calendar__modal-paper"
        >
            {step === INITIAL_STEP ?
                <div className="calendar__modal-info">
                    <Formik
                        initialValues={state}
                        validate={values => validateForm(values)}
                        onSubmit={(values) => {
                            onSubmit(values)
                        }}
                    >
                        {
                            ({
                                 values,
                                 errors,
                                 touched,
                                 handleChange,
                                 dirty,
                                 handleSubmit,
                             }) => (
                                <form className="calendar__modal-form" onSubmit={handleSubmit}>
                                    <h1>Записать клиента</h1>

                                    <InputPhoneMask
                                        className="calendar__modal-form__text-field-phone"
                                        value={values.number}
                                        propertyName="number"
                                        onChange={handleChange}
                                    />
                                    {errors.number && touched.number && errors.number &&
                                    <span className="field-error">{errors.number}</span>
                                    }
                                    <InputForm
                                        onChange={handleChange}
                                        value={values.name}
                                        propertyName="name"
                                        label="Имя"
                                        type="text"
                                        className="calendar__modal-form__text-field"/>

                                    {
                                        errors.name && touched.name &&
                                        (
                                            <span className="field-error field-error-by-staff">
                                         {errors.name}
                                        </span>
                                        )
                                    }

                                    <InputForm
                                        onChange={handleChange}
                                        value={values.surname}
                                        propertyName="surname"
                                        label="Фамилия"
                                        type="text"
                                        className="calendar__modal-form__text-field"/>

                                    <InputForm
                                        onChange={handleChange}
                                        value={values.appointed_time__gte}
                                        propertyName="appointed_time__gte"
                                        label="Время"
                                        type="time"
                                        disabled
                                        className="calendar__modal-form__text-field"/>

                                    {
                                        errors.appointed_time && touched.appointed_time &&
                                        (
                                            <span className="field-error field-error-by-staff">
                                         {errors.appointed_time}
                                        </span>
                                        )
                                    }

                                    <GeneralButton
                                        type="submit"
                                        title="Далее"
                                        disabled={!dirty}
                                        className="calendar__modal-form__button"
                                        bgColor={dirty ? Colors.SEMANTIC['green'] : Colors.NEUTRAL['border']}
                                        textColor={Colors.NEUTRAL['white']}/>
                                </form>
                            )
                        }
                    </Formik>
                    <div className="calendar__modal-promo">
                        <div className="step-info">
                            <span>Шаг 1/2</span>
                        </div>
                        <h3>Кого записываем?</h3>
                        <h4>
                            Для записи клиента укажите его имя и номер телефона. фамилию указывать не обязательно, но мы
                            рекомендуем.
                        </h4>
                    </div>
                </div>
                :
                <div className="calendar__modal-services">
                    {!availableCategories && <CommonSpinner isLoading={!availableCategories} centered/>}
                    {availableCategories && (
                        <>
                            <div className="calendar__modal-service-promo">
                                <div className="step-info">
                                    <span>Шаг 2/2</span>
                                </div>
                                <h3>На какие услуги?</h3>
                                <h4>
                                    Выберите одну или больше услуг для записи клиента. это тестовый список услуг, вы
                                    сможете
                                    заменить из позже.
                                </h4>
                                {/*<p className="available-time">*/}
                                {/*    Доступное время для записи:*/}
                                {/*    &nbsp;*/}
                                {/*    {*/}
                                {/*        TOTAL_TIME*/}
                                {/*    }*/}
                                {/*    &nbsp;мин*/}
                                {/*</p>*/}
                            </div>
                            <div className="calendar__modal-service-select">
                                <div className="calendar__modal-categories">
                                    <div className="calendar__modal-categories-head">
                                        <div className="calendar__modal-service__head-title">
                                            <h6>Категории услуг</h6>
                                        </div>
                                    </div>

                                    <Scrollbars style={{width: 240, height: 450}}>
                                        {availableCategories.result && !!availableCategories.result.length &&
                                        availableCategories.result.map((item, ndx) => (
                                            <SelectFormExpandPanel
                                                key={ndx}
                                                onSelectSimpleOption={addService}
                                                options={item.services ? item.services.map(it => ({
                                                    ...it,
                                                    label: it.name,
                                                    value: it.id
                                                })) : []}
                                                optionVariant="simple"
                                                title={item.category__name}
                                                selectedOptions={[]}
                                                selectedSimpleOptions={services}
                                            />
                                        ))}

                                        {!!availableCategories.result && !availableCategories.result.length && (
                                            <div className="no-categories-root">
                                                <p>Услуги не найдены</p>
                                                <NoResultIcon height={60}/>
                                            </div>
                                        )}

                                    </Scrollbars>
                                </div>
                                <div className="calendar__modal-service-items">
                                    <div className="calendar__modal-service-items-head">
                                        <div className="calendar__modal-service__head-title">
                                            <h6>Услуга</h6>
                                        </div>
                                        <div className="calendar__modal-service__head-title">
                                            <h6>Время (мин)</h6>
                                        </div>
                                        <div className="calendar__modal-service__head-title">
                                            <h6>Цена (сом)</h6>
                                        </div>
                                    </div>
                                    <Scrollbars style={{width: "100%", height: 268}}>
                                        {services.map(service => (
                                            <div className="calendar__modal-service-item" key={service.pk}>
                                                <p className="service">
                                    <span className="icon" onClick={() => removeService(service)}>
                                        <CloseIcon width={14} height={14} color="#D0C9D6"/>
                                    </span>
                                                    <span>{service.name}</span>
                                                </p>
                                                <div>
                                                    <p className="box">
                                                        {service.time}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="box">
                                                        {service.price}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </Scrollbars>

                                    <div className={TOTAL_TIME > 0 ? "calendar__modal-service-total" : "calendar__modal-service-total --red"}>
                                        <h6>Доступно {TOTAL_TIME} минут для записи</h6>
                                        <div>
                                            <span>Итого</span>
                                            <span>
                                                {services.reduce((acc, curr) => acc + curr.time, 0)}
                                                &nbsp;мин</span>
                                            <span>
                                                {services.reduce((acc, curr) => acc + curr.price, 0)}
                                                &nbsp;сом</span>
                                        </div>
                                        <GeneralButton
                                            type="button"
                                            title={"записать"}
                                            disabled={!services.length}
                                            onClick={createApplication}
                                            className="dashboard-form-staffs__button"
                                            bgColor={services.length ? Colors.SEMANTIC['green'] : Colors.NEUTRAL['border']}
                                            textColor={Colors.NEUTRAL['white']}/>
                                    </div>
                                </div>

                            </div>
                        </>
                    )}
                </div>
            }
        </DashboardModal>
    );
};

const mapStateToProps = state => ({
    availableCategories: state.calendar.availableCategories,
})

const mapDispatchToProps = dispatch => ({
    fetchGetAvailableCategories: data => dispatch(fetchGetAvailableCategories(data)),
    fetchCreateApplication: data => dispatch(fetchCreateApplication(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarModal);