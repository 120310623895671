import {
    CLEAR_APPLICATION,
    FETCH_APPLICATION_INFO,
    FETCH_APPLICATIONS_GET,
    FETCH_APPLICATIONS_REQUEST, FETCH_CLIENT_RECORDS, FETCH_CREATE_APPLICATIONS_CLOSE,
    FETCH_CREATE_APPLICATIONS_SUCCESS,
    FETCH_CHECK_NUMBER_REQUEST,  FETCH_CHECK_NUMBER_SUCCESS
} from "./applicationsAction";

const initialState = {
    requested: false,
    applicationsList: null,
    application: null,
    isCreated: false,

    clientRecords: null,
    otp: false,
};

const applicationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_APPLICATIONS_REQUEST:
            return {...state, requested: true}
        case FETCH_APPLICATIONS_GET:
            return {...state, applicationsList: action.applications, requested: false}
        case FETCH_CREATE_APPLICATIONS_SUCCESS:
            return {...state, isCreated: true}
        case FETCH_CREATE_APPLICATIONS_CLOSE:
            return {...state, isCreated: false}
        case FETCH_APPLICATION_INFO:
            return {...state, application: action.info}
        case CLEAR_APPLICATION:
            return {...state, application: null}
        case FETCH_CLIENT_RECORDS:
            return {...state, clientRecords: action.records}
        case FETCH_CHECK_NUMBER_SUCCESS:
            return {...state, otp: true}
        default:
            return state;
    }
};

export default applicationsReducer;
