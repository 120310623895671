import {
    //Search master
    FETCH_GET_MASTERS_SEARCH_REQUEST,
    FETCH_GET_MASTERS_SEARCH_ERROR,
    FETCH_GET_MASTERS_SEARCH_SUCCESS,

    //Search salon
    FETCH_GET_SALONS_SEARCH_REQUEST,
    FETCH_GET_SALONS_SEARCH_ERROR,
    FETCH_GET_SALONS_SEARCH_SUCCESS,

    //Search services
    FETCH_GET_SERVICES_SEARCH_REQUEST,
    FETCH_GET_SERVICES_SEARCH_ERROR,
    FETCH_GET_SERVICES_SEARCH_SUCCESS,

    //Hide Show Page
    SHOW_PAGE_SEARCH,
    HIDE_PAGE_SEARCH
} from './searchAction'


const initialState = {
    request: false,
    paginationResponse: false,
    error: false,
    showSearchPage: false,
    masterSearch: {},
    salonSearch: {},
    servicesSearch: {},
}

export const searchReducer = (state = initialState, action) => {
    switch (action.type){
        case FETCH_GET_MASTERS_SEARCH_REQUEST:
            return {...state, request: true, showSearchPage: true}
        case FETCH_GET_MASTERS_SEARCH_SUCCESS:
            return {...state, masterSearch: action.value ,request: false}
        case FETCH_GET_MASTERS_SEARCH_ERROR:
            return {...state, error: true, request: false}

        case FETCH_GET_SALONS_SEARCH_REQUEST:
            return {...state, request: true, showSearchPage: true}
        case FETCH_GET_SALONS_SEARCH_SUCCESS:
            return {...state, salonSearch: action.value, request: false}
        case FETCH_GET_SALONS_SEARCH_ERROR:
            return {...state, error: true, request: false}

        case FETCH_GET_SERVICES_SEARCH_REQUEST:
            return {...state, request: true}
        case FETCH_GET_SERVICES_SEARCH_SUCCESS:
            return {...state, servicesSearch: action.value, request: false}
        case FETCH_GET_SERVICES_SEARCH_ERROR:
            return {...state, error: true, request: false}

        case SHOW_PAGE_SEARCH:
            return {...state, showSearchPage: true}
        case HIDE_PAGE_SEARCH:
            return {...state, showSearchPage: false}
        default: return state
    }
}