import React from 'react';

const SimpleOption = ({item, onSelect, disabled}) => {
    return (
        <button className={`select-expand-panel_root__simple-option`}
                disabled={disabled}
                onClick={() => onSelect(item)}>
            <p className={`${disabled && '--disabled'}`}>
                {item.label || ""}
                <span className="select-expand-panel_root__simple-option-time">
                    {item.time}мин
                </span>
            </p>
        </button>
    );
};

export default SimpleOption;