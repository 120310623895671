import React, {useState} from 'react';
import Colors from "../../global/styles/Colors";
import SearchField from "../kit/SearchField/SearchField";
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import './index.scss';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import ArrowIcon from "../kit/Icons/ArrowIcon";
import {convertDateWithMonthName} from "../../global/Helpers/convertDateWithMonthName";
import DatePicker from "react-datepicker";
import CalendarIcon from "../kit/Icons/CalendarIcon";
import LogoutIcon from "../kit/Icons/LogoutIcon";
import {fetchLogout} from "../../store/user/userAction";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        backgroundColor: Colors.SEMANTIC['blue'],
        color: Colors.NEUTRAL['white'],
        borderRadius: '6px',
        padding: '6px 25px',
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'inherit'
    },
    btns: {
        marginLeft: '32px',
        backgroundColor: Colors.NEUTRAL['white']
    },
    btn: {
        borderColor: Colors.SEMANTIC['blue'],
        color: Colors.SEMANTIC['blue'],
    },
    active: {
        backgroundColor: Colors.NEUTRAL['blue'],
    },
    select: {
        '& .MuiTextField-root': {
            width: 200,
            height: 34,
            margin: 0,
            background: "#F7F5F9",
            border: "1px solid #ECEBED",
            boxSizing: "border-box",
            borderRadius: "3px",
            outline: "none",
        },
    }
}));

const CustomInput = ({value, onClick}) => (
    <button className="calendar__datepicker-icon" onClick={onClick}>
        <CalendarIcon width={16} height={16} color="#0084F4"/>
    </button>
);


const DashboardHeader = (
    {
        title,
        children,
        buttonText,
        onClickAdd,
        tabOptions,
        isActiveCalendar,

        selectOptions,
        selectValue,
        handleChange,
        fieldName,

        onChangeDate,

        activeTab,
        activateTab,

        searchValue,
        onSearch,

        fetchLogout,
        hasLogout = true,
        className
    }) => {
    const classes = useStyles();

    const [startDate, setStartDate] = useState(new Date());

    function handNextDay() {
        const date = new Date(startDate);
        const nextDay = date.setDate(date.getDate() + 1)
        setStartDate(new Date(nextDay))
        onChangeDate(new Date(nextDay))
    }

    function handPrevDay() {
        const date = new Date(startDate);
        const prevDay = date.setDate(date.getDate() - 1)
        setStartDate(new Date(prevDay))
        onChangeDate(new Date(prevDay))
    }

    function handleDateChange(date) {
        setStartDate(date)
        onChangeDate(date)
    }

    return (
        <div className={['dashboard-header', className].join(" ")}>
            <div className={title ? "dashboard-header__header" : ""} style={{
                backgroundColor: Colors.NEUTRAL['white'],
                borderBottom: `1px solid ${Colors.NEUTRAL['border']}`
            }}>
                {title && (
                    <h2 className="dashboard-header__title" style={{color: Colors.NEUTRAL['black']}}>
                        {title}
                    </h2>
                )}

                {selectOptions &&
                <div className={["dashboard-header__select", classes.select].join(" ")}>
                    <TextField
                        select
                        value={selectValue}
                        onChange={handleChange}
                        name={fieldName}
                    >
                        {selectOptions && selectOptions.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                }
                {isActiveCalendar &&
                <div className="dashboard-header__datepicker">
                    <button className="calendar__datepicker-arrow-btn" onClick={handPrevDay}>
                        <ArrowIcon width={16} height={16} color="#0084F4"/>
                    </button>
                    <span className="calendar__datepicker-date">
                        {convertDateWithMonthName(startDate)}
                    </span>
                    <button className="calendar__datepicker-arrow-btn"
                            onClick={handNextDay}>
                        <ArrowIcon width={16} height={16} color="#0084F4"/>
                    </button>
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        onChange={handleDateChange}
                        className="admin-date-picker-root__date-field"
                        locale="ru"
                        customInput={<CustomInput/>}
                    />
                </div>
                }

                {hasLogout && (
                <LogoutIcon className="dashboard-header__logout" onClick={fetchLogout}/>
                )}
            </div>
            <div className="dashboard-header__search">
                <div className="dashboard-header__search-elems">

                    {onSearch && (
                        <SearchField handleChange={onSearch} value={searchValue}/>
                    )}

                    {tabOptions &&
                    <ButtonGroup className={classes.btns} aria-label="button group">
                        {tabOptions.map((item, ndx) => (
                            <Button
                                key={ndx}
                                onClick={() => activateTab(item)}
                                className={[classes.btn, `${activeTab === item.status && "dashboard-header__btn"}`].join(" ")}>
                                {item.name}
                            </Button>
                        ))}
                    </ButtonGroup>
                    }
                </div>

                {buttonText && (
                    <Button variant="contained" color="primary" className={classes.root} onClick={onClickAdd}>
                        {buttonText}
                    </Button>
                )}

            </div>
            {children}
        </div>
    );
};

const mapDispatchToProps = dispatch => ({
    fetchLogout: () => dispatch(fetchLogout())
})

export default connect(null, mapDispatchToProps)(DashboardHeader);