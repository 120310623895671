export function useTelegram() {
  const tg = window.Telegram.WebApp;
  const isTgWebApp = tg.platform !== "unknown";

  const onClose = () => {
    tg.close();
  };

  return {
    onClose,
    tg,
    isTgWebApp,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
  };
}
