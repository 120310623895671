import React from 'react';

import './index.scss'

const Services = ({services}) => {

    return (
        <div className="list-services-salon">

            {services.map((item, index) => {
                return(
                    <div className="items-services information-salon" key={index}>
                        <div className="title-services">
                            <p>{item.name}</p>
                        </div>

                        <div className="description-services">

                            <div className="text-services">
                                <span className="time">{item.time}</span>
                                <span className="cost">{item.price} сом</span>
                            </div>

                            {/*<a href="#">*/}
                            {/*    Записаться*/}
                            {/*</a>*/}

                        </div>

                    </div>
                )
            })}

        </div>

    );
};

export default Services;