import React, {useEffect, useState} from 'react';
import SideBar from "./SideBar/SideBar";
import Categories from "../Pages/Categories/Categories";
import {history} from "../../store/configureStore";
import {
    ROUTE_CALENDAR,
    ROUTE_CATEGORIES,
    ROUTE_FINANCE, ROUTE_HOME,
    ROUTE_NOTIFICATIONS,
    ROUTE_SERVICES,
    ROUTE_SETTINGS, ROUTE_SETTINGS_ADD,
    ROUTE_USERS
} from "../../global/Constants/Constants";

import './index.scss';
import Services from "../Pages/Services/Services";
import Staffs from "../Pages/Staffs/Staffs";
import Applications from "../Pages/Applications/Applications";
import Finance from "../Pages/Finance/Finance";
import Settings from "../Pages/Settings/Settings";
import EventCalendar from "../Pages/Calendar/Calendar";
import {fetchSalonsGet} from "../../store/core/coreActions";
import {connect} from "react-redux";
import {useLocation} from 'react-router-dom';

const DashBoard = ({fetchSalonsGet, salons, salon, ...props}) => {

    const location = useLocation();
    const [currentPath, setPath] = useState(location.pathname)

    useEffect(() => {
        setPath(location.pathname)
        if (history.location.pathname === ROUTE_HOME) history.push(ROUTE_NOTIFICATIONS) // TODO need remove after complete home page
    }, [location]);

    return (
        <div className="dashboard-root">
            <SideBar activeMenu={history.location.pathname} salons={salons} salon={salon}/>
            <div className="dashboard-root__info">
                {history.location.pathname === ROUTE_CATEGORIES && <Categories/>}
                {history.location.pathname === ROUTE_SERVICES && <Services/>}
                {history.location.pathname === ROUTE_USERS && <Staffs/>}
                {history.location.pathname === ROUTE_NOTIFICATIONS && <Applications/>}
                {history.location.pathname === ROUTE_FINANCE && <Finance/>}
                {history.location.pathname.includes(ROUTE_SETTINGS) && <Settings {...props}/>}
                {history.location.pathname === ROUTE_SETTINGS_ADD && <Settings {...props}/>}
                {history.location.pathname.includes(ROUTE_CALENDAR) && <EventCalendar {...props}/>}
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    salons: state.core.salons,
    salon: state.core.salon,
})

export const mapDispatchToProps = dispatch => ({
    fetchSalonsGet: () => dispatch(fetchSalonsGet()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);