import axios from "../Api/axios-api";
import {
    ROUTE_AVAILABLE_TARIFFS,
    ROUTE_CATEGORIES, ROUTE_CHANGE_TARIFF, ROUTE_CURRENT_TARRIF, ROUTE_GET_BALANCE, ROUTE_MASTER_AVAILABLE_TIMES, ROUTE_PAY_METHOD,
    ROUTE_SALONS,
    ROUTE_SALONS_ROOT,
    ROUTE_SERVICES, ROUTE_STAFFS,
} from "../Api/Routes";
import { ERROR_TEXT, ROUTE_SETTINGS } from "../../global/Constants/Constants";
import { NotificationManager } from "react-notifications";
import { history } from "../configureStore";

export const FETCH_SALONS_GET = 'FETCH_SALONS_GET';
const fetchSalonsGetSuccess = salons => ({ type: FETCH_SALONS_GET, salons });
export const fetchSalonsGet = () => {
    return dispatch => {
        return axios.get(ROUTE_SALONS).then(
            response => {
                dispatch(fetchSalonsGetSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_CURRENT_TARRIF = 'FETCH_CURRENT_TARRIF';
const fetchCurrentTariff = currentTarif => ({ type: FETCH_CURRENT_TARRIF, currentTarif });
export const fetchCurrnetTarifGet = (salonId) => {
    return dispatch => {
        const params = {
            salon: salonId,

        };
        return axios.get(ROUTE_CURRENT_TARRIF, { params }).then(
            response => {
                dispatch(fetchCurrentTariff(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_AVIABLE_TARRIFS = 'FETCH_AVIABLE_TARRIFS';
const fetchAviableTarifs = aviable_tarifs => ({ type: FETCH_AVIABLE_TARRIFS, aviable_tarifs });
export const fetchAviableTarifsGet = (salonId) => {

    return dispatch => {
        return axios.get(ROUTE_AVAILABLE_TARIFFS, {
            params: {
                salon: salonId,
            },
        }).then(
            response => {
                console.log(response, "response")
                dispatch(fetchAviableTarifs(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}


export const FETCH_CATEGORIES_GET = 'FETCH_CATEGORIES_GET';
const fetchCategoriesSuccess = categories => ({ type: FETCH_CATEGORIES_GET, categories });
export const fetchCategoriesGet = (salonId) => {
    const url = salonId ? `?salon=${salonId}` : ""
    return dispatch => {
        return axios.get(ROUTE_CATEGORIES + url).then(
            response => {
                dispatch(fetchCategoriesSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_DATE_GET = 'FETCH_DATE_GET';
const fetchDateSuccess = dates => ({ type: FETCH_DATE_GET, dates });
export const fetchDateGet = (employmentId, salonId) => {
    const url = employmentId ? `salons/${salonId}/employees/${employmentId}/time/` : ""

    if (employmentId) {
        return dispatch => {
            return axios.get(url).then(
                response => {
                    dispatch(fetchDateSuccess(response.data))
                },
                () => {
                    NotificationManager.error(ERROR_TEXT)
                }
            ).catch(err => console.log(err))
        }
    }
}

export const FETCH_CHANGE_DATE = 'FETCH_CHANGE_DATE';
const fetchChangeDateSuccess = dates => ({ type: FETCH_CHANGE_DATE, dates });
export const fetchChangeDate = (employmentId, salonId, data) => {
    const url = employmentId ? `salons/${salonId}/employees/${employmentId}/time/` : ""


    if (url.length) {
        return dispatch => {
            return axios.put(url, data)
                .then(response => {
                    fetchChangeDateSuccess(response.data)
                    NotificationManager.success("Успешное изменение расписание сотрудника")
                },
                    () => {
                        NotificationManager.error(ERROR_TEXT)
                    }
                ).catch(err => console.log(err))
        }
    }
}

export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_GET = 'FETCH_SERVICES_GET';
const fetchServicesRequest = () => ({ type: FETCH_SERVICES_REQUEST });
const fetchServicesSuccess = services => ({ type: FETCH_SERVICES_GET, services });
export const fetchServicesGet = (category, salonId, masterId) => {
    let url = "?";
    if (category) {
        url += `category=${category}&`
    }
    if (salonId) {
        url += `salon=${salonId}&`
    }
    if (masterId) {
        url += `employee=${masterId}&`
    }

    return dispatch => {
        dispatch(fetchServicesRequest())
        return axios.get(ROUTE_SERVICES + url).then(
            response => {
                dispatch(fetchServicesSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}


export const FETCH_SALON_REQUEST = 'FETCH_SALON_REQUEST';
export const FETCH_SALON_SUCCESS = 'FETCH_SALON_SUCCESS';
export const FETCH_SALON_FAILURE = 'FETCH_SALON_FAILURE';
const fetchSalonRequest = () => ({ type: FETCH_SALON_REQUEST })
const fetchSalonGetSuccess = salon => ({ type: FETCH_SALON_SUCCESS, salon });
const fetchSalonFailure = () => ({ type: FETCH_SALON_FAILURE })
export const fetchSalonGet = (salonId) => {
    return dispatch => {
        dispatch(fetchSalonRequest())
        return axios.get(ROUTE_SALONS_ROOT + salonId + '/').then(
            response => {
                dispatch(fetchSalonGetSuccess(response.data))
            },
            () => {
                dispatch(fetchSalonFailure())
                history.push('/')
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchEditSalonInfo = (salonId, data) => {
    return dispatch => {
        dispatch(fetchSalonRequest())
        return axios.put(ROUTE_SALONS_ROOT + salonId + "/", data).then(
            () => {
                dispatch(fetchSalonGet(salonId))
                dispatch(fetchSalonsGet())
                NotificationManager.info("Изменения успешно сохранены")
            },
            () => {
                dispatch(fetchSalonFailure())
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_CLEAR_SALON = 'FETCH_CLEAR_SALON';
export const fetchClearSalon = () => ({ type: FETCH_CLEAR_SALON })
export const fetchCreateSalon = (data) => {
    return dispatch => {
        return axios.post(ROUTE_SALONS_ROOT, data).then(
            (response) => {
                dispatch(fetchSalonsGet())
                history.push(ROUTE_SETTINGS + response.data.id)
            },
            (error) => {
                dispatch(fetchSalonFailure())
                //TODO change here
                NotificationManager.error((error.response && JSON.stringify(error.response.data)) && ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchRemoveSalon = salonId => {
    return (dispatch) => {
        return axios.delete(ROUTE_SALONS_ROOT + salonId + "/").then(
            () => {
                NotificationManager.info("Успешно удаление")
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_SALON_TIME_SUCCESS = 'FETCH_SALON_TIME_SUCCESS';
const fetchSalonTimesSuccess = times => ({ type: FETCH_SALON_TIME_SUCCESS, times });
export const fetchSalonTimes = salonId => {
    return dispatch => {
        const header = {

        }
        return axios.get(ROUTE_SALONS_ROOT + salonId + '/time/').then(
            response => {
                dispatch(fetchSalonTimesSuccess(response.data))
            },
            (error) => {
                //TODO change here
                NotificationManager.error(error.response && error.response.data && ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchSaveSalonTimes = (salonId, data) => {
    return dispatch => {
        return axios.put(ROUTE_SALONS_ROOT + salonId + '/time/', data).then(
            () => {
                dispatch(fetchSalonTimes(salonId))
                NotificationManager.info("Изменения успешно сохранены")
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_MASTER_AVAILABLE_REQUEST = 'FETCH_MASTER_AVAILABLE_REQUEST';
export const FETCH_MASTER_AVAILABLE_TIMES = 'FETCH_MASTER_AVAILABLE_TIMES';
const fetchMasterAvailableTimesRequest = () => ({ type: FETCH_MASTER_AVAILABLE_REQUEST })
const fetchMasterAvailableTimesSuccess = times => ({ type: FETCH_MASTER_AVAILABLE_TIMES, times })
export const fetchMasterAvailableTimes = (salonId, date, masterId, services) => {
    const url = `/${masterId}/?date=${date}&service=${services.join(",")}`
    return dispatch => {
        dispatch(fetchMasterAvailableTimesRequest())
        return axios.get(ROUTE_MASTER_AVAILABLE_TIMES + salonId + url).then(
            response => {
                dispatch(fetchMasterAvailableTimesSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const CHANGE_SALON_ACTION = 'CHANGE_SALON_ACTION';
export const changeSalonAction = salon => ({ type: CHANGE_SALON_ACTION, salon })

export const FETCH_GET_BALANCE_REQUEST = 'FETCH_GET_BALANCE_REQUEST'
export const FETCH_GET_BALANCE_SUCCESS = 'FETCH_GET_BALANCE_SUCCESS'
export const FETCH_GET_BALANCE_FAIL = 'FETCH_GET_BALANCE_FAIL'

const fetchGetBalanceRequest = () => ({ type: FETCH_GET_BALANCE_REQUEST })
const fetchGetBalanceSuccess = balance => ({ type: FETCH_GET_BALANCE_SUCCESS, balance })
const fetchGetBalanceFail = () => ({ type: FETCH_GET_BALANCE_FAIL })

export const fetchGetBalance = id => {
    return dispatch => {
        dispatch(fetchGetBalanceRequest())
        return axios.get(`${ROUTE_GET_BALANCE}?salon=${id}`)
            .then(response => {
                dispatch(fetchGetBalanceSuccess(response.data))
            })
            .catch(() => {
                dispatch(fetchGetBalanceFail())
            })
    }
}

export const fetchChangeTarif = (data) => {
    return dispatch => {
        return axios.post(ROUTE_CHANGE_TARIFF, data).then(
            (res) => {
                NotificationManager.info("Запрос успешно прошел",)
                return res
            },
            () => {

                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

// export const FETCH_GET_ALL_REQUEST = 'FETCH_GET_ALL_SALONS'
// export const FETCH_GET_ALL_SUCCESS = 'FETCH_GET_ALL_SUCCESS'
// export const FETCH_GET_ALL_FAILURE = 'FETCH_GET_ALL_FAILURE'
// const fetchGetAllSalonsRequest = () => ({type: FETCH_GET_ALL_REQUEST})
// const fetchGetAllSalonsSuccess = salons => ({type: FETCH_GET_ALL_SUCCESS, salons})
// const fetchGetAllSalonsFailure = () => ({type: FETCH_GET_ALL_FAILURE})
// export const fetchGetAllSalons = () => {
//     return dispatch => {
//         dispatch(fetchGetAllSalonsRequest())
//             return axios.get(ROUTE_GET_ALL_SALONS)
//                     .then(response => {
//                         dispatch(fetchGetAllSalonsSuccess(response.data))
//                     }, () => {
//                         dispatch(fetchGetAllSalonsFailure())
//                         NotificationManager.error(ERROR_TEXT)
//                     }).catch(err => console.log(err))
//     }
// }


export const BTN_ACTIVE_SALON = 'BTN_ACTIVE_SALON'
export const BTN_ACTIVE_MASTER = 'BTN_ACTIVE_MASTER'

export const setBtnActiveSalon = () => ({ type: BTN_ACTIVE_SALON })
export const setBtnActiveMaster = () => ({ type: BTN_ACTIVE_MASTER })

export const SHOW_FILTER = 'SHOW_FILTER'
export const HIDE_FILTER = 'HIDE_FILTER'

export const showFilter = () => ({ type: SHOW_FILTER })
export const hideFilter = () => ({ type: HIDE_FILTER })