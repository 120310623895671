import axios from "../../Api/axios-api";
import {ROUTE_FILTER_SALON} from '../../Api/Routes'

export const FETCH_GET_FILTER_SALON_REQUEST = 'FETCH_GET_FILTER_SALON'
export const FETCH_GET_FILTER_SALON_SUCCESS = 'FETCH_GET_FILTER_SALON_SUCCESS'

const fetchGetFilterSalonRequest = () => ({type: FETCH_GET_FILTER_SALON_REQUEST})
const fetchGetFilterSalonSuccess = data => ({type: FETCH_GET_FILTER_SALON_SUCCESS, value: data})

export const getFilterSalon = (idServices) => {
    return dispatch => {
        dispatch(fetchGetFilterSalonRequest())
        return axios.get(ROUTE_FILTER_SALON + idServices)
            .then(response => {
                dispatch(fetchGetFilterSalonSuccess(response.data))
            })
    }
}
