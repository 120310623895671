import React, {useState, useEffect} from 'react';
import logo from '../../../../assets/logo.png';
import Colors from "../../../../global/styles/Colors";
import HeartPinkIcon from "../../../../components/kit/Icons/HeartPinkIcon";
import HeartPink3Icon from "../../../../components/kit/Icons/HeartPink_3Icon";

const hearts = [1, 2, 3, 4, 5];

const LoadingComponent = () => {

    let [value, setValue] = useState(1);

    useEffect(() => {
        const interval = setInterval(() => {
            const newValue = value === 5 ? value = 0 : value += 1;
            setValue(newValue)
        }, 500);
        return () => clearInterval(interval)
    }, []);

    return (
        <div className="loading-page">
                <img className="loading-page__logo" src={logo} alt="logo"/>
                <div className="loading-page__spinner">
                    <p className="loading-page__text" style={{color: Colors.TEXT['text_color']}}>
                        Идет переход на запись
                    </p>
                    <div className="loading-page__hearts">
                        {hearts.map(el => (
                            <React.Fragment key={el}>
                                {value === el ? <HeartPinkIcon width={16} height={14}/> :
                                    <HeartPink3Icon width={16} height={16}/>}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
        </div>
    );
};

export default LoadingComponent;