import React from 'react';

const UsersIcon = ({color, width, height, className, opacity}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 24 24"
             fill="none">
            <g opacity={opacity}>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H13C14.3261 14 15.5979 14.5268 16.5355 15.4645C17.4732 16.4021 18 17.6739 18 19V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V19C16 18.2044 15.6839 17.4413 15.1213 16.8787C14.5587 16.3161 13.7956 16 13 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21C2 21.5523 1.55228 22 1 22C0.447715 22 0 21.5523 0 21V19C0 17.6739 0.526784 16.4021 1.46447 15.4645Z"
                      fill={color}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9 4C7.34315 4 6 5.34315 6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4ZM4 7C4 4.23858 6.23858 2 9 2C11.7614 2 14 4.23858 14 7C14 9.76142 11.7614 12 9 12C6.23858 12 4 9.76142 4 7Z"
                      fill={color}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M19.032 14.8799C19.1701 14.3451 19.7155 14.0236 20.2502 14.1616C21.3229 14.4386 22.2732 15.064 22.9519 15.9395C23.6307 16.8151 23.9994 17.8913 24.0002 18.9991L24.0002 20.9999C24.0002 21.5522 23.5525 21.9999 23.0002 21.9999C22.448 21.9999 22.0002 21.5522 22.0002 20.9999L22.0002 19.0006C22.0002 19.0005 22.0002 19.0007 22.0002 19.0006C21.9997 18.336 21.7784 17.6901 21.3713 17.1649C20.964 16.6395 20.3938 16.2643 19.7502 16.0981C19.2155 15.9601 18.8939 15.4146 19.032 14.8799Z"
                      fill={color}/>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M15.0315 2.88184C15.1685 2.34681 15.7133 2.02414 16.2483 2.16113C17.3238 2.43651 18.2771 3.06201 18.9578 3.93902C19.6386 4.81603 20.0081 5.89467 20.0081 7.00488C20.0081 8.11509 19.6386 9.19373 18.9578 10.0707C18.2771 10.9478 17.3238 11.5733 16.2483 11.8486C15.7133 11.9856 15.1685 11.6629 15.0315 11.1279C14.8945 10.5929 15.2172 10.0481 15.7522 9.91113C16.3975 9.7459 16.9695 9.3706 17.3779 8.8444C17.7864 8.31819 18.0081 7.67101 18.0081 7.00488C18.0081 6.33875 17.7864 5.69157 17.3779 5.16536C16.9695 4.63915 16.3975 4.26385 15.7522 4.09863C15.2172 3.96164 14.8945 3.41687 15.0315 2.88184Z"
                      fill={color}/>
            </g>
        </svg>
    );
};

export default UsersIcon;