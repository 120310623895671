import React from 'react';
import Colors from "../../../global/styles/Colors";
import DotsIcon from "../../../components/kit/Icons/DotsIcon";
import ReactTooltip from "react-tooltip";
import PencilIcon from "../../../components/kit/Icons/PencilIcon";
import TrashIcon from "../../../components/kit/Icons/TrashIcon";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const CategoryElement = ({data, onRemove, onEdit}) => {

    return (
        <TableRow className="dashboard-categories-root__item" style={{
            color: Colors.NEUTRAL['black'],
            borderBottom: `1px solid ${Colors.NEUTRAL['border']}`
        }}>
            <TableCell align="left">
                <p className="dashboard-page-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.name}
                </p>
            </TableCell>
            <TableCell align="center">
                <div className="dashboard-page-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                            <span data-tip data-for={`category-tooltip-${data.id}`} data-event='click focus'>
                            <DotsIcon width={24} height={24} color={Colors.NEUTRAL['black']}/>
                            <ReactTooltip id={`category-tooltip-${data.id}`} place="bottom" globalEventOff='click' clickable={true}
                                          effect="solid" isCapture={true}>
                                <div className="dashboard-page-root__tooltip">
                                    <button onClick={onEdit} className="dashboard-page-root__tooltip-element">
                                        <PencilIcon width={14} height={16} color={Colors.NEUTRAL['white']}
                                                    className="dashboard-page-root__tooltip-element--edit"/>
                                        Редактировать</button>
                                    <button onClick={onRemove} className="dashboard-page-root__tooltip-element">
                                        <TrashIcon width={13} height={14} color={Colors.NEUTRAL['white']}
                                                   className="dashboard-page-root__tooltip-element--remove"/>
                                        Удалить
                                    </button>
                                </div>
                            </ReactTooltip>
                            </span>
                </div>
            </TableCell>
        </TableRow>
    )
};

export default CategoryElement;