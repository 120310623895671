import React from 'react';

const PencilIcon = ({width, height, color, className, opacity}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} opacity={opacity}
             viewBox="0 0 16 16"
             fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.1954 1.52876C10.4558 1.26841 10.8779 1.26841 11.1382 1.52876L14.4716 4.86209C14.7319 5.12244 14.7319 5.54455 14.4716 5.8049L5.8049 14.4716C5.67988 14.5966 5.51031 14.6668 5.3335 14.6668H2.00016C1.63197 14.6668 1.3335 14.3684 1.3335 14.0002V10.6668C1.3335 10.49 1.40373 10.3204 1.52876 10.1954L10.1954 1.52876ZM2.66683 10.943V13.3335H5.05735L13.0574 5.3335L10.6668 2.94297L2.66683 10.943Z"
                  fill={color}/>
        </svg>
    );
};

export default PencilIcon;