const Colors = {
    PRIMARY: {
        purple: '#BE52F2',
        purple_2: '#DBA5F5',
        purple_3: '#EEDFF2',
        purple_blue: '#6979F8',
        purple_blue_2: '#A5AFFB',
        purple_blue_3: '#E5E7FA'
    },
    SEMANTIC: {
        yellow: '#FFCF5C',
        yellow_2: '#FFE29D',
        yellow_3: '#FFF8E7',
        orange: '#FFA26B',
        orange_2: '#FFC7A6',
        orange_3: '#FFE8DA',
        blue: '#0084F4',
        blue_2: '#66B5F8',
        blue_3: '#D5E9FA',
        green: '#00C48C',
        green_2: '#7DDFC3',
        green_3: '#D5F2EA',
        pink: '#FF647C',
        pink_2: '#FDAFBB',
        pink_3: '#FBE4E8'
    },
    NEUTRAL: {
        black: '#1A051D',
        purple_black: '#3F3356',
        gray_purple: '#D0C9D6',
        border: '#ECEBED',
        light_gray: '#F7F5F9',
        white: '#FFFFFF',
    },
    GRADIENT: {
        purple: 'linear-gradient(191.77deg, #BD7AE3 0%, #8461C9 100%)',
        purple_light: 'linear-gradient(191.77deg, #CDDDF4 3.26%, #CDBAFA 100%)'
    },
    TEXT: {
        text_color: '#95909B',
        icon: '#C2BCCE'
    }
};

export default Colors;