import React from 'react';
import Colors from "../../../global/styles/Colors";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import './index.scss';
import moment from "moment";
import TimeIcon from "../../../components/kit/Icons/TimeIcon";

const MONTH = 'MONTH';
const YEAR = 'YEAR';

const FinancePayboxElement = ({data}) => {
    const renderPaymentType = (type) => {
        switch (type) {
            case MONTH:
                return 'Месяц'
            case YEAR:
                return 'Год'
            default:
                return null
        }
    }

        return (
            <TableRow>
                <TableCell align="left">
                    <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                        {data?.id}
                    </p>
                </TableCell>
                <TableCell align="left">
                    <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                        <TimeIcon width={16} height={16} color={Colors.NEUTRAL['gray_purple']}
                                  className="dashboard-notifications-root__item-time-icon"/>
                            {moment(data.paid_date).format('DD-MM-YYYY')}
                    </p>
                </TableCell>

                <TableCell align="left">
                    <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                        {renderPaymentType(data?.type)}
                    </p>
                </TableCell>

                <TableCell align="left">
                    <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                        {data?.service_type}
                    </p>
                </TableCell>
                <TableCell align="left">
                    <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                        {data?.tariff_name}
                    </p>
                </TableCell>

                <TableCell align="left">
                    <p className="dashboard-finance-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                        {`${data?.price_paid} cом`}
                    </p>
                </TableCell>
            </TableRow>
        );
    };

    export default FinancePayboxElement;