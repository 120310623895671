import {createBrowserHistory} from "history";
import {connectRouter, routerMiddleware} from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {loadState, saveState} from "./localStorage";

import axios from '../store/Api/axios-api';
import calendarReducer from "./calendar/calendarStore";
import coreReducer from "./core/coreStore";
import applicationsReducer from "./applications/applicationsStore";
import staffsReducer from "./staff/staffStore";
import serviceReducer from "./service/serviceStore";
import categoriesReducer from "./categories/categoriesStore";
import financeReducer from "./finance/financeStore";
import userReducer from "./user/userStore";
import mainPageReducer from "./Main/MainPage/mainPageStore";
import {servicesReducer} from "./Main/services/servicesStore";
import {masterReducer} from "./Main/listMaster/listMasterStore";
import {salonsReducer} from "./Main/listSalons/listSalonsStore";
import {detailSalon} from "./Main/detailedSalon/detailedSalonStore";
import {detailMaster} from "./Main/detailMaster/detailedMasterStore";
import {categoryReduce} from "./Main/filter/filterStore";
import {filterPageSalonReducer} from "./Main/filterSalon/filterSalonStore";
import {filterPageMasterReducer} from "./Main/filterMaster/filterMasterStore";
import {payMethodReducer} from "./payMethod/payMethodStore";
import {listMastersSalonsReducer} from "./Main/listMastersSalonsServices/listMastersSalonsServicesStore";
import {searchReducer} from './Main/search/searchStore'

export const history = createBrowserHistory();

history.listen((location, action) => {
    window.scrollTo(50, 0);
});

const rootReducer = combineReducers({
    router: connectRouter(history),
    core: coreReducer,
    calendar: calendarReducer,
    applications: applicationsReducer,
    staffs: staffsReducer,
    services: serviceReducer,
    categories: categoriesReducer,
    reports: financeReducer,
    user: userReducer,
    mainPage: mainPageReducer,
    listCategories: servicesReducer,
    listMaster: masterReducer,
    listSalon: salonsReducer,
    detailSalon: detailSalon,
    detailMaster: detailMaster,
    categoryList: categoryReduce,
    filterSalon: filterPageSalonReducer,
    filterMaster: filterPageMasterReducer,
    payMethods: payMethodReducer,
    listMastersSalonsServices: listMastersSalonsReducer,
    searchReducer: searchReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [
    thunkMiddleware,
    routerMiddleware(history)
];


const enhancers = composeEnhancers(applyMiddleware(...middleware));

const persistedState = loadState();

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
    saveState({
        user: store.getState().user
    })
});

axios.interceptors.request.use(config => {
    try {
        if (store.getState().user) {
            config.headers["Authorization"] = 'Token ' + store.getState().user.user.key;
        }
    } catch (e) {
        // user is not logged
    }

    return config;
});

export default store;