import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import woman from '../../../assets/Icons/woman-with-long-hair .svg'
import LoadingComponent from "../OnlineRecord/LoadingComponent/LoadingComponent";
import {fetchGetFilter} from '../../../store/Main/filter/filterAction'

import {getFilterSalon} from "../../../store/Main/filterSalon/filterSalonAction";
import {getFilterMaster} from "../../../store/Main/filterMaster/filterMasterAction";

import {setBtnActiveSalon, setBtnActiveMaster, showFilter} from "../../../store/core/coreActions";

import './index.scss'

const FilterPage = ({
    categoryList,
    fetchGetFilterSalon,
    onClick,
    btnActive = 0,
    history,
    defaultPage
}) => {

    const [state, setState] = useState(null)
    const [stateServices, setServices] = useState(null)
    const [showService, setShowService] = useState(null)
    const [idResponse, setResponse] = useState([])

    const setIdResponse = (id) => {
        const responseId = idResponse
        const findId = responseId.indexOf(id)
        if(findId === -1){
            responseId.push(id)
        }else {
            responseId.splice(findId, 1)
        }
        setResponse(responseId)
    }

    const openServices = (e, index) => {
        e.stopPropagation()
        const cloneState = [...showService]
        cloneState[index] = !showService[index]
        setShowService(cloneState)
    }

    const handlerSelectService = (e, indexCategory, indexServices, id) => {
        e.stopPropagation()
        const selectServices = [...stateServices]
        selectServices[indexCategory][indexServices] = !stateServices[indexCategory][indexServices]
        setServices(selectServices)
        setIdResponse(id)
    }

    useEffect(() => {
        fetchGetFilterSalon()
    }, [])

    useEffect(() => {
        if(categoryList.category){
            const services = []
            categoryList.category.map(item => item.values.map(itemServices => services.push(itemServices)))
            setState(services)
            setShowService(new Array(services.length).fill(false))
            setServices(services.map(items => items.values.map(item => false)))
        }
    }, [categoryList])

    const getSalon = () => {
        if(idResponse.length) {
            history.push(`/filter_result/${btnActive ? 'salons' : 'masters'}/${defaultPage ? 'default' : ''}?id=${idResponse.join(',')}`)
        }
    }

    if(!state) return <div className="wrapper-loading"><LoadingComponent /></div>

    return (
        <div className="wrapper-filter-page"
             onClick={onClick}
        >
            <div className="filter-page" onClick={e => e.stopPropagation()}>
                <div>
                    <h4>Фильтр</h4>

                    <div className="filters-items">

                        {state.map((item, index) => {
                            return (
                                <div key={index}>
                                    <div className="filter-item"
                                         onClick={e => openServices(e, index)}>
                                        <span>
                                            <img src={woman} alt=""/>
                                            <p>{item.name}</p>
                                        </span>

                                        <span className={showService[index] ? "item-arrow-rotate" : "item-arrow"}/>
                                    </div>

                                    {showService[index] &&
                                        <div className="list-services" key={index}>

                                            {item.values.map((itemService, indexServices) => {
                                                return(
                                                    <div
                                                        className={stateServices[index][indexServices] ? "list-services-item-select" : "list-services-item"}
                                                        key={indexServices}
                                                        onClick={(e) => {
                                                            handlerSelectService(e, index, indexServices, itemService.id)
                                                        }}
                                                    >{itemService.name}</div>
                                                )
                                            })}

                                        </div>
                                    }

                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="btn-show">
                    <button onClick={getSalon} >
                        Показать
                    </button>
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    categoryList: state.categoryList
})

const mapDispatchToProps = dispatch => ({
    fetchGetFilterSalon: () => {dispatch(fetchGetFilter())},
    getFilterSalon: (idServices) => {dispatch(getFilterSalon(idServices))},
    getFilterMaster: (idServices) => {dispatch(getFilterMaster(idServices))},
    setBtnActiveSalon: () => {dispatch(setBtnActiveSalon())},
    setBtnActiveMaster: () => {dispatch(setBtnActiveMaster())},
    showFilter: () => {dispatch(showFilter())},
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterPage);