export const currentDate = new Date();

export const ROUTE_HOME = '/';
export const ROUTE_CALENDAR = '/calendar';
export const ROUTE_CALENDAR_WITH_PARAMS = '/calendar/:salonId/:date';
export const ROUTE_NOTIFICATIONS = '/applications';
export const ROUTE_FINANCE = '/finance';
export const ROUTE_SETTINGS = '/settings_main/';
export const ROUTE_SETTINGS_ADD = '/salon/add';
export const ROUTE_SETTINGS_ROLE = '/settings_role/';
export const ROUTE_SERVICES = '/services';
export const ROUTE_CATEGORIES = '/categories';
export const ROUTE_USERS = '/users';
export const ROUTE_RECORD = '/record/:salonId';
export const ROUTE_LOGIN = '/login';
export const MERCHANTS = '/merchants';
export const RECORDS = '/records';
export const NEW_ENTRY = '/new_entry'
export const ALL_MASTER = '/all_master'
export const MAIN_PAGE = '/main'
export const ABOUT_SALON = '/about/:type/:id'
export const REVIEWS = '/reviews/:type/:id'
export const FILTER_RESULT = '/filter_result/:type'
export const SEARCH_RESULT = '/search_result/:type'
export const RECORDS_SERVICES = '/records_services'
export const RECORDS_SERVICES_DEFAULT = '/records_services/:type'
export const RECORDS_SERVICES_DEFAULT_ID = '/records_services/:type/:id'

// Applications

export const APPLICATION_NEW = "N"
export const APPLICATION_ACCEPTED = "A"
export const APPLICATION_REJECTED = "R"

export const APPLICATIONS_STATUS_DISPLAY = {
    "N": "В ожидании ответа",
    "A": "Обработан",
    "R": "Отменен"
}

// Staffs
export const ROLE_MASTER = "M"
export const ROLE_ADMIN = "A"
export const ROLE_OWN = "V"

export const ROLES = {
    "V": {
        name: "владелец",
        color: "#6979F8"
    },
    "A": {
        name: "администратор",
        color: "#BE52F2"
    },
    "M": {
        name: "специалист",
        color: "#00C48C"
    }
}

export const ERROR_TEXT = "Не удалось выполнить запрос";

export const TYPE_PAYMENTS = [
    {name: 'mbank'},
    {name: 'cash'},
]