import axios from 'axios';
import {baseURLMain, baseUrl} from "./Routes";
import {removeState} from "../localStorage";
import {LOGIN} from "../../global/Helpers/Links";

const instance = axios.create({
    baseURL: baseUrl
    
});

/**
 * Global API Error Handling
 */
instance.interceptors.response.use((r) => r,
    (error) => {
    if (
        error.response
        && error.response.status === 401
    ) {
        removeState()
        window.location(LOGIN)
        return;
    }

    return Promise.reject(error);
});

export default instance;
