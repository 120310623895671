import {FETCH_MAIN_PAGE_REQUEST, FETCH_MAIN_PAGE_SUCCESS} from "./mainPageAction";

const initialState = {
    requested: false,

    me: null,
    myAppointments: null,

    promos: null,
    popularServices: null,
    bestSalons: null,
    topMasters: null,
}

const mainPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MAIN_PAGE_REQUEST:
            return {...state, requested: true}

        case FETCH_MAIN_PAGE_SUCCESS:
            return {...state, requested: false, me: action.value.me,
                bestSalons: action.value.best_salons, myAppointments: action.value.my_appointments,
                popularServices: action.value.popular_services, promos: action.value.promos,
                topMasters: action.value.top_masters}
        default: return state
    }
}

export default mainPageReducer