import React, {useEffect, useState} from 'react';
import Colors from "../../../../global/styles/Colors";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";
import InputPhoneMask from "../../../../components/kit/InputPhoneMask/InputPhoneMask";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";
import ReactCodeInput from 'react-code-input'
import {connect} from "react-redux";

const AuthUser = ({handleBackNav, handleNextNavByAuth, applications}) => {

    const [phone, setPhone] = useState("");

    const [showCode, toggleShowCode] = useState(true);

    const [code, setCode] = useState('')

    let [time, setTime] = useState(20);

    useEffect(() => {
        let interval;

        if (time > 0 && showCode) {
            interval = setInterval(function () {
                setTime(time - 1)
            }, 1000)
        }

        return () => clearInterval(interval)
    }, [showCode, time])


    function handlePhoneChange(e) {
        e.persist();
        setPhone(e.target.value)
    }

    function onSendPhoneNumber() {
        // if (!showCode) {
        //     toggleShowCode(true)
        // } else {
            handleNextNavByAuth(code)
        // }
    }

    function handlerCodeInput(value) {
        setCode(value)
    }

    function resendCode() {
        setTime(20)
    }

    const disabled = code.length < 4;

    return (
        <div className="auth-user-online">
            <h3 className="service-variant-root__title__auth" style={{color: Colors.NEUTRAL['black']}}>
               {/* <span className="record-nav-back-icon">*/}
               {/*    <ArrowLargeIcon onClick={handleBackNav}/>*/}
               {/*</span>*/}
                {showCode ? "СМС код" : "Номер"}
                {/*<span className="empty"/>*/}
            </h3>
            <p className="auth-user-online__sub-title">
                {showCode ? "Введите смс код для подтверждения записи" : "Укажите номер для подтверждения"}
            </p>

            <div className="auth-user-online__phone-number">
                {/*{!showCode && (*/}
                {/*    <InputPhoneMask*/}
                {/*        className="select-date-root__phone-field"*/}
                {/*        value={phone}*/}
                {/*        propertyName="phone"*/}
                {/*        onChange={handlePhoneChange}*/}
                {/*    />*/}
                {/*)}*/}

                {/*{showCode && (*/}
                    <div className="auth-user-online__code-field">
                        <ReactCodeInput
                            type='number'
                            fields={4}
                            value={code}
                            onChange={handlerCodeInput}
                            className="auth-user-online__code-input"
                        />
                        <p className="auth-user-online__code-helper-txt">
                            Смс код придет в течении:&nbsp;<span>{time} сек</span>
                        </p>
                    </div>
                {/*)}*/}

            </div>

            {/*<GeneralButton*/}
            {/*    bgColor={time > 0 ? Colors.NEUTRAL['border'] : Colors.SEMANTIC['pink']}*/}
            {/*    className="auth-user-online__btn --repeat"*/}
            {/*    disabled={time > 0}*/}
            {/*    title="Повторить отправку кода"*/}
            {/*    textColor={time > 0 ? Colors.TEXT['text_color'] : Colors.NEUTRAL['white']}*/}
            {/*    onClick={resendCode}/>*/}

            <GeneralButton
                bgColor={disabled ? Colors.NEUTRAL['border'] : Colors.SEMANTIC['pink']}
                className="auth-user-online__btn"
                title={"Подтвердить"}
                textColor={disabled ? Colors.TEXT['text_color'] : Colors.NEUTRAL['white']}
                onClick={onSendPhoneNumber}/>
        </div>
    );
};

const mapStateToProps = state => ({
    applications: state.applications
})

export default connect(mapStateToProps)(AuthUser);