import React, {useEffect} from 'react';
import Colors from "../../../../global/styles/Colors";
import ArrowIcon from "../../../../components/kit/Icons/ArrowIcon";

import '../index.scss';
import {fetchStaffsGet} from "../../../../store/staff/staffActions";
import {connect} from "react-redux";
import CommonSpinner from "../../../../components/kit/CommonSpinner/CommonSpinner";
import {ROLE_MASTER} from "../../../../global/Constants/Constants";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";


const ChooseMaster = (
    {
        onSelectMaster,
        staffsList,
        salon,
        services,

        fetchStaffsGet,
        requested,
        handleBackNav
    }
) => {

    useEffect(function () {
        const serviceIds = services.map(service => service.id)
        fetchStaffsGet({position: ROLE_MASTER}, [salon.id], serviceIds)
    }, [])

    return (
        <div className="choose-master-root">
            <h3 className="service-variant-root__title" style={{color: Colors.NEUTRAL['black']}}>
                <span className="record-nav-back-icon">
                   <ArrowLargeIcon onClick={handleBackNav}/>
               </span>
                <span>Выбрать специалиста</span>
                <span className="empty"/>
            </h3>
            {requested && <CommonSpinner isLoading={requested} centered/>}
            {!requested && (
                <>
                    {/*<p className="service-variant-root__sub-title" style={{color: Colors.TEXT['icon']}}>*/}
                    {/*    Ногтевой сервис*/}
                    {/*</p>*/}

                    {staffsList && staffsList.map((item, ndx) => (
                        <div key={ndx} className="choose-master-root__master"
                             onClick={() => onSelectMaster(item)}>
                            <span className="choose-master-root__name-symbol"
                            style={{
                                backgroundColor: Colors.PRIMARY['purple_3'],
                                color: Colors.PRIMARY['purple']
                            }}>

                            {!!item.image ? (
                                <img src={item.image} className="userImage" alt="$"/>
                            ) : (
                                <span>
                                    <span>{item.name && item.name.charAt().toUpperCase()}</span>
                                    <span>{item.surname && item.surname.charAt().toUpperCase()}</span>
                                </span>
                            )}
                            </span>
                            <span className="choose-master-root__master-name"
                                style={{color: Colors.NEUTRAL['purple_black']}}>
                                {item.name}&nbsp;{item.surname}
                            </span>
                            <span className="choose-master-root__arrow-icon">
                                <ArrowIcon
                                    width={6}
                                    height={11}
                                    color={Colors.PRIMARY['purple']}/>
                            </span>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    salon: state.core.salon,
    requested: state.staffs.requested,
    staffsList: state.staffs.staffsList
})

const mapDispatchToProps = dispatch => ({
    fetchStaffsGet: (params, salonId, serviceIds) => dispatch(fetchStaffsGet(params, salonId, serviceIds)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChooseMaster);