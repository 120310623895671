import React from 'react';

const IdeaIcon = ({width = 20, height = 20, color = "#0084F4"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10 0C5.8581 0 2.5 3.3581 2.5 7.5C2.5 10.4463 4.19947 12.9895 6.66667 14.2156V15C6.66667 15.0003 6.66667 15.0006 6.66667 15.0009V16.6667C6.66667 18.0474 7.78595 19.1667 9.16667 19.1667H10.8333C12.214 19.1667 13.3333 18.0474 13.3333 16.6667L13.3333 15V14.2156C15.8005 12.9895 17.5 10.4463 17.5 7.5C17.5 3.3581 14.1419 0 10 0ZM11.6667 14.1667V13.6775C11.6667 13.338 11.8726 13.0325 12.1872 12.9051C14.3267 12.0386 15.8333 9.94568 15.8333 7.5C15.8333 4.27857 13.2214 1.66667 10 1.66667C6.77857 1.66667 4.16667 4.27857 4.16667 7.5C4.16667 9.94568 5.67331 12.0386 7.81281 12.9051C8.12744 13.0325 8.33333 13.338 8.33333 13.6775V14.1654M8.33333 14.1667H11.6667H8.33333ZM11.6667 15.8333H8.33333V16.6667C8.33333 17.1269 8.70643 17.5 9.16667 17.5H10.8333C11.2936 17.5 11.6667 17.1269 11.6667 16.6667V15.8333Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.99968 5.00016C8.61908 5.00016 7.49967 6.11957 7.49967 7.50016C7.49967 7.9604 7.12658 8.3335 6.66634 8.3335C6.2061 8.3335 5.83301 7.9604 5.83301 7.50016C5.83301 5.19909 7.6986 3.3335 9.99968 3.3335C10.4599 3.3335 10.833 3.70659 10.833 4.16683C10.833 4.62707 10.4599 5.00016 9.99968 5.00016Z"
                  fill={color}/>
        </svg>
    );
};

export default IdeaIcon;