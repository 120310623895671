import React from 'react';
import CheckIcon from "../../../components/kit/Icons/CheckIcon";
import Colors from "../../../global/styles/Colors";
import TimeIcon from "../../../components/kit/Icons/TimeIcon";
import DotsIcon from "../../../components/kit/Icons/DotsIcon";
import ReactTooltip from "react-tooltip";
import PencilIcon from "../../../components/kit/Icons/PencilIcon";
import TrashIcon from "../../../components/kit/Icons/TrashIcon";
import {
    APPLICATION_ACCEPTED,
    APPLICATION_REJECTED,
    APPLICATIONS_STATUS_DISPLAY
} from "../../../global/Constants/Constants";
import ClockIcon from "../../../components/kit/Icons/ClockIcon";
import {getHourByMinutes, getRemainingMinutes} from "../../../global/Helpers/helpers";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const MAX_SERVICE_DISPLAY_LENGTH = 4;

const ApplicationElement = ({data, onChangeStatus}) => {

    const approveApplication = () => {
        onChangeStatus(data.id, APPLICATION_ACCEPTED)
    }

    function rejectApplication() {
        onChangeStatus(data.id, APPLICATION_REJECTED)
    }

    return (
        <TableRow>
            <TableCell align="left">
                <p className="dashboard-notifications-root__item-title">
                        <span className="dashboard-notifications-root__item-status-icon"
                              style={{
                                  backgroundColor: data.is_confirmed === APPLICATION_ACCEPTED
                                      ? Colors.SEMANTIC['green'] : "transparent"
                              }}>
                            {data.is_confirmed === APPLICATION_ACCEPTED ? (
                                    <CheckIcon width={9} height={6} color={Colors.NEUTRAL['white']}/>
                                )
                                : (<ClockIcon/>)}

                        </span>
                    <span>{APPLICATIONS_STATUS_DISPLAY[data.is_confirmed]}</span>
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-notifications-root__item-title" style={{color: Colors.TEXT['text_color']}}>
                    <TimeIcon width={16} height={16} color={Colors.NEUTRAL['gray_purple']}
                              className="dashboard-notifications-root__item-time-icon"/>
                    {data.appointed_date}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-notifications-root__item-title">
                    {data.name}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-notifications-root__item-title">
                    {data.number}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-notifications-root__item-title">
                    {getHourByMinutes(data.total_time)}
                    <span style={{backgroundColor: Colors.PRIMARY['purple_2']}}
                          className="dashboard-notifications-root__item-range-icon"/>
                    {getRemainingMinutes(data.total_time)}
                </p>
            </TableCell>
            <TableCell align="left">
                <div className="dashboard-notifications-root__item-title">
                    {data.service_list && data.service_list.map((service, ndx) => {
                        if (ndx < MAX_SERVICE_DISPLAY_LENGTH) {
                            return (
                                <div className="dashboard-notifications-root__item-service"
                                     style={{
                                         backgroundColor: Colors.PRIMARY['purple_3'],
                                         border: `1px solid ${Colors.PRIMARY['purple_2']}`,
                                         color: Colors.NEUTRAL['purple_black']
                                     }}
                                     key={service.id}>
                                    {service.name}
                                </div>
                            )
                        } else return null
                    })}

                    {data.service_list && data.service_list.length > MAX_SERVICE_DISPLAY_LENGTH && (
                        <div className="dashboard-notifications-root__item-service"
                             style={{
                                 backgroundColor: Colors.PRIMARY['purple_3'],
                                 border: `1px solid ${Colors.PRIMARY['purple_2']}`,
                                 color: Colors.NEUTRAL['purple_black']
                             }}>
                            {data.service_list[MAX_SERVICE_DISPLAY_LENGTH].name}
                            {(data.service_list.length - 1) - MAX_SERVICE_DISPLAY_LENGTH > 0 && (
                                <div className="dashboard-notifications-root__item-service-last">
                                    <div
                                        data-tip
                                        data-for={`notification-service-tooltip-${data.id}`}
                                        data-event="click focus"
                                        style={{
                                            backgroundColor: Colors.PRIMARY['purple_blue'],
                                            color: Colors.NEUTRAL['white']
                                        }}
                                        className="dashboard-notifications-root__item-service-count">
                                        {(data.service_list.length - 1) - MAX_SERVICE_DISPLAY_LENGTH} +
                                        <ReactTooltip
                                            id={`notification-service-tooltip-${data.id}`}
                                            place="bottom"
                                            globalEventOff="click"
                                            clickable={true}
                                            className="application-tooltip"
                                            effect="solid"
                                            isCapture={true}
                                        >
                                            <div className="dashboard-notifications-root__item-service-tooltip">
                                                {data.service_list.map((service, ndx) => {
                                                    if (ndx >= MAX_SERVICE_DISPLAY_LENGTH) {
                                                        return (
                                                            <p
                                                                key={service.id}
                                                                className="dashboard-notifications-root__item-service-title"
                                                                style={{color: Colors.NEUTRAL['white']}}>
                                                                {service.name}
                                                            </p>
                                                        )
                                                    } else return null
                                                })}
                                            </div>
                                        </ReactTooltip>
                                    </div>

                                </div>
                            )}

                        </div>
                    )}

                </div>
            </TableCell>
            <TableCell align="left">

                <p className="dashboard-notifications-root__item-title">
                    {data.total_sum} сом
                </p>
            </TableCell>
            <TableCell align="left">
                <div className="dashboard-notifications-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                 <span data-tip data-for={`tooltip-${data.id}`} data-event='click focus'>
                            <DotsIcon width={24} height={24} color={Colors.NEUTRAL['black']}/>
                            <ReactTooltip id={`tooltip-${data.id}`} place="bottom" globalEventOff='click'
                                          clickable={true}
                                          effect="solid" isCapture={true}>
                                <div className="dashboard-notifications-root__tooltip">
                                    <button className="dashboard-notifications-root__tooltip-element"
                                            onClick={approveApplication}>
                                        <PencilIcon width={14} height={16} color={Colors.NEUTRAL['white']}
                                                    className="dashboard-notifications-root__tooltip-element--edit"/>
                                        Одобрить запись
                                    </button>
                                    <button className="dashboard-notifications-root__tooltip-element"
                                            onClick={rejectApplication}>
                                        <TrashIcon width={13} height={14} color={Colors.NEUTRAL['white']}
                                                   className="dashboard-notifications-root__tooltip-element--remove"/>
                                        Отменить запись
                                    </button>
                                </div>
                            </ReactTooltip>
                 </span>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default ApplicationElement;