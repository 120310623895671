import React, {useEffect, useState} from 'react';
import {
    LINK_ABOUT_MASTER,
    LINK_ABOUT_MASTER_DEFAULT,
    LINK_ABOUT_SALON,
    LINK_ABOUT_SALON_DEFAULT
} from "../../../../global/Helpers/Links";

import {connect} from "react-redux";

import {hideSearchPage} from "../../../../store/Main/search/searchAction";
import {getSalonsSearch, getMastersSearch, getServicesSearch} from "../../../../store/Main/search/searchAction";

import './index.scss'
import ListSearch from "./component/ListSearch/ListSearch";
import {getDefaultPage} from "../../../../global/Helpers/helpers";
import Arrow from "../../../../components/kit/Arrow/Arrow";
import ListServices from "./component/ListServices/ListServices";

const MASTERS = 'masters'
const SALONS = 'salons'
const SERVICES = 'services'

const SearchPage = (props) => {

    const {searchReducer} = props
    const {history, match} = props
    const {requestSalonsSearch, requestMastersSearch, requestServicesSearch} = props
    const [type, setType] = useState(null)
    const [defaultCheck, setDefaultCheck] = useState(null)

    const urlParams = new URLSearchParams(history?.location?.search);
    const paramsFilter = urlParams.get('search')

    useEffect(() => {
        setDefaultCheck(getDefaultPage(match?.params?.default))
        requestToSearch(match.params.type)
    }, [])

    const requestToSearch = typeLink => {
        switch (typeLink) {
            case MASTERS:
                requestMastersSearch(paramsFilter)
                setType('masterSearch')
                break
            case SALONS:
                requestSalonsSearch(paramsFilter)
                setType('salonSearch')
                break
            case SERVICES:
                requestServicesSearch(paramsFilter + '&page=1')
                setType('servicesSearch')
                break
        }
    }

    return (
        <div className="container">
            <div className="online-record-root">
                <div className="wrapper-result__results">

                    <div className="header-result__wrapper">

                        <Arrow
                            title="Результат"
                            history={history}
                        />

                    </div>
                        <div className="list-search-result">
                            {type === 'servicesSearch' ?
                                <ListServices
                                    list={searchReducer[type]}
                                    link={defaultCheck ? LINK_ABOUT_SALON_DEFAULT : LINK_ABOUT_SALON}
                                />
                                :
                                <ListSearch
                                    list={searchReducer[type]}
                                    btnActive={type === 'masterSearch' ? 0 : 1}
                                    link={defaultCheck ? [LINK_ABOUT_MASTER_DEFAULT, LINK_ABOUT_SALON_DEFAULT] : [LINK_ABOUT_MASTER, LINK_ABOUT_SALON]}
                                />
                            }
                        </div>
                    </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
    hidePage: (state) => {dispatch(hideSearchPage(state))},
    requestSalonsSearch: (search) => {dispatch(getSalonsSearch(search))},
    requestMastersSearch: (search) => {dispatch(getMastersSearch(search))},
    requestServicesSearch: (search) => {dispatch(getServicesSearch(search))}
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);