import {
    FETCH_PAGE_MASTER_REQUEST,
    FETCH_PAGE_MASTER_SUCCESS,
    FETCH_PAGE_PAGINATION_REQUEST,
    FETCH_PAGE_PAGINATION_SUCCESS
} from "./listMasterAction";

const initialState = {
    response: false,
    listMaster: null,

    paginationResponse: false,
    nextPage: null,
    previousPage: null,
}

export const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PAGE_MASTER_REQUEST:
            return {...state, response: true}
        case FETCH_PAGE_MASTER_SUCCESS:
            return {...state, response: false,
                listMaster: action.value.results, nextPage: action.value.next,
                previousPage: action.value.previous}
        case FETCH_PAGE_PAGINATION_REQUEST:
            return {...state, paginationResponse: true}
        case FETCH_PAGE_PAGINATION_SUCCESS:
            const copyMaster = [...state.listMaster].concat([...action.value.results])
            return {...state, paginationResponse: false, nextPage: action.value.next, listMaster: copyMaster}
        default: return state
    }
}