import React from 'react';
import ArrowLargeIcon from "../Icons/ArrowLargeIcon";

import './index.scss'

const Arrow = ({title = '', history}) => {

    return (
        <div className="arrow">
            <span
                onClick={() => {history.goBack()}}
            >
                <ArrowLargeIcon/>
            </span>

            <h3>{title}</h3>
        </div>
    );
};

export default Arrow;