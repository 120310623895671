import React from "react";
import axios from "../Api/axios-api";
import {NotificationManager} from 'react-notifications';
import {ERROR_TEXT} from "../../global/Constants/Constants";
import {ROUTE_STAFFS} from "../Api/Routes";
import SwalModal from "../../components/SwalModal/SwalModal";
import swal from "@sweetalert/with-react";

export const FETCH_STAFFS_REQUEST = 'FETCH_STAFFS_REQUEST';
export const FETCH_STAFFS_SUCCESS = 'FETCH_STAFFS_SUCCESS';
const fetchStaffsRequest = () => ({type: FETCH_STAFFS_REQUEST});
const fetchStaffsGetSuccess = staffs => ({type: FETCH_STAFFS_SUCCESS, staffs});
export const fetchStaffsGet = (filter = {}, salonsIds, serviceIds) => {

    let params = `?page=${filter.page || ""}&position=${filter.position || ""}&search=${filter.search || ""}`
    if (salonsIds) {
        params += `&salon=${salonsIds.join(",")}`
    }
    if (serviceIds) {
        params += `&service=${serviceIds.join(",")}`
    }

    return dispatch => {
        dispatch(fetchStaffsRequest())
        return axios.get(ROUTE_STAFFS + params).then(
            response => {
                dispatch(fetchStaffsGetSuccess(response.data))
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchCreateStaff = data => {
    return dispatch => {
        return axios.post(ROUTE_STAFFS, data).then(
            () => {
                const el = <SwalModal title="Успешно добавлено!"
                                      subTitle={`Вы добавили специалиста ${data.get('name')} ${data.get('surname')} успешно!`}
                                      swal={swal}/>;
                swal({buttons: false, content: el});
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchEditStaff = (staffId, data) => {
    return dispatch => {
        return axios.put(ROUTE_STAFFS + staffId + "/", data).then(
            () => {
                NotificationManager.success("Успешное изменение сотрудника")
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchRemoveStaff = staffId => {
    return dispatch => {
        return axios.delete(ROUTE_STAFFS + staffId + "/").then(
            () => {
                NotificationManager.success("Успешное удаление пользователя")
            },
            () => {
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

