import React from 'react';

const CopyIcon = ({width = 20, height = 20, color = "#0084F4"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.16602 8.33341C8.70578 8.33341 8.33268 8.70651 8.33268 9.16675V16.6667C8.33268 17.127 8.70578 17.5001 9.16602 17.5001H16.666C17.1263 17.5001 17.4993 17.127 17.4993 16.6667V9.16675C17.4993 8.70651 17.1263 8.33341 16.666 8.33341H9.16602ZM6.66602 9.16675C6.66602 7.78604 7.7853 6.66675 9.16602 6.66675H16.666C18.0467 6.66675 19.166 7.78604 19.166 9.16675V16.6667C19.166 18.0475 18.0467 19.1667 16.666 19.1667H9.16602C7.7853 19.1667 6.66602 18.0475 6.66602 16.6667V9.16675Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.33398 2.49992C3.11297 2.49992 2.90101 2.58772 2.74473 2.744C2.58845 2.90028 2.50065 3.11224 2.50065 3.33325V10.8333C2.50065 11.0543 2.58845 11.2662 2.74473 11.4225C2.90101 11.5788 3.11297 11.6666 3.33398 11.6666H4.16732C4.62756 11.6666 5.00065 12.0397 5.00065 12.4999C5.00065 12.9602 4.62756 13.3333 4.16732 13.3333H3.33398C2.67094 13.3333 2.03506 13.0699 1.56622 12.601C1.09738 12.1322 0.833984 11.4963 0.833984 10.8333V3.33325C0.833984 2.67021 1.09738 2.03433 1.56622 1.56548C2.03506 1.09664 2.67094 0.833252 3.33398 0.833252H10.834C11.497 0.833252 12.1329 1.09664 12.6018 1.56548C13.0706 2.03433 13.334 2.67021 13.334 3.33325V4.16659C13.334 4.62682 12.9609 4.99992 12.5007 4.99992C12.0404 4.99992 11.6673 4.62682 11.6673 4.16659V3.33325C11.6673 3.11224 11.5795 2.90028 11.4232 2.744C11.267 2.58772 11.055 2.49992 10.834 2.49992H3.33398Z"
                  fill={color}/>
        </svg>
    );
};

export default CopyIcon;