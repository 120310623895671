import React from 'react';
import Arrow from "../../../components/kit/Arrow/Arrow";

import silverIcon from '../../../assets/Icons/logoSilver.png'

import './index.scss'

const Records = () => {
    return (
        <div className="container">
            <div className="online-record-root">
                <Arrow title="Мои записи"/>

                <div className="empty-information">
                    <div className="icon-empty">
                        <img src={silverIcon} alt=""/>
                    </div>
                    <h3 className="text-empty">Записей нет</h3>
                </div>

            </div>
        </div>
    );
};

export default Records;