import React, {useEffect, useState} from 'react';
import {connect} from "react-redux"

import Arrow from "../../../components/kit/Arrow/Arrow";
import Filter from "../../../components/kit/Filter/Filter";
import ListNewRecording from "../../../components/List/ListNewRecording/ListNewRecording";
import FilterPage from "../FilterPage/FilterPage";
import LoadingComponent from "../OnlineRecord/LoadingComponent/LoadingComponent";
import {getListMaster, getListMasterPagination} from "../../../store/Main/listMaster/listMasterAction";

import {LINK_ABOUT_MASTER, LINK_ABOUT_MASTER_DEFAULT} from "../../../global/Helpers/Links";
import './index.scss';
import {getDefaultPage} from "../../../global/Helpers/helpers";

const ListMasterPage = ({
        listMaster,
        response,
        getListMaster,
        nextPage,
        getMasterPagination,
        paginationResponse,
        history,
        btnActive,
        match
}) => {

    const [getResponse, setResponse] = useState(false)
    const [filterPageIsOpen, setFilterPage] = useState(false)
    const [defaultCheck, setDefaultCheck] = useState(null)
    const [searchTitle, setSearchTitle] = useState('')

    useEffect(() => {
        document.body.style.overflow = filterPageIsOpen ? 'hidden' : 'auto';
    }, [filterPageIsOpen])

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        getListMaster()
        setDefaultCheck(getDefaultPage(match.params.default))

        return () => {
            document.removeEventListener('scroll', scrollHandler)
            document.body.style.overflow = 'auto';
        }
    }, [])

    useEffect(() => {
        paginationPage()
    }, [getResponse])

    const scrollHandler = (e) => {
        if(e.target.documentElement.scrollHeight -
            (e.target.documentElement.scrollTop + window.innerHeight) < 100
        ){
            setResponse(true)
        }
    }

    const paginationPage = () => {
        if(getResponse && !(paginationResponse) && nextPage){
                getMasterPagination(nextPage)
        }
        setResponse(false)
    }

    const setTitle = e => setSearchTitle(e.target.value)

    const onSubmitSearch = () => {
        history.push(`/search_result/${btnActive ? 'salons' : 'masters'}/${defaultCheck ? 'default' : ''}?search=${searchTitle}`)
    }

    if(response) return <LoadingComponent />

    return (
        <div className="container">
            <div className="online-record-root">
                <Arrow
                    title="Мастера"
                    history={history}
                />

                <Filter
                    placeholder="Поиск мастера"
                    onClick={() => setFilterPage(!filterPageIsOpen)}
                    setTitle={setTitle}
                    onSubmit={onSubmitSearch}
                    value={searchTitle}
                />

                {filterPageIsOpen &&
                    <FilterPage
                        isOpen={filterPageIsOpen}
                        onClick={() => setFilterPage(!filterPageIsOpen)}
                        history={history}
                        btnActive={btnActive}
                        defaultPage={defaultCheck}
                    />
                }

                <div className="list-discounts-page">
                    <ListNewRecording
                        listMaster={listMaster}
                        nextPage={[nextPage]}
                        paginationResponse={[paginationResponse]}
                        links={[LINK_ABOUT_MASTER, LINK_ABOUT_MASTER_DEFAULT]}
                        btnActive={defaultCheck ? 1 : 0}
                    />
                </div>

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    listMaster: state.listMaster.listMaster,
    response: state.listMaster.response,

    paginationResponse: state.listMaster.paginationResponse,
    nextPage: state.listMaster.nextPage,
    previousPage: state.listMaster.previousPage,
    btnActive: state.core.btnActive
})

const mapDispatchProps = dispatch => ({
    getListMaster: () => dispatch(getListMaster()),
    getMasterPagination: (url) => dispatch(getListMasterPagination(url))
})

export default connect(mapStateToProps, mapDispatchProps)(ListMasterPage);