import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Colors from "../../global/styles/Colors";
import CloseIcon from "../kit/Icons/CloseIcon";
import './index.scss';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: Colors.NEUTRAL['white'],
        padding: "30px 5px 40px",
        borderRadius: '12px',
        maxHeight: '550px',
        position: 'relative'
    },
    title: {
        margin: '0 0 32px 0',
        textAlign: 'center',
        fontSize: '20px',
        lineHeight: '26px',
        fontWeight: 500,
        color: Colors.TEXT['text_color']
    },
    closeIcon: {
        position: 'absolute',
        top: -20,
        right: -20,
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: Colors.NEUTRAL['white'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer'
    }
}));

const DashboardModal = ({isOpen, onClose, title, children, className, classNamePaper}) => {
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={[classes.modal, "dashboard-modal__root", className].join(" ")}
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <React.Fragment>
                <Fade in={isOpen}>
                    <div className={[classes.paper, "dashboard-modal__paper", classNamePaper].join(" ")}>
                    <span className={classes.closeIcon} onClick={onClose}>
                        <CloseIcon width={24} height={24} color={Colors.NEUTRAL['black']}/>
                    </span>
                        {title && (
                            <h3 className={classes.title}>{title}</h3>
                        )}
                        {children}
                    </div>
                </Fade>
            </React.Fragment>
        </Modal>
    );
};

export default DashboardModal;