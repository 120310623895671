import React, {useEffect} from 'react';
import DashboardModal from "../DashboardModal/DashboardModal";
import './index.scss';
import {clearApplication, getApplication} from "../../store/applications/applicationsAction";
import {connect} from "react-redux";
import CommonSpinner from "../kit/CommonSpinner/CommonSpinner";

const ReservationInfoModal = ({reserveInfoId, isOpen, handleClose, getApplication, application, clearApplication}) => {

    useEffect(function () {
        getApplication(reserveInfoId)
        return function () {
            clearApplication()
        }
    }, [])

    if (!application) {
        return <CommonSpinner isLoading={!application} centered/>
    }
    return (
        <DashboardModal isOpen={isOpen} onClose={handleClose}>
            <div className="reservation-info">
                <div className="reservation-info__head">
                    <h6>Специалист</h6>
                    <h4>{application.master_name}</h4>
                </div>
                <div className="reservation-info__body">
                    <div className="row row-head">
                        <p className="service">Услуги</p>
                        <p className="duration">Длительность</p>
                        <p className="price">Цена</p>
                    </div>
                    {application.service_list && application.service_list
                        .sort((a, b) => Number(a.price) - Number(b.price))
                        .map((service, ndx) => (
                            <div className="row">
                                <p className="service">
                                    <span>{ndx + 1}.&nbsp;</span>
                                    {service.name}</p>
                                <p className="duration">{service.time}&nbsp;мин</p>
                                <p className="price">{Number(service.price)}&nbsp;с</p>
                            </div>
                        ))}
                </div>

                <div className="reservation-info__footer">
                    <p className="total-title">Общая сумма</p>
                    <p className="total-sum">
                        {application.service_list.reduce((curr, acc) => curr + Number(acc.price), 0).toLocaleString()}&nbsp;
                        c
                    </p>
                </div>
            </div>
        </DashboardModal>
    );
};

const mapStateToProps = state => ({
    application: state.applications.application
})

const mapDispatchToProps = dispatch => ({
    getApplication: id => dispatch(getApplication(id)),
    clearApplication: () => dispatch(clearApplication())
})

export default connect(mapStateToProps, mapDispatchToProps)(ReservationInfoModal);