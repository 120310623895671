import React from 'react';

import './index.scss'

const Reviews = (props) => {

    console.log('reviews', props)

    return (
        <div>
            Reviews
        </div>
    );

};

export default Reviews;