import React, {useEffect} from 'react';
import Colors from "../../../../global/styles/Colors";
import TimeButton from "../../../../components/kit/TimeButton/TimeButton";
import InputForm from "../../../../components/kit/InputForm/InputForm";
import InputPhoneMask from "../../../../components/kit/InputPhoneMask/InputPhoneMask";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";
import {checkEmptyField} from "../../../../global/Helpers/checkEmptyField";

import '../index.scss';
import ServiceDatePicker from "../../../../components/kit/ServiceDatePicker/ServiceDatePicker";
import {connect} from "react-redux";
import {fetchMasterAvailableTimes} from "../../../../store/core/coreActions";
import {dateFormatter} from "../../../../global/Helpers/helpers";
import CommonSpinner from "../../../../components/kit/CommonSpinner/CommonSpinner";
import LargeCalendarIcon from "../../../../components/kit/Icons/LargeCalendarIcon";
import {isEmptyArray} from "formik";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";

const SelectDate = (
    {
        selectedTime,
        onSelectTime,
        handleClickNext,
        name,
        phone,
        inputChangeHandler,
        onSelectDate,
        selectedDate,
        calendarHandleNextClick,
        calendarHandlePrevClick,
        handleBackNav,
        ...props
    }
) => {
    const disabled = !checkEmptyField(name) ||
        (!checkEmptyField(phone) || phone.length < 17)
        || !checkEmptyField(selectedTime);

    const {fetchMasterAvailableTimes, requested, masterTimes, master, salonId, service} = props

    useEffect(function () {
        const serviceIds = service.map(service => service.id)
        fetchMasterAvailableTimes(salonId, dateFormatter(selectedDate), master.id, serviceIds)
    }, [selectedDate])

    return (
        <div className="select-date-root">
            <h3 className="select-date-root__title" style={{color: Colors.NEUTRAL['black']}}>
               <span className="record-nav-back-icon">
                   <ArrowLargeIcon onClick={handleBackNav}/>
               </span>
                <span>Выбрать дату</span>
                <span className="empty"/>
            </h3>
            <div className="select-date-root__date">
                <ServiceDatePicker
                    onChange={onSelectDate}
                    selectedDate={selectedDate}
                    calendarHandleNextClick={calendarHandleNextClick}
                    calendarHandlePrevClick={calendarHandlePrevClick}/>
            </div>

            {requested && <CommonSpinner isLoading={requested} centered/>}

            {!requested && masterTimes && isEmptyArray(masterTimes.result) && (
                <div className="select-date-root__not-found">
                    <LargeCalendarIcon/>
                    <h3>На данную дату,</h3>
                    <h4>нет свободных специалистов</h4>
                    <p>Выберите другую дату</p>
                </div>
            )}

            {!requested && masterTimes && !isEmptyArray(masterTimes.result) && (
                <div className="select-date-root__time" style={{backgroundColor: Colors.NEUTRAL['light_gray']}}>
                    <div className="select-date-root__select-time">
                        <h4 className="select-date-root__select-time-text" style={{color: Colors.NEUTRAL['black']}}>
                            Выбрать время
                        </h4>
                        <div className="select-date-root__times-btns">
                            {masterTimes.result.map((item, ndx) => (
                                <TimeButton
                                    key={ndx}
                                    time={item}
                                    selected={item === selectedTime}
                                    onClick={onSelectTime}/>
                            ))}
                        </div>
                        <h4 className="select-date-root__select-time-text" style={{color: Colors.NEUTRAL['black']}}>
                            Ваши контакты
                        </h4>
                        <form className="select-date-root__form">
                            <InputForm
                                className="select-date-root__name-field"
                                type="text"
                                value={name}
                                propertyName="name"
                                onChange={inputChangeHandler}
                                label="Имя"/>
                            <InputPhoneMask
                                className="select-date-root__phone-field"
                                value={phone}
                                propertyName="phone"
                                onChange={inputChangeHandler}
                            />
                        </form>
                        <GeneralButton
                            bgColor={disabled ? Colors.NEUTRAL['border'] : Colors.SEMANTIC['pink']}
                            className="service-variant-root__btn"
                            title="Далее"
                            disabled={disabled}
                            textColor={disabled ? Colors.TEXT['text_color'] : Colors.NEUTRAL['white']}
                            onClick={handleClickNext}/>
                    </div>
                </div>
            )}

        </div>
    );
};

const mapStateToProps = state => ({
    requested: state.core.masterTimesRequested,
    masterTimes: state.core.masterTimes
})

const mapDispatchToProps = dispatch => ({
    fetchMasterAvailableTimes: (salonId, date, masterId, service) =>
        dispatch(fetchMasterAvailableTimes(salonId, date, masterId, service))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectDate);