import React from 'react';

const ArrowIcon = ({width, height, color, className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 7 12"
             fill={color}>
            <path
                d="M5.45964 5.99998L0.479862 1.0202C0.2846 0.824938 0.2846 0.508355 0.479862 0.313093C0.675124 0.117831 0.991706 0.117831 1.18697 0.313093L6.5203 5.64643C6.71556 5.84169 6.71556 6.15827 6.5203 6.35353L1.18697 11.6869C0.991705 11.8821 0.675123 11.8821 0.479861 11.6869C0.284599 11.4916 0.284599 11.175 0.479861 10.9798L5.45964 5.99998Z"
                fill={color}/>
        </svg>
    );
};

export default ArrowIcon;