import {FETCH_CATEGORIES_GET_SUCCESS, FETCH_CATEGORIES_REQUEST} from "./categoriesActions";

const initialState = {
    requested: false,
    categories: null
};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
            return {...state, requested: true}
        case FETCH_CATEGORIES_GET_SUCCESS:
            return {...state, categories: action.categories, requested: false}
        default:
            return state;
    }
};

export default categoriesReducer;
