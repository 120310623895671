import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OnlineRecord from "./containers/Pages/OnlineRecord/OnlineRecord";
import {
  ROLE_ADMIN,
  ROLE_OWN,
  ROUTE_CALENDAR,
  ROUTE_CALENDAR_WITH_PARAMS,
  ROUTE_CATEGORIES,
  ROUTE_FINANCE,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_NOTIFICATIONS,
  ROUTE_RECORD,
  ROUTE_SERVICES,
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_ADD,
  ROUTE_SETTINGS_ROLE,
  ROUTE_USERS,
  MERCHANTS,
  RECORDS,
  NEW_ENTRY,
  RECORDS_SERVICES,
  ALL_MASTER,
  MAIN_PAGE,
  ABOUT_SALON,
  REVIEWS,
  FILTER_RESULT,
  RECORDS_SERVICES_DEFAULT,
  RECORDS_SERVICES_DEFAULT_ID,
  SEARCH_RESULT,
} from "./global/Constants/Constants";
import { LOGIN } from "./global/Helpers/Links";
import DashBoard from "./containers/Dashboard/DashBoard";
import Login from "./containers/Pages/Login/Login";
import MainPage from "./containers/Pages/MainPage/MainPage";
import Records from "./containers/Pages/Records/Records";
import NewEntry from "./containers/Pages/NewEntry/NewEntry";
import ListServicesMain from "./containers/Pages/ListServicesMain/ListServicesMain";
import ListSalonsPage from "./containers/Pages/ListSalonsPage/ListSalonsPage";
import ListMasterPage from "./containers/Pages/ListMasterPage/ListMasterPage";
import About from "./containers/Pages/About/About";
import Reviews from "./containers/Pages/Reviews/Reviews";
import ResultFilter from "./containers/Pages/FilterPage/ResultFilter/ResultFilter";
import SearchPage from "./containers/Pages/FilterPage/SearchPage/SearchPage";

const ProtectedRoute = ({ isAllowed, ...props }) => {
  return isAllowed ? <Route {...props} /> : <Redirect to={LOGIN} />;
};

const Routes = ({ user }) => {
  const isAllowed =
    user && (user.user_type === ROLE_OWN || user.user_type === ROLE_ADMIN);

  return (
    <Switch>
      {[
        ROUTE_HOME,
        ROUTE_CATEGORIES,
        ROUTE_CALENDAR,
        ROUTE_NOTIFICATIONS,
        ROUTE_USERS,
        ROUTE_CALENDAR_WITH_PARAMS,
        ROUTE_FINANCE,
        ROUTE_SERVICES,
        ROUTE_SETTINGS_ADD,
        `${ROUTE_SETTINGS}:salonId`,
        `${ROUTE_SETTINGS_ROLE}:salonId`,
      ].map((path) => (
        <ProtectedRoute
          isAllowed={isAllowed}
          key={path}
          path={path}
          exact
          component={DashBoard}
        />
      ))}

      <Route
        path={RECORDS_SERVICES_DEFAULT_ID}
        exact
        component={ListServicesMain}
      />
      <Route path={RECORDS_SERVICES_DEFAULT} component={ListServicesMain} />
      <Route path={RECORDS_SERVICES} exact component={ListServicesMain} />

      {/*For Bank*/}
      <Route path={MAIN_PAGE} exact component={MainPage} />
      <Route path={RECORDS} exact component={Records} />
      <Route path={NEW_ENTRY} exact component={NewEntry} />
      <Route path={ALL_MASTER} exact component={ListMasterPage} />
      <Route path={ROUTE_RECORD} exact component={OnlineRecord} />
      <Route path={ROUTE_LOGIN} exact component={Login} />
      <Route path={MERCHANTS} exact component={ListSalonsPage} />
      <Route path={ABOUT_SALON} exact component={About} />
      <Route path={REVIEWS} exact component={Reviews} />
      <Route path={FILTER_RESULT} exact component={ResultFilter} />
      <Route path={SEARCH_RESULT} exact component={SearchPage} />
      {/*TODO record users*/}
      {/*<Route path={RECORDS_DEFAULT} exact component={Records}/>*/}

      <Route
        component={() => (
          <div style={{ padding: "30px 100px" }}>403 Forbidden</div>
        )}
      />
    </Switch>
  );
};

export default Routes;
