import axios from '../../Api/axios-api'
import {ROUTE_MASTER_PAGE} from '../../Api/Routes'

export const FETCH_GET_MASTER_DETAIL_REQUEST = 'FETCH_GET_MASTER_DETAIL_REQUEST'
export const FETCH_GET_MASTER_DETAIL_SUCCESS = 'FETCH_GET_MASTER_DETAIL_SUCCESS'

const fetchGetSalonDetailRequest = () => ({type: FETCH_GET_MASTER_DETAIL_REQUEST})
const fetchGetSalonDetailSuccess = data => ({type: FETCH_GET_MASTER_DETAIL_SUCCESS, value: data})

export const getMasterDetail = (id) => {
    return dispatch => {
        dispatch(fetchGetSalonDetailRequest())
        return axios.get(ROUTE_MASTER_PAGE + id)
            .then(response => {
                dispatch(fetchGetSalonDetailSuccess(response.data))
            })
    }
}