import React, { useEffect, useState } from 'react';
import { Formik } from "formik";
import InputForm from "../../../components/kit/InputForm/InputForm";
import InputPhoneMask from "../../../components/kit/InputPhoneMask/InputPhoneMask";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";
import Colors from "../../../global/styles/Colors";
import DashboardModal from "../../../components/DashboardModal/DashboardModal";
import MultiSelectForm from "../../../components/kit/MultiSelectForm/MultiSelectForm";
import { Scrollbars } from 'react-custom-scrollbars';
import { ROLE_ADMIN, ROLE_MASTER, ROLE_OWN } from "../../../global/Constants/Constants";
import { fetchCategoriesGet, fetchChangeDate, fetchDateGet, fetchServicesGet } from "../../../store/core/coreActions";
import { connect } from "react-redux";
import IdeaIcon from "../../../components/kit/Icons/IdeaIcon";
import CustomSelect from "../../../components/kit/CustomSelect/CustomSelect";
import CameraIcon from "../../../components/kit/Icons/CameraIcon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import './CreateSpecialistModal.scss'
import Schedule from "./Schedule";

const SYSTEM_ROLES = [
  {
    label: "Специалист",
    id: ROLE_MASTER
  },
  {
    label: "Администратор",
    id: ROLE_ADMIN
  },
  {
    label: "Владелец",
    id: ROLE_OWN
  }
]

const TABS = [{tab: 0, name: 'Информация'}, {tab: 1, name: 'Время'}]

const CreateSpecialistModal = (
  {
    initValues,
    days,
    isOpen,
    onClose,
    onSubmitNewSpecialist,

    salons,

    salonId,

    fetchCategoriesGet,
    categories,
    fetchChangeDate,
    fetchServicesGet,
    services,
    fetchDateGet,
    employeePosition

  }
) => {
  const [state, setState] = useState({
    name: initValues ? initValues.name : '',
    surname: initValues ? initValues.surname : '',
    position: initValues ? initValues.position : employeePosition ? employeePosition : '',
    category: (initValues && initValues.service_list.map(it => ({
      id: it.category,
      label: it.category_name
    })).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)) || [],
    service: (initValues && initValues.service_list) ? initValues.service_list.map(it => ({
      ...it,
      label: it.name
    })) : [],
    salon: initValues ? initValues.salon[0] : salonId ? salonId : '',
    number: initValues ? initValues.number : '',
    password: '',
    email: initValues ? initValues.email : '',
    image: ""
  });

  const [tab, setTab] = useState(TABS[0].tab)
  useEffect(() => {
    if (initValues && initValues.salon && initValues.salon[0]) {
      fetchCategoriesGet(initValues.salon[0]);
      fetchDateGet(initValues.id, salonId)
    }
    !!salonId && fetchCategoriesGet(salonId);

  }, [])

  function validateForm(values) {
    const error = {};
    if (!values.salon || values.salon === "*") {
      error.salon = "Обязательное поле"
    }
    if (!values.position || values.position === "*") {
      error.position = "Обязательное поле"
    }
    if (values.position === ROLE_MASTER) {
      if (!values.service.length) {
        error.service = "Обязательное поле"
      }
    }

    if (!values.number) {
      error.number = "Обязательное поле"
    } else if (values.number.length < 17) {
      error.number = "Некорректный номер"
    }
    if (!values.name) {
      error.name = "Обязательное поле"
    }
    // if (!values.surname) {
    //     error.surname = "Обязательное поле"
    // }
    if (!initValues) {
      if (!values.password) {
        error.password = "Обязательное поле"
      }
    }
    if (!!values.password && values.password.length < 8) {
      error.password = "Пароль должен содержать минимум 8 символов"
    }
    // if (!values.email) {
    //     error.email = 'Обязательное поле';
    // }
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      error.email = 'Некорректный адрес почты';
    }
    return error
  }

  const selectCategoryHandler = (setFieldValue) => (event) => {
    const ids = event ? [...event].map(item => item.id) : []
    setFieldValue('category', !!event ? event : [])
    setFieldValue('service', [])
    if (ids.length) {
      fetchServicesGet(ids.join(","), state.salon)
    }
  };

  const selectSalonHandler = setFieldValue => (event) => {
    setFieldValue('salon', event.target.value)
    setFieldValue('category', [])
    setFieldValue('service', [])
    fetchCategoriesGet(event.target.value);
    setState(prevState => ({...prevState, salon: event.target.value}))
  };

  const selectServiceHandler = setFieldValue => event => {
    const ids = Array.isArray(event) && event.map(item => item.id)
    setFieldValue('service', ids.length ? event : "")
  }

  const generatePassword = setFieldValue => event => {
    const randomPassword = Math.random().toString(36).substring(2);
    setFieldValue('password', randomPassword)
  }

  const fileHandler = setFieldValue => e => {
    e.persist();
    if (e.target.files[0]) {
      setFieldValue('image', e.target.files[0])
    }
  }

  const onSubmit = values => {
    onSubmitNewSpecialist(values)
  }

  const onChangeDate = (data) => {
    fetchChangeDate(initValues.id, salonId, {days: data})
    onClose()
  }

  return (
    <DashboardModal
      isOpen={ isOpen }
      onClose={ onClose }
      title={ initValues ? `${ initValues.surname } ${ initValues.name }` : "Добавить специалиста" }
    >
      <Scrollbars style={ {width: 500, height: 530} }>

        {(initValues && ROLE_OWN !== initValues.position) &&
          <ButtonGroup className="tabs">
            { TABS.map((item, ndx) => (
              <Button
                key={ ndx }
                onClick={ () => setTab(item.tab) }
                className={ `${ item.tab === tab ? "active" : "tab" }` }
              >
                { item.name }
              </Button>
            )) }
          </ButtonGroup>
        }

        {
          tab === TABS[0].tab ?
            <Formik
              initialValues={ state }
              validate={ values => validateForm(values) }
              onSubmit={ onSubmit }
            >
              {
                ({
                   values,
                   errors,
                   touched,
                   handleChange,
                   dirty,
                   isValid,
                   handleSubmit,
                   setFieldValue
                 }) => (
                  <form className="dashboard-form-staffs" onSubmit={ handleSubmit }>
                    <div className="dashboard-form-staffs__avatar">
                      <label htmlFor="upload-staff-image">
                        { !!values.image && (
                          <img src={ URL.createObjectURL(values.image) } alt="#"/>
                        ) }
                        { !values.image && initValues && initValues.image && (
                          <img src={ initValues.image } alt="#"/>
                        ) }
                        { !values.image && (!initValues || (initValues && !initValues.image)) && (
                          <div className="circle">
                            <CameraIcon width={ 24 } height={ 24 } color="#0099FF"/>
                          </div>
                        ) }
                        <input
                          accept=".png, .jpg, .jpeg"
                          id="upload-staff-image"
                          type="file"
                          value=""
                          onChange={ fileHandler(setFieldValue) }/>
                        <p>Загрузить фото</p>
                      </label>
                    </div>
                    <div className="dashboard-form-staffs__text-field-names-block --mr-25">
                      <InputForm
                        onChange={ handleChange }
                        value={ values.name }
                        propertyName="name"
                        label="Имя"
                        type="text"
                        className="dashboard-form-staffs__text-field"/>
                      <span className="field-error field-error-by-staff">
                        { errors.name && touched.name && errors.name }
                      </span>
                    </div>
                    <div className="dashboard-form-staffs__text-field-names-block">
                      <InputForm
                        onChange={ handleChange }
                        value={ values.surname }
                        propertyName="surname"
                        label="Фамилия"
                        type="text"
                        className="dashboard-form-staffs__text-field"/>
                      <span className="field-error field-error-by-staff">
                        { errors.surname && touched.surname && errors.surname &&
                          errors.surname }
                      </span>
                    </div>
                    <CustomSelect
                      handleChange={ selectSalonHandler(setFieldValue) }
                      options={ salons }
                      propertyName="salon"
                      placeholder="Салон"
                      className="dashboard-form-staffs__text-field"
                      value={ values.salon }
                    />
                    {
                      errors.salon && touched.salon && errors.salon &&
                      <span className="field-error">{ errors.salon }</span>
                    }

                    <CustomSelect
                      handleChange={ handleChange }
                      options={ SYSTEM_ROLES }
                      propertyName="position"
                      className="dashboard-form-staffs__text-field"
                      value={ values.position }
                      placeholder="Роль"
                    />
                    {
                      errors.position && touched.position && errors.position &&
                      <span className="field-error">{ errors.position }</span>
                    }

                    { values.position === ROLE_MASTER && (
                      <>
                        <MultiSelectForm
                          onChange={ selectCategoryHandler(setFieldValue) }
                          options={ categories ? categories.map(it => ({
                            id: it.id,
                            label: it.name
                          })).filter(it => !values.category.some(item => item.id === it.id)) : [] }
                          value={ values.category }
                          placeholder="Выбрать категорию"
                          propertyName="category"
                        />

                        <MultiSelectForm
                          onChange={ selectServiceHandler(setFieldValue) }
                          options={ services }
                          value={ values.service }
                          placeholder="Выбрать услугу"
                          propertyName="service"
                        />

                        <span className="field-error field-error-by-staff">
                            {
                              errors.service && touched.service && errors.service &&
                              errors.service
                            }
                        </span>
                      </>
                    ) }
                    <InputPhoneMask
                      className="dashboard-form-staffs__text-field-phone"
                      value={ values.number }
                      propertyName="number"
                      onChange={ handleChange }
                    />
                    { errors.number && touched.number && errors.number &&
                      <span className="field-error">{ errors.number }</span>
                    }
                    <InputForm
                      onChange={ handleChange }
                      value={ values.email }
                      propertyName="email"
                      label="E-mail"
                      type="email"
                      className="dashboard-form-staffs__text-field"/>
                    { errors.email && touched.email && errors.email &&
                      <span className="field-error">{ errors.email }</span>
                    }
                    <InputForm
                      onChange={ handleChange }
                      value={ values.password }
                      propertyName="password"
                      label="Пароль"
                      type="text"
                      className="dashboard-form-staffs__text-field"/>
                    { errors.password && touched.password && errors.password &&
                      <span className="field-error">{ errors.password }</span>
                    }
                    <p onClick={ generatePassword(setFieldValue) }
                       className="dashboard-form-staffs__password-generate">
                      <IdeaIcon/> <span>Сгенерировать пароль</span>
                    </p>
                    <GeneralButton
                      type="submit"
                      title={ initValues ? "Изменить" : "Добавить" }
                      disabled={ !dirty }
                      className="dashboard-form-staffs__button"
                      bgColor={ dirty ? Colors.SEMANTIC['green'] : Colors.NEUTRAL['border'] }
                      textColor={ Colors.NEUTRAL['white'] }/>
                  </form>
                )
              }
            </Formik>
            :
            <Schedule days={ days } onChangeDate={ onChangeDate }/>
        }

      </Scrollbars>
    </DashboardModal>
  );
};

const mapStateToProps = state => ({
  salons: state.core.salons,
  salon: state.core.salon,
  categories: state.core.categories,
  services: state.core.services,
  days: state.core.days,
})

const mapDispatchToProps = dispatch => ({
  fetchCategoriesGet: (salonID) => dispatch(fetchCategoriesGet(salonID)),
  fetchServicesGet: (category, salonId) => dispatch(fetchServicesGet(category, salonId)),
  fetchDateGet: (employmentId, salonId) => dispatch(fetchDateGet(employmentId, salonId)),
  fetchChangeDate: (employmentId, salodId, data) => dispatch(fetchChangeDate(employmentId, salodId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateSpecialistModal);