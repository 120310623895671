import axios from '../../Api/axios-api'
import {MAIN_PAGE} from "../../Api/Routes";

export const FETCH_MAIN_PAGE_REQUEST = 'FETCH_MAIN_PAGE_REQUEST'
export const FETCH_MAIN_PAGE_SUCCESS = 'FETCH_MAIN_PAGE_SUCCESS'

const fetchMainPageRequest = () => ({type: FETCH_MAIN_PAGE_REQUEST})
const fetchMainPageSuccess = data => ({type: FETCH_MAIN_PAGE_SUCCESS, value: data})

export const getMainPage = () => {
    return dispatch => {
        dispatch(fetchMainPageRequest())
        return axios.get(MAIN_PAGE)
            .then(response => {
                dispatch(fetchMainPageSuccess(response.data))
            })
    }
}