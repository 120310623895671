export const LINK_MERCHANTS_DEFAULT = '/merchants/default';
export const LINK_RECORDS_DEFAULT = '/records/default/';
export const LINK_NEW_ENTRY_DEFAULT = '/new_entry/default'
export const LINK_RECORDS_SERVICES_DEFAULT = '/records_services/default'
export const LINK_ALL_MASTER_DEFAULT = '/all_master/default/'
export const LINK_MAIN_PAGE_DEFAULT = '/main/default'
export const LINK_FILTER_RESULT_DEFAULT = '/filter_result/default'
export const LINK_ROUTE_RECORD_DEFAULT = '/record/default';
export const LINK_ABOUT_SALON_DEFAULT = '/about/default/salon/'
export const LINK_ABOUT_MASTER_DEFAULT = '/about/default/master/'
export const LINK_REVIEWS_DEFAULT = '/reviews/default'
export const LINK_MAIN_DEFAULT = '/main/default'

export const LINK_MERCHANTS = '/merchants';
export const LINK_RECORDS = '/records/';
export const LINK_NEW_ENTRY = '/new_entry'
export const LINK_RECORDS_SERVICES = '/records_services'
export const LINK_ALL_MASTER = '/all_master'
export const LINK_MAIN_PAGE = '/main'
export const LINK_FILTER_RESULT = '/filter_result'
export const LINK_ROUTE_RECORD = '/record';
export const LINK_ABOUT_SALON = '/about/salon/'
export const LINK_ABOUT_MASTER = '/about/master/'
export const LINK_REVIEWS = '/reviews/'
export const LINK_MAIN = '/main/'

export const LOGIN = '/login'