import axios from "../../Api/axios-api";
import {ROUTE_CATEGORIES_PAGE} from "../../Api/Routes";

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'

const fetchCategoriesRequest = () => ({type: FETCH_CATEGORIES_REQUEST})
const fetchCategoriesSuccess = data => ({type: FETCH_CATEGORIES_SUCCESS, value: data})

export const getCategories = () => {
    return dispatch => {
        dispatch(fetchCategoriesRequest())
        return axios.get(ROUTE_CATEGORIES_PAGE)
            .then(response => {
                dispatch(fetchCategoriesSuccess(response.data))
            })
    }
}
