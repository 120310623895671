import {
    FETCH_GET_LIST_MASTERS_SERVICES_REQUEST, FETCH_GET_LIST_MASTERS_SERVICES_SUCCESS,
    FETCH_GET_LIST_SALONS_SERVICES_REQUEST, FETCH_GET_LIST_SALONS_SERVICES_SUCCESS,
    FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_REQUEST, FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_SUCCESS,
    FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_REQUEST, FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_SUCCESS
} from "./listMastersSalonsServicesAction";

const initialState = {
    listMaster: {
        nextPage: null,
        arrayMaster: [],
        response: false,
        title: null
    },
    listSalons: {
        nextPage: null,
        arraySalons: [],
        response: false,
        title: null
    },
    response: true
}

export const listMastersSalonsReducer = (state = initialState, action) => {
    let listData = {}
    switch (action.type){
        case FETCH_GET_LIST_MASTERS_SERVICES_REQUEST:
            listData.response = true
            return {...state, listMaster: listData}
        case FETCH_GET_LIST_MASTERS_SERVICES_SUCCESS:
            listData.response = false
            listData.arrayMaster = action.value.results
            listData.nextPage = action.value.next
            listData.title = action.value.main_cat.name
            return {...state, listMaster: listData, response: false}

        case FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_REQUEST:
            listData = {...state.listMaster}
            listData.response = true
            return { ...state, listMaster: listData}
        case FETCH_PAGE_PAGINATION_OF_MASTER_SERVICES_SUCCESS:
            listData = {...state.listMaster}
            listData.response = false
            listData.nextPage = action.value.next
            const newArrayMaster = state.listMaster.arrayMaster.concat(action.value.results)
            listData.arrayMaster = newArrayMaster
            return {...state, listMaster: listData}

        case FETCH_GET_LIST_SALONS_SERVICES_REQUEST:
            listData.response = true
            return {...state, listSalons: listData}
        case FETCH_GET_LIST_SALONS_SERVICES_SUCCESS:
            listData.response = false
            listData.arraySalons = action.value.results
            listData.nextPage = action.value.next
            listData.title = action.value.main_cat.name
            return {...state, listSalons: listData, response: false}

        case FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_REQUEST:
            listData = {...state.listSalons}
            listData.response = true
            return { ...state, listSalons: listData}
        case FETCH_PAGE_PAGINATION_OF_SALONS_SERVICES_SUCCESS:
            listData = {...state.listSalons}
            listData.response = false
            listData.nextPage = action.value.next
            const newArraySalon = state.listSalons.arraySalons.concat(action.value.results)
            listData.arraySalons = newArraySalon
            return {...state, listSalons: listData}

        default: return state
    }
}
