import {
    FETCH_MESSAGE_GET_SUCCESS,
    FETCH_MESSAGE_REQUEST,
    FETCH_PAYBOX_GET_SUCCESS,
    FETCH_PAYBOX_REQUEST,
    FETCH_REPORTS_GET,
    FETCH_REPORTS_REQUEST
} from "./financeAction";

const initialState = {
    reports: null,
    requested: false,
    paybox: null,
    messages: null,
};

const financeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REPORTS_REQUEST:
            return {...state, requested: true}
        case FETCH_REPORTS_GET:
            return {...state, reports: action.reports, requested: false}

        case FETCH_PAYBOX_REQUEST:
            return {...state, requested: true}
        case FETCH_PAYBOX_GET_SUCCESS:
            return {...state, paybox: action.paybox, requested: false}
        case FETCH_MESSAGE_REQUEST:
            return {...state, requested: true}
        case FETCH_MESSAGE_GET_SUCCESS:
            return {...state, messages: action.messages, requested: false}
        default:
            return state;
    }
};

export default financeReducer;
