import React from 'react';
import Colors from "../../../global/styles/Colors";
import DotsIcon from "../../../components/kit/Icons/DotsIcon";
import ReactTooltip from "react-tooltip";
import PencilIcon from "../../../components/kit/Icons/PencilIcon";
import TrashIcon from "../../../components/kit/Icons/TrashIcon";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {ROLES} from "../../../global/Constants/Constants";

const StaffElement = ({data, onRemove, onEdit}) => {
    function remove() {
        onRemove(data.id)
    }

    function edit() {
        onEdit(data)
    }

    return (
        <TableRow>
            <TableCell align="left">
                <p className="dashboard-staffs-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                <span style={{backgroundColor: ROLES[data.position].color || Colors.SEMANTIC['green']}}
                      className="dashboard-staffs-root__item-dot"/>
                    {data.name}&nbsp;{data.surname}&nbsp;{data.middle_name}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-staffs-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.number}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-staffs-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {ROLES[data.position] && ROLES[data.position].name}
                </p>
            </TableCell>
            <TableCell align="left">
                <p className="dashboard-staffs-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                    {data.salon_name && data.salon_name.join(", ")}
                </p>
            </TableCell>
            <TableCell align="left">
                <div className="dashboard-staffs-root__item-title" style={{color: Colors.NEUTRAL['black']}}>
                            <span data-tip data-for={`staff-tooltip-${data.id}`} data-event='click focus'>
                            <DotsIcon width={24} height={24} color={Colors.NEUTRAL['black']}/>
                            <ReactTooltip id={`staff-tooltip-${data.id}`}
                                          place="bottom" globalEventOff='click' clickable={true}
                                          effect="solid" isCapture={true}>
                                <div className="dashboard-staffs-root__tooltip">
                                    <button
                                        className="dashboard-staffs-root__tooltip-element"
                                        onClick={edit}>
                                        <PencilIcon width={14} height={16} color={Colors.NEUTRAL['white']}
                                                    className="dashboard-staffs-root__tooltip-element--edit"/>
                                        Редактировать</button>
                                    <button
                                        className="dashboard-staffs-root__tooltip-element"
                                        onClick={remove}>
                                        <TrashIcon width={13} height={14} color={Colors.NEUTRAL['white']}
                                                   className="dashboard-staffs-root__tooltip-element--remove"/>
                                        Удалить
                                    </button>
                                </div>
                            </ReactTooltip>
                            </span>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default StaffElement;