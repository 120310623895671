import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import Arrow from "../../../../components/kit/Arrow/Arrow";
import SelectionButtons from "../../../../components/kit/SelectionButtons/SelectionButtons";
import AboutTitle from "../../../../components/kit/About/AboutTitle/AboutTitle";
import AboutContact from "../../../../components/kit/About/AboutContact/AboutContact";
import Description from "../../../../components/kit/About/Description/Description";
import Reviews from "../../../../components/kit/About/Reviews/Reviews";
import Services from "../../../../components/kit/About/Services/Services";
import LoadingComponent from "../../OnlineRecord/LoadingComponent/LoadingComponent";
import {getSalonDetail} from '../../../../store/Main/detailedSalon/detailedSalonAction'
import {NavLink} from "react-router-dom";

import {
    LINK_MERCHANTS_DEFAULT,
    LINK_MERCHANTS,
    LINK_ROUTE_RECORD_DEFAULT,
    LINK_ROUTE_RECORD,
} from "../../../../global/Helpers/Links";

import './index.scss'

const AboutSalon = ({
    getSalonDetail,
    paramsId,
    detailSalon,
    history,
    defaultCheck
    }) => {

    const [btnActive, setActive] = useState(0)
    const {response, salonDetail} = detailSalon

    useEffect(() => {
        getSalonDetail(paramsId)
    }, [])

    if(response) return <LoadingComponent />

    return (
        <div className="container">
            <div className="online-record-root">

               <Arrow
                   to={defaultCheck ? LINK_MERCHANTS_DEFAULT : LINK_MERCHANTS}
                   title="О салоне"
                   history={history}
               />

                <SelectionButtons
                    titles={['О салоне', 'Услуги']}
                    btnActive={btnActive}
                    setActive={setActive}
                />

                {!btnActive ?
                    <>
                        <AboutTitle
                            img={salonDetail?.logo}
                            title={salonDetail?.name}
                            rating={salonDetail?.rating}
                            reviews={salonDetail?.reviews}
                        />

                        <AboutContact
                            number={salonDetail?.phone}
                            gmail={salonDetail?.email}
                            location={salonDetail?.address}
                        />

                        <Description
                            title="О салоне"
                            text={salonDetail?.description}
                        />

                        <Reviews
                            count={salonDetail?.reviews?.length}
                        />

                        <div className="btn-wrapper">
                            <NavLink
                                to={defaultCheck ? LINK_ROUTE_RECORD_DEFAULT + '/' + paramsId :
                                    LINK_ROUTE_RECORD + '/' + paramsId}
                            >
                                Записатся
                            </NavLink>
                        </div>
                    </>

                    :

                    <Services
                        services={salonDetail?.services}
                    />
                }

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSalonDetail: (id) => {dispatch(getSalonDetail(id))}
})

export default connect(mapStateToProps, mapDispatchToProps)(AboutSalon);