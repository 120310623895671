import React from 'react';

import {NavLink} from "react-router-dom";

import './index.scss'

const Reviews = ({count}) => {
    return (
        <NavLink
            to="/reviews/salon/12"
        >
            <div className="reviews-block information-salon" >
                <h4>Отзывы</h4>

                <span>{count}</span>
            </div>
        </NavLink>
    );
};

export default Reviews;