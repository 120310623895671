import React, { useCallback, useEffect, useState } from 'react';
import CustomSwitch from "../../../components/kit/Switch/CustomSwitch";
import './index.scss';
import SelectForm from "../../../components/kit/SelectForm/SelectForm";
import { fetchSalonTimes, fetchSaveSalonTimes } from "../../../store/core/coreActions";
import { connect } from "react-redux";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import Button from "@material-ui/core/Button";
import { generateTimeOptions } from "../../../global/Helpers/helpers";


const WorkTime = (
    {
        fetchSalonTimes,
        salonTimes,
        fetchSaveSalonTimes,
        ...props
    }
) => {

    const [isActive, toggleActive] = useState(false);

    const [state, setState] = useState([])

    useEffect(function () {
        fetchSalonTimes(props.match.params.salonId)
    }, [props.match.params.salonId])

    useEffect(function () {
        if (salonTimes) {
            const days = salonTimes.days && [...salonTimes.days].sort((a, b) => a.queue - b.queue)
            setState(days)
        }
    }, [salonTimes])

    const handleTimeChange = useCallback((e, ndx) => {
        const values = [...state]
        const timeValue = moment(e.target.value, 'HH:mm:ss')

        if (e.target.name === "start_time") {
            if (values[ndx].finish_time) {
                const timeEnd = moment(values[ndx].finish_time, 'HH:mm:ss')
                if (timeValue.isBefore(timeEnd)) {
                    values[ndx].start_time = e.target.value
                } else {
                    NotificationManager.info('Некорректное время')
                }
            } else {
                values[ndx].start_time = e.target.value
            }

        } else {
            if (values[ndx].start_time) {
                const timeStart = moment(values[ndx].start_time, 'HH:mm:ss')
                if (timeStart.isBefore(timeValue)) {
                    values[ndx].finish_time = e.target.value
                } else {
                    NotificationManager.info('Некорректное время')
                }
            } else {
                values[ndx].finish_time = e.target.value
            }

        }
        setState(values)
        toggleActive(true)
    }, [state])

    const handleDinnerTimeChange = useCallback((e, ndx) => {
        const values = [...state]
        const timeValue = moment(e.target.value, 'HH:mm:ss')

        if (e.target.name === "dinner_start_time") {

            if (values[ndx].dinner_finish_time) {
                const timeEnd = moment(values[ndx].dinner_finish_time, 'HH:mm:ss')
                if (timeValue.isBefore(timeEnd)) {
                    values[ndx].dinner_start_time = e.target.value
                } else {
                    NotificationManager.info('Некорректное время')
                }
            } else {
                values[ndx].dinner_start_time = e.target.value
            }


        } else {
            if (values[ndx].dinner_start_time) {
                const timeStart = moment(values[ndx].dinner_start_time, 'HH:mm:ss')
                if (timeStart.isBefore(timeValue)) {
                    values[ndx].dinner_finish_time = e.target.value
                } else {
                    NotificationManager.info('Некорректное время')
                }
            } else {
                values[ndx].dinner_finish_time = e.target.value
            }

        }
        setState(values)
        toggleActive(true)
    }, [state])

    const handleSwitchChange = useCallback(ndx => {
        const values = [...state];
        values[ndx].is_work = !values[ndx].is_work;
        setState(values)
        toggleActive(true)
    }, [state])

    const handleDinnerSwitchChange = useCallback(ndx => {
        const values = [...state];
        if (!!values[ndx].dinner_start_time) {
            values[ndx].dinner_start_time = null
            values[ndx].dinner_finish_time = null
        } else {
            values[ndx].dinner_start_time = "13:00:00"
            values[ndx].dinner_finish_time = "14:00:00"
        }

        setState(values)
        toggleActive(true)

    }, [state])


    const onSubmit = useCallback(() => {
        const data = {
            days: state
        }
        fetchSaveSalonTimes(props.match.params.salonId, data)
        toggleActive(false)
    }, [fetchSaveSalonTimes, props.match.params.salonId, state])

    if (!state.length) {
        return <CommonSpinner isLoading={!state.length} centered />
    }

    return (
        <div className="settings-root__work-time">
            <h3 className="settings-root__work-time-title">
                Рабочие часы
            </h3>

            <div className="settings-root__work-time-days">
                {salonTimes && state.length && state.sort((a, b) => a.queue - b.queue)
                    .map((day, ndx) => (
                        <div key={day.id} className="day-item">
                            <span className="week-name">{day.day_name}</span>
                            <CustomSwitch
                                className="switch-day"
                                checked={day.is_work}
                                handleChange={() => handleSwitchChange(ndx)}
                            />
                            <div className="select">
                                <span>От</span>
                                <SelectForm
                                    options={generateTimeOptions()}
                                    propertyName="start_time"
                                    handleChange={e => handleTimeChange(e, ndx)}
                                    value={state[ndx].start_time || ""}
                                    disabled={!day.is_work}
                                />
                            </div>
                            <div className="select">
                                <span>До</span>
                                <SelectForm
                                    options={generateTimeOptions()}
                                    propertyName="finish_time"
                                    handleChange={e => handleTimeChange(e, ndx)}
                                    value={state[ndx].finish_time || ""}
                                    disabled={!day.is_work}
                                />
                            </div>
                        </div>
                    ))}
            </div>

            <h3 className="settings-root__work-time-title">
                Обеденное время
            </h3>

            <div className="settings-root__work-time-days">
                {salonTimes && state.length && state.sort((a, b) => a.queue - b.queue)
                    .map((day, ndx) => (
                        <div key={day.id} className="day-item">
                            <span className="week-name">{day.day_name}</span>
                            <CustomSwitch
                                className="switch-day"
                                checked={!!day.dinner_start_time && !!day.dinner_finish_time}
                                handleChange={() => handleDinnerSwitchChange(ndx)}
                            />
                            <div className="select">
                                <span>От</span>
                                <SelectForm
                                    options={generateTimeOptions()}
                                    propertyName="dinner_start_time"
                                    handleChange={e => handleDinnerTimeChange(e, ndx)}
                                    value={state[ndx].dinner_start_time || ""}
                                    disabled={!day.dinner_start_time && !day.dinner_finish_time}
                                />
                            </div>
                            <div className="select">
                                <span>До</span>
                                <SelectForm
                                    options={generateTimeOptions()}
                                    propertyName="dinner_finish_time"
                                    handleChange={e => handleDinnerTimeChange(e, ndx)}
                                    value={state[ndx].dinner_finish_time || ""}
                                    disabled={!day.dinner_start_time && !day.dinner_finish_time}
                                />
                            </div>
                        </div>
                    ))}
            </div>

            <Button disabled={!isActive}
                type="button"
                onClick={onSubmit}
                variant="contained"
                className="settings-root__company-form-btn"
            >
                Сохранить
            </Button>

        </div>
    );
};

const mapStateToProps = state => ({
    salonTimes: state.core.salonTimes
})

const mapDispatchToProps = dispatch => ({
    fetchSalonTimes: salonId => dispatch(fetchSalonTimes(salonId)),
    fetchSaveSalonTimes: (salonId, data) => dispatch(fetchSaveSalonTimes(salonId, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkTime);