import React, {useCallback, useEffect, useState} from 'react';

import './index.scss';
import Colors from "../../../global/styles/Colors";
import DashboardHeader from "../../../components/DasboardHeader/DashboardHeader";
import CreateBlock from "../../../components/CreateBlock/CreateBlock";
import CreateSpecialistModal from "./createSpecialistModal";
import CommonPagination from "../../../components/CommonPagintation/CommonPagintation";
import StaffElement from "./StaffElement";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import {fetchCreateStaff, fetchEditStaff, fetchRemoveStaff, fetchStaffsGet} from "../../../store/staff/staffActions";
import {connect} from "react-redux";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import {ROLE_ADMIN, ROLE_MASTER, ROLES} from "../../../global/Constants/Constants";
import {useDebounce} from "../../../components/UseDebounce/UserDebounce";
import {INITIAL_PAGE} from "../Applications/Applications";
import {isEmptyArray} from "formik";
import NoResultIcon from "../../../components/kit/Icons/NoResultIcon";

export const STAFF_OPTIONS = [
    {
        name: "Все",
        status: ""
    },
    {
        name: "Специалист",
        status: ROLE_MASTER
    },
    {
        name: "Администраторы",
        status: ROLE_ADMIN
    }
]

const Staffs = (
    {
        fetchStaffsGet,
        staffsList,

        requested,

        fetchCreateStaff,
        fetchRemoveStaff,

        fetchEditStaff,

        salons,
        salon
    }
) => {

    const [isOpenSpecialistModal, setOpenSpecialistModal] = useState(false)
    const [editableItem, setItem] = useState(null)

    const [state, setState] = useState(
        {
            position: "",
            search: "",
            page: INITIAL_PAGE,
            salon: []
        }
    )

    const debouncedSearchTerm = useDebounce(state.search, 700);

    useEffect(function () {
        getData()
    }, [state.position, debouncedSearchTerm, state.page, salons])

    const getData = useCallback(() => {
        if (salons) {
            const salonsIds = salons.map(salon => salon.id)
            fetchStaffsGet(state, salonsIds)
        }
    }, [fetchStaffsGet, salons, state])


    const toggleSpecialistModal = useCallback(() => {
        if (isOpenSpecialistModal) {
            setItem(null)
        }
        setOpenSpecialistModal(!isOpenSpecialistModal)
    }, [isOpenSpecialistModal])

    const inputHandleChange = useCallback(e => {
        e.persist();
        setState(prevState => {
            return {...prevState, search: e.target.value}
        })
    }, [])

    const changeTab = useCallback(item => {
        setState(prevState => {
            return {...prevState, position: item.status, page: INITIAL_PAGE}
        })
    }, [])

    const onChangePage = useCallback((e, page) => {
        setState(prevState => {
            return {...prevState, page: page}
        })
    }, [])


    const onSubmitNewSpecialist = useCallback(async values => {
        toggleSpecialistModal();
        const data = {...values};

        const formData = new FormData();

        Object.keys(data).forEach(key => {
            if (typeof data[key] !== 'object') formData.append(key, data[key])
        })

        data.service && data.service.forEach(service => {
            formData.append('service', service.id)
        })
        formData.append('image', data.image)

        if (editableItem) {
            await fetchEditStaff(editableItem.id, formData)
        } else {
            await fetchCreateStaff(formData)
        }
        const salonsIds = salons.map(salon => salon.id)
        await fetchStaffsGet(state, salonsIds)

    }, [editableItem, fetchCreateStaff, fetchEditStaff, fetchStaffsGet, salons, state, toggleSpecialistModal])

    const onRemoveStaff = useCallback(async id => {
        await fetchRemoveStaff(id)
        getData()
    }, [fetchRemoveStaff, getData])

    const onEdit = useCallback(item => {
        setOpenSpecialistModal(true)
        setItem(item)
    }, [])

    return (
        <React.Fragment>
            <DashboardHeader
                title="Все сотрудники"
                buttonText="Добавить сотрудника"
                isActiveTab={true}
                tabOptions={STAFF_OPTIONS}
                onSearch={inputHandleChange}
                activeTab={state.position}
                activateTab={changeTab}
                onClickAdd={toggleSpecialistModal}>
            </DashboardHeader>
            <div className="dashboard-staffs-root">
                <Table className="dashboard-staffs-root__table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Имя и фамилия
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Телефон
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Роль
                                </p>
                            </TableCell>
                            <TableCell>

                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Салон
                                </p>
                            </TableCell>
                            <TableCell>
                                <p className="dashboard__title dashboard__services-title"
                                   style={{color: Colors.TEXT['text_color']}}>
                                    Действия
                                </p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {!requested && staffsList && staffsList.count > 0 && staffsList.results &&
                        staffsList.results.map(staff => (
                            <StaffElement
                                data={staff}
                                key={staff.id}
                                onRemove={onRemoveStaff}
                                onEdit={onEdit}
                            />
                        ))}

                    </TableBody>
                </Table>


                {requested && (
                    <CommonSpinner isLoading={requested} centered/>
                )}

                {!requested && debouncedSearchTerm && staffsList &&
                staffsList.results &&
                isEmptyArray(staffsList.results) &&
                <div className="no-result">
                    <NoResultIcon width={100} height={140}/>
                    <p className="no-result__text">Результатов не найдено</p>
                </div>
                }

                <div className="dashboard-staffs-root__empty-block"
                     style={{backgroundColor: Colors.NEUTRAL['light_gray']}}>
                    {!requested && !debouncedSearchTerm && staffsList && staffsList.count === 0 && (
                        <CreateBlock
                            title={`Нет 
                            ${ROLES[state.position] ? ROLES[state.position].name + "a" : "сотрудника"}`}
                            subTitle="Добавьте сотрудников к соответсвующим ролям и к соотвующим салонам"
                            onSubmit={toggleSpecialistModal}
                            buttonText={`Добавить 
                            ${ROLES[state.position] ? ROLES[state.position].name + "a" : "сотрудника"}`}
                        />
                    )}


                </div>
                {!requested && staffsList && staffsList.page_count > INITIAL_PAGE && (
                    <CommonPagination
                        count={staffsList.page_count}
                        currentPage={state.page}
                        onChange={onChangePage}/>
                )}
            </div>


            {isOpenSpecialistModal &&
            <CreateSpecialistModal
                isOpen={isOpenSpecialistModal}
                onClose={toggleSpecialistModal}
                initValues={editableItem}
                employeePosition={state.position}
                onSubmitNewSpecialist={onSubmitNewSpecialist}
                salonId={!!salon && salon.id}
            />
            }

        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    staffsList: state.staffs.staffsList,
    requested: state.staffs.requested,
    salons: state.core.salons,
    salon: state.core.salon,
})

const mapDispatchToProps = dispatch => ({
    fetchStaffsGet: (filter, salonsIds) => dispatch(fetchStaffsGet(filter, salonsIds)),
    fetchCreateStaff: data => dispatch(fetchCreateStaff(data)),
    fetchRemoveStaff: staffId => dispatch(fetchRemoveStaff(staffId)),
    fetchEditStaff: (staffId, data) => dispatch(fetchEditStaff(staffId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Staffs);