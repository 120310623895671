import {FETCH_GET_MASTER_DETAIL_REQUEST, FETCH_GET_MASTER_DETAIL_SUCCESS} from './detailedMasterAction'

const initialState = {
    response: false,
    masterDetail: null
}

export const detailMaster = (state = initialState, action) => {
    switch (action.type){
        case FETCH_GET_MASTER_DETAIL_REQUEST:
            return {...state, response: true}
        case FETCH_GET_MASTER_DETAIL_SUCCESS:
            return {...state, response: false, masterDetail: action.value}
        default: return state
    }
}