import React from 'react';
import Colors from "../../../../global/styles/Colors";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";

const OrderList = ({orders, handleBackNav, onSelectOrder}) => {

    return (
        <div className="order-list-root">
            <h3 className="service-variant-root__title" style={{color: Colors.NEUTRAL['black']}}>
                <span className="record-nav-back-icon">
                   <ArrowLargeIcon onClick={handleBackNav}/>
               </span>
                Выберите запись
                <span className="empty"/>
            </h3>
            <div className="order-list-root__order-list">
                {!!orders && !!orders.length && orders.map(order => (
                    <div
                        className="order-list-root__order-item"
                        key={order.id}
                        onClick={() => onSelectOrder(order)}>
                        <p className="date-title">Дата записи:</p>
                        <p className="date-value">{order.appointed_date}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrderList;