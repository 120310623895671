import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'

import Arrow from "../../../components/kit/Arrow/Arrow";
import Filter from "../../../components/kit/Filter/Filter";
import SelectionButtons from "../../../components/kit/SelectionButtons/SelectionButtons";
import ListNewRecording from "../../../components/List/ListNewRecording/ListNewRecording";
import FilterPage from "../FilterPage/FilterPage";
import LoadingComponent from "../../Pages/OnlineRecord/LoadingComponent/LoadingComponent";

import {
    LINK_ABOUT_SALON_DEFAULT,
    LINK_ABOUT_MASTER_DEFAULT,
    LINK_ABOUT_SALON,
    LINK_ABOUT_MASTER
} from "../../../global/Helpers/Links";

import {getListMaster, getListMasterPagination} from '../../../store/Main/listMaster/listMasterAction'
import {getListSalons, getListSalonPagination} from '../../../store/Main/listSalons/listSalonsAction'
import {getDefaultPage} from "../../../global/Helpers/helpers";
import {getMastersSearch} from "../../../store/Main/search/searchAction";
import {getSalonsSearch} from "../../../store/Main/search/searchAction";
import {showFilter} from "../../../store/core/coreActions"
import {showSearchPage} from "../../../store/Main/search/searchAction";

import './index.scss'

const NewEntry = (props) => {

    const [btnActive, setActive] = useState(0)
    const [filterPageIsOpen, setFilterPage] = useState(false)
    const [getResponse, setResponse] = useState(false)
    const [defaultCheck, setDefaultCheck] = useState(null)
    const [searchTitle, setSearchTitle] = useState('')

    const {listMaster, responseMaster} = props
    const {listSalon, responseSalon} = props
    const {nextPageMaster, nextPageSalon} = props
    const {requestPaginationMaster, requestPaginationSalon} = props
    const {paginationResponseMaster, paginationResponseSalon} = props
    const {requestMastersSearch, requestSalonsSearch, history} = props

    useEffect(() => {
        props.requestGetMasterList()
        props.requestGetSalonList()

        setDefaultCheck(getDefaultPage(props.match.params.default))

        document.addEventListener('scroll', scrollHandler)
        return () => {
            document.removeEventListener('scroll', scrollHandler)
            document.body.style.overflow = 'auto';
        }
    }, [])

    useEffect(() => {
        document.body.style.overflow = filterPageIsOpen ? 'hidden' : 'auto';
    }, [filterPageIsOpen])

    useEffect(() => {
        getResponse && paginationPage()
    }, [getResponse])

    useEffect(() => setSearchTitle(''),[btnActive])

    const scrollHandler = (e) => {
        if(e.target.documentElement.scrollHeight -
            (e.target.documentElement.scrollTop + window.innerHeight) < 100
        ){
            setResponse(true)
        }
    }

    const paginationPage = () => {
        if(getResponse && !(paginationResponseMaster || paginationResponseSalon)){
            if(btnActive ? nextPageSalon : nextPageMaster){
                btnActive ? requestPaginationSalon(nextPageSalon) : requestPaginationMaster(nextPageMaster)
            }
        }
        setResponse(false)
    }

    const setTitle = e => setSearchTitle(e.target.value)

    const onSubmitSearch = () => {
        history.push(`/search_result/${btnActive ? 'salons' : 'masters'}/${defaultCheck ? 'default' : ''}?search=${searchTitle}`)
    }

    if(responseMaster || responseSalon) return <LoadingComponent />

    return (
        <div className="container">
            <div className="online-record-root">
                <Arrow
                    title="Записаться"
                    history={history}
                />

                <SelectionButtons
                    titles={['Мастера', 'Салоны']}
                    btnActive={btnActive}
                    setActive={setActive}
                />

                <Filter
                    placeholder={btnActive ? 'Поиск салона' : 'Поиск мастера'}
                    onClick={() => setFilterPage(!filterPageIsOpen)}
                    setTitle={setTitle}
                    onSubmit={onSubmitSearch}
                    value={searchTitle}
                />

                {filterPageIsOpen &&
                    <FilterPage
                        isOpen={filterPageIsOpen}
                        onClick={() => setFilterPage(!filterPageIsOpen)}
                        history={history}
                        btnActive={btnActive}
                        defaultPage={defaultCheck}
                    />
                }

                <ListNewRecording
                    btnActive={btnActive}
                    listMaster={listMaster}
                    listSalon={listSalon}
                    nextPage={[nextPageMaster, nextPageSalon]}
                    paginationResponse={[paginationResponseMaster, paginationResponseSalon]}
                    moreInformation={paginationPage}
                    links={defaultCheck ? [LINK_ABOUT_MASTER_DEFAULT, LINK_ABOUT_SALON_DEFAULT] : [LINK_ABOUT_MASTER, LINK_ABOUT_SALON]}
                />

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    listMaster: state.listMaster.listMaster,
    listSalon: state.listSalon.listSalon,
    nextPageMaster: state.listMaster.nextPage,
    nextPageSalon: state.listSalon.nextPage,
    responseMaster: state.listMaster.response,
    responseSalon: state.listSalon.response,
    paginationResponseMaster: state.listMaster.paginationResponse,
    paginationResponseSalon: state.listSalon.paginationResponse,
    searchReducer: state.searchReducer
})

const mapDispatchToProps = dispatch => ({
    requestGetMasterList: () => {dispatch(getListMaster())},
    requestGetSalonList: () => {dispatch(getListSalons())},
    requestPaginationMaster: (url) => {dispatch(getListMasterPagination(url))},
    requestPaginationSalon: (url) => {dispatch(getListSalonPagination(url))},
    requestMastersSearch: (params) => {dispatch(getMastersSearch(params))},
    requestSalonsSearch: (params) => {dispatch(getSalonsSearch(params))},
    setShowSalon: () => {dispatch(showFilter())},
    showPage: () => {dispatch(showSearchPage())}
})

export default connect(mapStateToProps, mapDispatchToProps)(NewEntry);