import React from 'react';

const WalletIcon = ({width, height, className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 20 20"
             fill="none">
            <g clipPath="url(#clip0)">
                <path
                    d="M4.67355 4.05527L12.6466 1.87897L12.2244 1.02551C11.9481 0.470769 11.2743 0.241684 10.7196 0.517934L3.58203 4.05527H4.67355Z"
                    fill="#C2BCCE"/>
                <path
                    d="M15.1219 1.94187C15.0231 1.94187 14.9242 1.95534 14.8254 1.98229L12.9523 2.49436L7.23193 4.05528H13.7249H16.5548L16.2044 2.77061C16.0697 2.26977 15.616 1.94187 15.1219 1.94187Z"
                    fill="#C2BCCE"/>
                <path
                    d="M17.7249 4.84138H17.4688H17.1207H16.7726H14.1157H4.35261H3.07243H1.99439H1.7945H1.12521C0.770354 4.84138 0.453678 5.00533 0.247052 5.26361C0.152723 5.38265 0.0808535 5.51965 0.0404267 5.67013C0.0157215 5.76445 0 5.86328 0 5.96434V6.0991V7.37928V18.4764C0 19.0963 0.503088 19.5994 1.12296 19.5994H17.7226C18.3425 19.5994 18.8456 19.0963 18.8456 18.4764V15.3433H12.1819C11.1286 15.3433 10.2729 14.4876 10.2729 13.4343V12.4057V12.0575V11.7094V10.9368C10.2729 10.4203 10.4795 9.95089 10.8141 9.60726C11.1106 9.30179 11.5081 9.09517 11.9528 9.04351C12.027 9.03455 12.1033 9.03004 12.1797 9.03004H17.9113H18.2594H18.6075H18.8456V5.96434C18.8478 5.34447 18.3448 4.84138 17.7249 4.84138Z"
                    fill="#C2BCCE"/>
                <path
                    d="M19.6341 10.1035C19.5218 10.0002 19.3893 9.92161 19.2411 9.86995C19.1265 9.83179 19.0052 9.80933 18.8772 9.80933H18.848H18.8256H18.4774H17.222H12.1821C11.5622 9.80933 11.0591 10.3124 11.0591 10.9323V11.4915V11.8396V12.1878V13.432C11.0591 14.0519 11.5622 14.555 12.1821 14.555H18.848H18.8772C19.0052 14.555 19.1265 14.5325 19.2411 14.4943C19.3893 14.4449 19.5218 14.3641 19.6341 14.2607C19.8587 14.0564 20.0002 13.7599 20.0002 13.432V10.9323C20.0002 10.6044 19.8587 10.3079 19.6341 10.1035ZM14.5268 12.4056C14.5268 12.7155 14.2753 12.9671 13.9653 12.9671H13.5925C13.2826 12.9671 13.031 12.7155 13.031 12.4056V12.0328C13.031 11.8531 13.1141 11.6936 13.2466 11.5926C13.3432 11.5184 13.4623 11.4713 13.5925 11.4713H13.6868H13.9653C14.2753 11.4713 14.5268 11.7228 14.5268 12.0328V12.4056Z"
                    fill="#C2BCCE"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default WalletIcon;