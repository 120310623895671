import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";

import Arrow from "../../../../components/kit/Arrow/Arrow";
import SelectionButtons from "../../../../components/kit/SelectionButtons/SelectionButtons";
import ListSalonsRecording from "../../../../components/List/ListSalons/ListSalonsRecording";
import Filter from "../../../../components/kit/Filter/Filter";
import LoadingComponent from "../../OnlineRecord/LoadingComponent/LoadingComponent";

import {
    getListMastersServices, getListSalonsServices,
    getListMasterPaginationOfServices, getListSalonsPaginationOfServices
} from "../../../../store/Main/listMastersSalonsServices/listMastersSalonsServicesAction";

import './index.scss'
import {getDefaultPage} from "../../../../global/Helpers/helpers";
import {
    LINK_ABOUT_MASTER,
    LINK_ABOUT_MASTER_DEFAULT,
    LINK_ABOUT_SALON,
    LINK_ABOUT_SALON_DEFAULT
} from "../../../../global/Helpers/Links";

const ListServices = (props) => {

    const {getListMastersServices, getListSalonsServices,
        getListMasterPaginationOfServices, getListSalonsPaginationOfServices} = props
    const {listMastersSalonsServices, defaultPage, params, history} = props

    const [getResponse, setResponse] = useState(false)
    const [defaultCheck, setDefaultCheck] = useState(null)
    const [btnActive, setActive] = useState(0)
    const [searchTitle, setSearchTitle] = useState('')

    const paginationMaster = listMastersSalonsServices?.listMaster?.response
    const paginationSalons = listMastersSalonsServices?.listSalons?.response

    const nextPageMaster = listMastersSalonsServices?.listMaster?.nextPage
    const nextPageSalon = listMastersSalonsServices?.listSalons?.nextPage

    useEffect(() => {
        getListSalonsServices(params)
        getListMastersServices(params)

        setDefaultCheck(defaultPage)

        document.addEventListener('scroll', scrollHandler)
        return () => {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    useEffect(() => {
        getResponse && paginationPage()
    }, [getResponse])

    const scrollHandler = (e) => {
        if(e.target.documentElement.scrollHeight -
            (e.target.documentElement.scrollTop + window.innerHeight) < 100
        ){
            setResponse(true)
        }
    }

    const paginationPage = () => {
        if((getResponse && !(paginationSalons || paginationMaster))){
            if(btnActive ? nextPageSalon : nextPageMaster) {
                btnActive ?
                    getListSalonsPaginationOfServices(nextPageSalon) :
                    getListMasterPaginationOfServices(nextPageMaster)
            }
        }
        setResponse(false)
    }

    const setTitle = e => setSearchTitle(e.target.value)

    const onSubmitSearch = () => {
        history.push(`/search_result/${btnActive ? 'salons' : 'masters'}/${defaultCheck ? 'default' : ''}?search=${searchTitle}`)
    }

    if(listMastersSalonsServices.response) return <LoadingComponent />

    return (
        <div className="container">
            <div className="online-record-root">
                <Arrow
                    title={btnActive ? listMastersSalonsServices?.listSalons?.title : listMastersSalonsServices?.listMaster?.title}
                    to="/records_services"
                    history={props.history}
                />

                <SelectionButtons
                    titles={['Мастера', 'Салоны']}
                    btnActive={btnActive}
                    setActive={setActive}
                />

                <Filter
                    filter={false}
                    placeholder={btnActive ? 'Поиск салона' : 'Поиск мастера'}
                    setTitle={setTitle}
                    onSubmit={onSubmitSearch}
                    value={searchTitle}
                />

                <ListSalonsRecording
                    btnActive={btnActive}
                    listItems={
                        btnActive ?
                        listMastersSalonsServices?.listSalons?.arraySalons :
                        listMastersSalonsServices?.listMaster?.arrayMaster
                    }
                    response={btnActive ? paginationSalons : paginationMaster}
                    links={defaultCheck ? [LINK_ABOUT_MASTER_DEFAULT, LINK_ABOUT_SALON_DEFAULT] : [LINK_ABOUT_MASTER, LINK_ABOUT_SALON]}
                />

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getListMastersServices: id => dispatch(getListMastersServices(id)),
    getListSalonsServices: id => dispatch(getListSalonsServices(id)),
    getListMasterPaginationOfServices: url => dispatch(getListMasterPaginationOfServices(url)),
    getListSalonsPaginationOfServices: url => dispatch(getListSalonsPaginationOfServices(url))
})

export default connect(mapStateToProps, mapDispatchToProps)(ListServices);