import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import moment from "moment";
import { isEmptyArray } from "formik";

import LoadingComponent from "./LoadingComponent/LoadingComponent";
import SelectBranches from "./SelectBranches/SelectBranches";
import SelectServiceMaster from "./SelectServiceMaster/SelectServiceMaster";
import ChooseService from "./ChooseService/ChooseService";
import SelectDate from "./SelectDate/SelectDate";
import Payment from "./Payment/Payment";
import ChooseMaster from "./ChooseMaster/ChooseMaster";
import OnlineRecordModal from "./OnlineRecordModal/OnlineRecordModal";

import {
  fetchCreateApplicationByOnlineWithMBank,
  fetchSalonGetPayMethod,
} from "../../../store/payMethod/payMethodAction";
import {
  closeCreatedModal,
  fetchCreateApplicationByOnline,
  fetchEditClientApplication,
  fetchGetClientRecords,
  fetchGetCodeRequest,
  fetchCheckCodeRequest,
} from "../../../store/applications/applicationsAction";
import {
  fetchSalonGet,
  fetchServicesGet,
} from "../../../store/core/coreActions";
import { dateFormatter, getDefaultPage } from "../../../global/Helpers/helpers";
import AuthUser from "./AuthUser/AuthUser";
import OrderList from "./OrderList/OrderList";

import { currentDate } from "../../../global/Constants/Constants";

import "./index.scss";
import ChangeRecord from "./ChangeRecord/ChangeRecord";
import PaymentChange from "./PaymentChange/PaymentChange";
import { useTelegram } from "../../../hooks/useTelegram";
//Record
export const STEP_CHOOSE_BRANCHES = 1; // филиалы
export const STEP_LOADING = 2; // 1 филиал
export const STEP_CHOOSE_SERVICE = 3; // Выбор услуг
export const STEP_CHOOSE_MASTER = 4; // Выбор мастера
export const STEP_CHOOSE_DATE = 5; // Дата
export const STEP_COMPLETE = 6; //
export const STEP_USER_AUTH = 7; //
//Change
export const STEP_USER_AUTH_CHANGE = 8;
export const STEP_SELECT_ORDER = 9; //
export const STEP_ORDER_INFO = 10; //
export const STEP_COMPLETE_CHANGE = 11;

const OnlineRecord = (props) => {
  const { fetchSalonGet, salon, requested, fetchSalonGetPayMethod, isOtp } =
    props;

  const {
    fetchServicesGet,
    servicesList,
    serviceRequested,
    fetchCreateApplicationByOnlineWithMBank,
  } = props;

  const {
    fetchCreateApplicationByOnline,
    closeCreatedModal,
    isCreated,
    fetchEditClientApplication,
    fetchGetCodeRequest,
    fetchCheckCodeRequest,
  } = props;

  const { fetchGetClientRecords, clientRecords, history, payMethods, match } =
    props;

  const { tg, isTgWebApp } = useTelegram();

  const [isChaneRecord, setChangeRecord] = useState(false);
  const [defaultCheck, setDefaultCheck] = useState(null);
  const [request, setRequest] = useState(false);

  const [state, setState] = useState({
    page: STEP_LOADING,
    services: [],
    salonId: props.match.params.salonId,
    time: "",
    name: "",
    phone: "",
    master: undefined,
    selectedDate: currentDate,
    typePay: null,
  });

  const [selectedOrder, setOrder] = useState(null);

  useEffect(
    function () {
      fetchSalonGet(state.salonId);
      fetchSalonGetPayMethod(state.salonId);
    },
    [state.salonId]
  );

  useEffect(() => {
    setDefaultCheck(getDefaultPage(match.params.default));
  }, []);

  useEffect(() => {
    if (salon?.filials && salon?.filials.length === 1) {
      setState({ ...state, page: STEP_LOADING });
    }
  }, [salon]);

  useEffect(
    function () {
      const clientKey = localStorage.getItem("client_key");

      if (clientKey && state.page === STEP_CHOOSE_BRANCHES) {
        fetchGetClientRecords();
      }
    },
    [state.page]
  );

  useEffect(
    function () {
      fetchServicesGet(
        undefined,
        state.salonId,
        state.master ? state.master.id : ""
      );
    },
    [state.salonId]
  );

  useEffect(() => {
    if (isOtp) {
      setState((prevState) => {
        return { ...prevState, page: STEP_COMPLETE };
      });
    }
  }, [isOtp]);

  useEffect(() => {
    if (payMethods?.payMethods && payMethods.payMethods.length === 1) {
      onSelectTypePay(payMethods.payMethods[0].slug);
    }
  }, [payMethods]);

  const onSelectBranchId = (branchId) => {
    setState((prevState) => {
      return { ...prevState, salonId: branchId };
    });
  };

  const onSelectService = (variant) => {
    setState((prevState) => {
      return { ...prevState, page: variant };
    });
  };

  const onSelectServiceVariant = (item) => {
    const services = [...state.services];
    const findInDex = services.findIndex((it) => it.id === item.id);
    if (findInDex !== -1) {
      services.splice(findInDex, 1);
    } else {
      services.push(item);
    }
    setState((prevState) => {
      return { ...prevState, services };
    });
  };

  const inputChangeHandler = (e) => {
    e.persist();
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const onSelectTime = (time) => {
    setState((prevState) => {
      return { ...prevState, time: time };
    });
  };

  const handleClickNext = () => {
    if (state.page === STEP_CHOOSE_MASTER) {
      setState((prevState) => {
        return {
          ...prevState,
          page: state.services.length ? STEP_CHOOSE_DATE : STEP_CHOOSE_SERVICE,
        };
      });
    } else if (state.page === STEP_CHOOSE_SERVICE) {
      setState((prevState) => {
        return {
          ...prevState,
          page: state.master ? STEP_CHOOSE_DATE : STEP_CHOOSE_MASTER,
        };
      });
    } else if (
      state.page === STEP_CHOOSE_DATE &&
      salon.is_otp_enabled &&
      !isChaneRecord
    ) {
      setState((prevState) => {
        return { ...prevState, page: STEP_USER_AUTH };
      });
      fetchGetCodeRequest(state.phone.replace(/[- )(]/g, ""), state.salonId);
    } else if (state.page === STEP_CHOOSE_DATE && isChaneRecord) {
      setState((prevState) => {
        return { ...prevState, page: STEP_COMPLETE_CHANGE }; //STEP_COMPLETE_CHANGE
      });
    } else {
      setState((prevState) => {
        return { ...prevState, page: state.page + 1 };
      });
    }
  };

  const onSelectMaster = (master) => {
    setState((prevState) => {
      return { ...prevState, master };
    });
    handleClickNext();
  };

  const onSelectTypePay = (type) => {
    setState((prevState) => {
      return { ...prevState, typePay: type };
    });
  };

  const onSelectDate = (date) => {
    setState((prevState) => {
      return { ...prevState, selectedDate: date };
    });
  };

  const calendarHandleNextClick = () => {
    const { selectedDate } = state;
    const currentMonth = selectedDate.getMonth();
    setState((prevState) => {
      return {
        ...prevState,
        selectedDate: new Date(selectedDate.setMonth(currentMonth + 1)),
      };
    });
  };

  const calendarHandlePrevClick = () => {
    const { selectedDate } = state;
    const currentMonth = selectedDate.getMonth();
    setState((prevState) => {
      return {
        ...prevState,
        selectedDate: new Date(selectedDate.setMonth(currentMonth - 1)),
      };
    });
  };

  const onSubmit = async () => {
    if (state.page === STEP_ORDER_INFO) {
      setState((prevState) => {
        return {
          ...prevState,
          page: STEP_CHOOSE_MASTER,
          employee: undefined,
          services: [],
        };
      });
    } else {
      const serviceIds = state.services.map((service) => service.id);
      const data = {
        employee: state.master.id,
        number: state.phone,
        name: state.name,
        service: serviceIds,
        salon: state.salonId,
        appointed_date: dateFormatter(state.selectedDate),
        appointed_time: state.time,
      };
      if (selectedOrder) {
        await fetchEditClientApplication(selectedOrder.id, data);
        setOrder(null);
      } else {
        if (isTgWebApp) {
          return tg.onEvent("mainButtonClicked", () => {
            tg.sendData(JSON.stringify(data));
          });
        }
        if (typePay === "cash") {
          setRequest(true);
          !request &&
            fetchCreateApplicationByOnline(data).then((response) => {
              setTimeout(() => {
                setRequest(false);
              }, 10000);
            });
        } else {
          await fetchCreateApplicationByOnlineWithMBank(data);
        }
      }
    }
  };

  function SelectPage() {
    if (salon?.filials) {
      setState((prevState) => {
        return { ...prevState, page: STEP_LOADING };
      });
    }
  }

  function onCloseModal() {
    setState({
      page: STEP_LOADING,
      services: [],
      salonId: state.salonId,
      time: "",
      name: "",
      phone: "",
      master: undefined,
      selectedDate: currentDate,
    });
    closeCreatedModal();
  }

  function handleBackNav() {
    if (state.page === 1) {
      history.goBack();
    } else if (state.page === 2) {
      salon?.filials.length > 1
        ? setState({ ...state, page: STEP_CHOOSE_BRANCHES })
        : history.goBack();
    } else if (state.page === STEP_CHOOSE_MASTER) {
      setState((prevState) => {
        return {
          ...prevState,
          page: state.services.length
            ? STEP_CHOOSE_SERVICE
            : salon?.filials.length > 1
            ? STEP_LOADING
            : STEP_LOADING,
          master: undefined,
        };
      });
    } else if (state.page === STEP_CHOOSE_SERVICE) {
      setState((prevState) => {
        return {
          ...prevState,
          page: state.master
            ? STEP_CHOOSE_MASTER
            : salon?.filials.length > 1
            ? STEP_LOADING
            : STEP_LOADING,
          services: [],
        };
      });
    } else if (state.page === STEP_SELECT_ORDER) {
      setState((prevState) => {
        return { ...prevState, page: STEP_LOADING };
      });
      setChangeRecord(false);
    } else if (
      state.page === STEP_USER_AUTH &&
      clientRecords &&
      clientRecords.length
    ) {
      setState((prevState) => {
        return { ...prevState, page: STEP_LOADING };
      });
      setChangeRecord(false);
    } else if (
      state.page === STEP_USER_AUTH &&
      !(clientRecords && clientRecords.length)
    ) {
      setState((prevState) => {
        return { ...prevState, page: STEP_CHOOSE_DATE };
      });
    } else if (state.page === STEP_USER_AUTH_CHANGE) {
      setState((prevState) => {
        return { ...prevState, page: STEP_LOADING };
      });
      setChangeRecord(false);
    } else {
      setState((prevState) => {
        return { ...prevState, page: state.page - 1 };
      });
    }
  }

  function onSelectEdit() {
    setState((prevState) => {
      return { ...prevState, page: STEP_USER_AUTH_CHANGE };
    });
  }

  function handleNextNavByAuth(code = "5555") {
    fetchCheckCodeRequest(state.phone.replace(/[- )(]/g, ""), code);
  }

  function handleBackByAuth() {
    setState((prevState) => {
      return { ...prevState, page: STEP_SELECT_ORDER };
    });
    setChangeRecord(true);
  }

  function onSelectOrder(order) {
    setOrder(order);
    setState((prevState) => {
      return {
        ...prevState,
        page: STEP_ORDER_INFO,
        time: order.appointed_time.slice(0, 5),
        name: order.name,
        phone: order.number,
        master: { id: order.employee, name: order.master_name },
        selectedDate: moment(order.appointed_date, "DD-MM-YYYY").toDate(),
        services: order.service_list,
      };
    });
  }

  const { page, services, time, name, phone, master, selectedDate, typePay } =
    state;
  return (
    <React.Fragment>
      {(requested || !salon) && <LoadingComponent />}

      {!requested && salon && (
        <div className="container">
          <div className="online-record-root">
            {page === STEP_CHOOSE_BRANCHES && (
              <SelectBranches
                onSelect={onSelectService}
                onSelectId={onSelectBranchId}
                onSelectPage={SelectPage}
                salon={salon}
                clientRecords={clientRecords}
                handleBackNav={handleBackNav}
              />
            )}

            {page === STEP_LOADING && (
              <SelectServiceMaster
                onSelect={onSelectService}
                salon={salon}
                clientRecords={clientRecords}
                onSelectEdit={onSelectEdit}
                handleBackNav={handleBackNav}
              />
            )}

            {page === STEP_CHOOSE_SERVICE && servicesList && (
              <ChooseService
                onSelect={onSelectServiceVariant}
                selectedOptions={services}
                serviceOptions={master ? master.service_list : servicesList}
                requested={serviceRequested}
                handleClickNext={handleClickNext}
                handleBackNav={handleBackNav}
              />
            )}

            {page === STEP_CHOOSE_MASTER && (
              <ChooseMaster
                onSelectMaster={onSelectMaster}
                services={services}
                handleBackNav={handleBackNav}
              />
            )}

            {page === STEP_CHOOSE_DATE && (
              <SelectDate
                selectedTime={time}
                onSelectTime={onSelectTime}
                name={name}
                phone={phone}
                inputChangeHandler={inputChangeHandler}
                onSelectDate={onSelectDate}
                selectedDate={selectedDate}
                calendarHandleNextClick={calendarHandleNextClick}
                calendarHandlePrevClick={calendarHandlePrevClick}
                handleClickNext={handleClickNext}
                salonId={state.salonId}
                master={master}
                service={services}
                handleBackNav={handleBackNav}
              />
            )}

            {page === STEP_COMPLETE && (
              <Payment
                selectedOptions={services}
                selectedTime={time}
                master={master}
                selectedDate={selectedDate}
                onSubmit={onSubmit}
                handleBackNav={handleBackNav}
                initOrder={selectedOrder}
                payMethods={payMethods.payMethods}
                typePay={typePay}
                onSelectTypePay={onSelectTypePay}
                defaultCheck={defaultCheck}
                data={{
                  employee: state.master.id,
                  number: state.phone,
                  name: state.name,
                  service: state.services.map((service) => service.id),
                  salon: state.salonId,
                  appointed_date: dateFormatter(state.selectedDate),
                  appointed_time: state.time,
                }}
              />
            )}

            {page === STEP_USER_AUTH && (
              <AuthUser
                handleBackNav={handleBackNav}
                handleNextNavByAuth={handleNextNavByAuth}
              />
            )}

            {page === STEP_USER_AUTH_CHANGE && (
              <ChangeRecord
                handleBackNav={handleBackNav}
                handleBackByAuth={handleBackByAuth}
              />
            )}

            {page === STEP_SELECT_ORDER &&
              clientRecords &&
              !isEmptyArray(clientRecords) && (
                <OrderList
                  orders={clientRecords}
                  onSelectOrder={onSelectOrder}
                  handleBackNav={handleBackNav}
                />
              )}

            {page === STEP_ORDER_INFO && (
              <PaymentChange
                selectedOptions={services}
                selectedTime={time}
                master={master}
                selectedDate={selectedDate}
                onSubmit={onSubmit}
                handleBackNav={handleBackNav}
                initOrder={true}
                disabled={isCreated}
              />
            )}

            {page === STEP_COMPLETE_CHANGE && (
              <PaymentChange
                selectedOptions={services}
                selectedTime={time}
                master={master}
                selectedDate={selectedDate}
                onSubmit={onSubmit}
                handleBackNav={handleBackNav}
                initOrder={true}
                disabled={isCreated}
              />
            )}

            {isCreated && (
              <OnlineRecordModal
                isOpenModal={isCreated}
                onClose={onCloseModal}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  salon: state.core.salon,
  requested: state.core.requested,
  servicesList: state.core.services,
  serviceRequested: state.core.serviceRequested,
  payMethods: state.payMethods,

  staffsList: state.staffs.staffsList,

  isCreated: state.applications.isCreated,
  clientRecords: state.applications.clientRecords,
  isOtp: state.applications.otp,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSalonGet: (salonId) => dispatch(fetchSalonGet(salonId)),
  fetchSalonGetPayMethod: (salonId) =>
    dispatch(fetchSalonGetPayMethod(salonId)),
  fetchServicesGet: (category, salonId, masterId) =>
    dispatch(fetchServicesGet(category, salonId, masterId)),
  fetchCreateApplicationByOnline: (data) =>
    dispatch(fetchCreateApplicationByOnline(data)),
  closeCreatedModal: () => dispatch(closeCreatedModal()),
  fetchGetClientRecords: () => dispatch(fetchGetClientRecords()),
  fetchEditClientApplication: (applicId, data) =>
    dispatch(fetchEditClientApplication(applicId, data)),
  fetchGetCodeRequest: (phone, salonId) =>
    dispatch(fetchGetCodeRequest(phone, salonId)),
  fetchCheckCodeRequest: (phone, code) =>
    dispatch(fetchCheckCodeRequest(phone, code)),
  fetchCreateApplicationByOnlineWithMBank: (data) =>
    dispatch(fetchCreateApplicationByOnlineWithMBank(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineRecord);
