import React, {useCallback, useEffect, useState} from 'react';
import DashboardHeader from "../../../components/DasboardHeader/DashboardHeader";
import Colors from "../../../global/styles/Colors";
import CreateBlock from "../../../components/CreateBlock/CreateBlock";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import './index.scss';
import {connect} from "react-redux";
import {INITIAL_PAGE} from "../Applications/Applications";
import {fetchMessageReport, fetchPayboxReport, fetchReportsGet} from "../../../store/finance/financeAction";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import {isEmptyArray} from "formik";
import ReservationInfoModal from "../../../components/ReservationInfoModal/ReservationInfoModal";
import {changeSalonAction} from "../../../store/core/coreActions";
import moment from "moment";
import FinancePayboxElement from "./FinancePayboxElement";
import FinanceMessageElement from "./FinanceMessageElement";
import FinanceElement from "./FinanceElement";

const PAYBOX = "paybox"
const MESSAGE = 'messages'
const REPORTS = 'reports'

const TABLE_PAYBOX_HEADER = ['ID', 'Дата', 'Период', 'Тариф', 'Тип платежа', 'Сумма']
const TABLE_MESSAGE_HEADER = ['ID', 'Дата', 'Телефон']
const TABLE_REPORTS_HEADER = ['Специалист', 'Количество услуг', 'Сумма за услуги']

export const FINANCE_OPTIONS = [
    {
        name: "Платежи",
        status: PAYBOX
    },
    {
        name: "Сообщения",
        status: MESSAGE
    },
    {
        name: "Отчеты",
        status: REPORTS
    },
]

const Finance = (
    {
        salons,
        salon,

        reports,
        paybox,
        messages,
        requested,

        fetchReportsGet,
        changeSalonAction,
        fetchPayboxReport,
        fetchMessageReport,
    }
) => {

    const [state, setState] = useState({
        salon: "",
        date: new Date(),
        page: INITIAL_PAGE,
        position: PAYBOX,
    })

    const [reserveInfoId, setReserveInfoId] = useState(null)

    useEffect(function () {
        if (salons && salons[0]) {
            setState(prevState => {
                return {...prevState, salon: !!salon ? salon.id : salons[0].id}
            })
        }
    }, [salon, salons])

    useEffect(function () {
        if (state.salon) {
            const data = {
                salon: state.salon,
                start_date: moment(state.date).format('DD-MM-YYYY'),
                end_date: moment(state.date).add(1, 'days').format('DD-MM-YYYY'),
            }

            switch (state.position) {
                case PAYBOX:
                    fetchPayboxReport(data)
                    break;
                case MESSAGE:
                    fetchMessageReport(data)
                    break;
                case REPORTS:
                    fetchReportsGet(data)
                    break;
                default:
                    break;
            }
        }
    }, [state.salon, state.date, state.position])

    const onChangeSalon = useCallback(e => {
        setState(prevState => {
            return {...prevState, salon: e.target.value, page: INITIAL_PAGE}
        })

        const salon = salons.find(salon => salon.id === e.target.value);
        changeSalonAction(salon)

    }, [changeSalonAction, salons])

    const handleDateChange = useCallback(date => {
        setState(prevState => {
            return {...prevState, date}
        })
    }, [])

    const renderTableResult = (position) => {
        switch (position) {
            case PAYBOX:
                return <TableRow>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell align={"left"} style={{fontWeight: 700}}>
                        Всего результатов: {paybox?.payments_count}
                    </TableCell>
                    <TableCell align={"left"} style={{fontWeight: 700}}>
                        Всего сумма: {paybox?.payments_sum} сом
                    </TableCell>
                </TableRow>
            case MESSAGE:
                return <TableRow className={'table_result'}>
                    <TableCell/>
                    <TableCell align={"left"} style={{fontWeight: 700}}>
                        Всего результатов: {messages?.sms_count}
                    </TableCell>
                    <TableCell align={"left"} style={{fontWeight: 700}}>
                        Всего сумма: {messages?.sms_sum} сом
                    </TableCell>
                </TableRow>
            default:
                return null;
        }
    }

    const renderTableHeader = (position) => {
        switch (position) {
            case PAYBOX:
                return TABLE_PAYBOX_HEADER.map((elem, index) => (
                    <TableCell key={index}>
                        <p className="dashboard__title dashboard__finance-title"
                           style={{color: Colors.TEXT['text_color']}}>
                            {elem}
                        </p>
                    </TableCell>
                ))
            case MESSAGE:
                return TABLE_MESSAGE_HEADER.map((elem, index) => (
                    <TableCell key={index}>
                        <p className="dashboard__title dashboard__finance-title"
                           style={{color: Colors.TEXT['text_color']}}>
                            {elem}
                        </p>
                    </TableCell>
                ))
            case REPORTS:
                return TABLE_REPORTS_HEADER.map((elem, index) => (
                    <TableCell key={index}>
                        <p className="dashboard__title dashboard__finance-title"
                           style={{color: Colors.TEXT['text_color']}}>
                            {elem}
                        </p>
                    </TableCell>
                ))
            default:
                return null;
        }
    }

    const renderTableBody = (position) => {
        switch (position) {
            case PAYBOX:
                return paybox?.payments_list?.map(report => (
                    <FinancePayboxElement
                        data={report}
                        key={report.id}
                    />
                ))

            case MESSAGE:
                return messages?.sms_list?.map(report => (
                    <FinanceMessageElement
                        data={report}
                        key={report.id}
                    />
                ))
            case REPORTS:
                return reports?.report?.map(report => (
                    <FinanceElement
                        data={report}
                        key={report.id}
                        onRemove={() => null}
                        onEdit={() => null}
                        onSelect={setReserveInfoId}
                    />
                ))
            default:
                return null;
        }
    }

    const isDataEmpty = () => {
        switch (state.position) {
            case PAYBOX:
                return isEmptyArray(paybox?.payments_list);
            case MESSAGE:
                return isEmptyArray(messages?.sms_list)
            case REPORTS:
                return isEmptyArray(reports?.report)
            default:
                break;
        }
    }

    const changeTab = useCallback(item => {
        setState(prevState => {
            return {...prevState, position: item.status, page: INITIAL_PAGE}
        })
    }, [])

    return (
        <React.Fragment>
            <DashboardHeader title="Финансовый отчет"
                             isActiveSelect={true}
                             isActiveCalendar={true}
                             onClickAdd={() => null}
                             activeTab={state.position}
                             activateTab={changeTab}
                             tabOptions={FINANCE_OPTIONS}
                             selectOptions={salons}
                             selectValue={state.salon}
                             fieldName="salon"
                             handleChange={onChangeSalon}
                             onChangeDate={handleDateChange}
            />

            <div className="dashboard-finance-root">
                <Table className="dashboard-finance-root__table">
                    <TableHead>
                        <TableRow>
                            {renderTableHeader(state.position)}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {renderTableBody(state.position)}
                    </TableBody>

                    {!isDataEmpty() && !requested && renderTableResult(state.position)}
                </Table>

                {requested && (
                    <CommonSpinner isLoading={requested} centered/>
                )}

                {!requested && isDataEmpty() && (
                    <CreateBlock
                        title="Статистики на сегодня нет"
                        subTitle="Добавьте услуги, которые вы предоставляете вашим клиентам. Без этого вы не сможете записывать клиентов."
                    />
                )}
            </div>

            {!!reserveInfoId && (
                <ReservationInfoModal
                    reserveInfoId={reserveInfoId}
                    isOpen={!!reserveInfoId}
                    handleClose={() => setReserveInfoId(null)}
                />
            )}
        </React.Fragment>
    );
};

export const mapStateToProps = state => ({
    salons: state.core.salons,
    salon: state.core.salon,
    reports: state.reports.reports,
    paybox: state.reports.paybox,
    messages: state.reports.messages,
    requested: state.reports.requested
})

export const mapDispatchToProps = dispatch => ({
    fetchReportsGet: data => dispatch(fetchReportsGet(data)),
    changeSalonAction: salon => dispatch(changeSalonAction(salon)),
    fetchPayboxReport: data => dispatch(fetchPayboxReport(data)),
    fetchMessageReport: data => dispatch(fetchMessageReport(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Finance);