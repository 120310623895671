import {
    FETCH_CREATE_SERVICE,
    FETCH_SERVICES_FAILURE,
    FETCH_SERVICES_REQUEST,
    FETCH_SERVICES_SUCCESS
} from "./serviceActions";

const initialState = {
    requested: false,
    services: null
};

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICES_REQUEST:
            return {...state, requested: true}
        case FETCH_SERVICES_SUCCESS:
            return {...state, services: action.services, requested: false}
        case FETCH_SERVICES_FAILURE:
            return {...state, requested: false}
        case FETCH_CREATE_SERVICE:
            return {...state, requested: false}
        default:
            return state;
    }
};

export default serviceReducer;
