import axios from "../../Api/axios-api";
import {ROUTE_MASTER_PAGE, ROUTE_SALON_PAGE, ROUTE_SERVICES} from "../../Api/Routes";

export const FETCH_GET_MASTERS_SEARCH_REQUEST = 'FETCH_GET_MASTERS_SEARCH_REQUEST'
export const FETCH_GET_MASTERS_SEARCH_ERROR = 'FETCH_GET_MASTERS_SEARCH_ERROR'
export const FETCH_GET_MASTERS_SEARCH_SUCCESS = 'FETCH_GET_MASTERS_SEARCH_SUCCESS'

const fetchGetMastersSearchRequest = () => ({type: FETCH_GET_MASTERS_SEARCH_REQUEST})
const fetchGetMastersSearchError = () => ({type: FETCH_GET_MASTERS_SEARCH_ERROR})
const fetchGetMastersSearchSuccess = data => ({type: FETCH_GET_MASTERS_SEARCH_SUCCESS, value: data})

export const getMastersSearch = params => {
    return dispatch => {
        dispatch(fetchGetMastersSearchRequest())
        return axios.get(ROUTE_MASTER_PAGE + `?search=${params}`)
            .then(response => {
                dispatch(fetchGetMastersSearchSuccess(response.data))
            })
            .catch(e => {
                dispatch(fetchGetMastersSearchError(e))
            })
    }
}

export const FETCH_GET_SALONS_SEARCH_REQUEST = 'FETCH_GET_SALONS_SEARCH_REQUEST'
export const FETCH_GET_SALONS_SEARCH_ERROR = 'FETCH_GET_SALONS_SEARCH_ERROR'
export const FETCH_GET_SALONS_SEARCH_SUCCESS = 'FETCH_GET_SALONS_SEARCH_SUCCESS'

const fetchGetSalonsSearchRequest = () => ({type: FETCH_GET_SALONS_SEARCH_REQUEST})
const fetchGetSalonsSearchError = () => ({type: FETCH_GET_SALONS_SEARCH_ERROR})
const fetchGetSalonsSearchSuccess = data => ({type: FETCH_GET_SALONS_SEARCH_SUCCESS, value: data})

export const getSalonsSearch = params => {
    return dispatch => {
        dispatch(fetchGetSalonsSearchRequest())
        return axios.get(ROUTE_SALON_PAGE + `?search=${params}`)
            .then(response => {
                dispatch(fetchGetSalonsSearchSuccess(response.data))
            })
            .catch(e => {
                dispatch(fetchGetSalonsSearchError(e))
            })
    }
}

export const FETCH_GET_SERVICES_SEARCH_REQUEST = 'FETCH_GET_SERVICES_SEARCH_REQUEST'
export const FETCH_GET_SERVICES_SEARCH_ERROR = 'FETCH_GET_SERVICES_SEARCH_ERROR'
export const FETCH_GET_SERVICES_SEARCH_SUCCESS = 'FETCH_GET_SERVICES_SEARCH_SUCCESS'

const fetchGetServicesSearchRequest = () => ({type: FETCH_GET_SERVICES_SEARCH_REQUEST})
const fetchGetServicesSearchError = () => ({type: FETCH_GET_SERVICES_SEARCH_ERROR})
const fetchGetServicesSearchSuccess = data => ({type: FETCH_GET_SERVICES_SEARCH_SUCCESS, value: data})

export const getServicesSearch = params => {
    return dispatch => {
        dispatch(fetchGetServicesSearchRequest())
        return axios.get(ROUTE_SERVICES + `?search=${params}`)
            .then(response => {
                dispatch(fetchGetServicesSearchSuccess(response.data))
            })
            .catch(e => {
                dispatch(fetchGetServicesSearchError(e))
            })
    }
}

export const SHOW_PAGE_SEARCH = 'SHOW_PAGE_SEARCH'

export const showSearchPage = () => ({type: SHOW_PAGE_SEARCH})

export const HIDE_PAGE_SEARCH = 'HIDE_PAGE_SEARCH'

export const hideSearchPage = () => ({type: HIDE_PAGE_SEARCH})