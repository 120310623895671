import {FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS} from "./servicesAction";

const initialState = {
    response: false,
    listCategories: []
}

export const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
            return {...state, response: true}
        case FETCH_CATEGORIES_SUCCESS:
            return {...state, response: false, listCategories: action.value}
        default: return state
    }
}

