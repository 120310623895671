import React, {useCallback, useEffect, useState} from 'react';

import './index.scss';
import SelectForm from "../../../components/kit/SelectForm/SelectForm";
import ArrowIcon from "../../../components/kit/Icons/ArrowIcon";
import CalendarIcon from "../../../components/kit/Icons/CalendarIcon";
import DatePicker from "react-datepicker";
import {convertDateWithMonthName} from "../../../global/Helpers/convertDateWithMonthName";
import moment from 'moment'
import {connect} from "react-redux";
import CalendarModal from "./CalendarModal";
import {fetchCalendarEvents} from "../../../store/calendar/calendarActions";
import CommonSpinner from "../../../components/kit/CommonSpinner/CommonSpinner";
import {fetchStaffsGet} from "../../../store/staff/staffActions";
import {APPLICATION_ACCEPTED, APPLICATION_REJECTED, ROLE_MASTER} from "../../../global/Constants/Constants";
import CustomEventCalendar from "./CustomEventCalendar";
import {fetchChangeApplicationStatus} from "../../../store/applications/applicationsAction";
import ReservationInfoModal from "../../../components/ReservationInfoModal/ReservationInfoModal";
import LargeCalendarIcon from "../../../components/kit/Icons/LargeCalendarIcon";
import {changeSalonAction} from "../../../store/core/coreActions";


const DAY_COUNT = 1;

const CustomInput = ({value, onClick}) => (
    <button className="calendar__datepicker-icon" onClick={onClick}>
        <CalendarIcon width={16} height={16} color="#0084F4"/>
    </button>
);


const EventCalendar = (
    {
        salons,
        salon,

        calendarEvents,
        requested,

        fetchCalendarEvents,

        fetchStaffsGet,
        staffsList,

        fetchChangeApplicationStatus,
        changeSalonAction,

        ...props
    }
) => {

    const [state, setState] = useState({
        salon: "",
        master: "*"
    })

    const [selectedItem, setItem] = useState({
        time: '',
        employee: null,
        salon: null,
        appointed_date: null
    })

    const [startDate, setStartDate] = useState(new Date());

    const [isOpen, toggleModal] = useState(false);

    const [reserveInfoId, setReserveInfoId] = useState(null)

    const [dateStr, setDateStr] = useState(startDate.toISOString().split('T').shift())

    const handleInputChange = useCallback(e => {
        setState(prevState => {
            return {...prevState, [e.target.name]: e.target.value}
        })
        if (e.target.name === 'salon') {
            const salon = salons.find(salon => salon.id === e.target.value)
            changeSalonAction(salon)
        }
    }, [changeSalonAction, salons])

    useEffect(function () {
        if (salons && salons[0]) {
            const salonIds = salons.map(salon => salon.id)

            if (props.match.params.salonId) {
                setState(prevState => {
                    return {...prevState, salon: props.match.params.salonId}
                })

                setStartDate(new Date(props.match.params.date))
            } else {
                setState(prevState => {
                    return {...prevState, salon: !!salon ? salon.id : salons[0].id}
                })
            }

            fetchStaffsGet({position: ROLE_MASTER}, salonIds)

        }
    }, [salons])


    useEffect(function () {
        const dateString = startDate.toISOString().split('T').shift();
        setDateStr(dateString)
        getEvents()
    }, [startDate, state.salon, state.master])

    const getEvents = useCallback(() => {
        const params = {
            salonId: state.salon,
            datetime: startDate,
            masterId: state.master !== "*" ? state.master : ''
        }

        if (state.salon) {
            fetchCalendarEvents(params)
        }
    }, [fetchCalendarEvents, startDate, state.master, state.salon])

    const handNextDay = useCallback(() => {
        const date = new Date(startDate);
        const nextDay = date.setDate(date.getDate() + DAY_COUNT)
        setStartDate(new Date(nextDay))
    }, [startDate])

    const handPrevDay = useCallback(() => {
        const date = new Date(startDate);
        const prevDay = date.setDate(date.getDate() - DAY_COUNT)
        setStartDate(new Date(prevDay))
    }, [startDate])

    const onSelectDay = useCallback(date => {
        setStartDate(new Date(date))
    }, [])

    const onApproveEvent = useCallback(async id => {
        await fetchChangeApplicationStatus(id, {is_confirmed: APPLICATION_ACCEPTED})
        await getEvents()
    }, [fetchChangeApplicationStatus, getEvents])

    const onRejectEvent = useCallback(async id => {
        await fetchChangeApplicationStatus(id, {is_confirmed: APPLICATION_REJECTED})
        getEvents()
    }, [fetchChangeApplicationStatus, getEvents])

    const onSelectApp = useCallback(appId => {
        setReserveInfoId(appId)
    }, [])


    const filterSalons = salons && salons.map(salon => {
        return {...salon, label: salon.name, id: salon.id}
    })

    const masterOptions = staffsList && Array.isArray(staffsList) && staffsList.map(staff => ({
        ...staff,
        label: `${staff.surname} ${staff.name}`
    }))

    const filteredOptions = masterOptions && masterOptions.filter(it => it.salon.includes(state.salon))


    const timeFinishStr = calendarEvents && calendarEvents.data && calendarEvents.data.work_finish;
    const timeStartStr = calendarEvents && calendarEvents.data && calendarEvents.data.work_start;

    const finishTimeAndDate = moment(dateStr + ' ' + timeFinishStr).toDate();
    const startTimeAndDate = moment(dateStr + ' ' + timeStartStr).toDate();

    const today = moment().toDate()

    const handleSelect = useCallback(({start, end, resourceId}) => {
        const startDate = moment(start);
        const startTime = moment(start).format("HH:mm")
        const startTimeWithSec = moment(start).format("HH:mm:ss")
        const endTimeWithSec = moment(end).format("HH:mm:ss")

        if (startDate.isBefore(today)) {
            return false
        }

        if (
            startTimeWithSec >= calendarEvents.data.break_start
            &&
            (endTimeWithSec <= calendarEvents.data.break_finish)
        ) {
            return false
        }

        setItem({
            time: startTime,
            employee: resourceId,
            salon: state.salon,
            appointed_date: startDate
        })
        toggleModal(true)
    }, [state.salon, today])

    const events = [];
    const resources = [];
    calendarEvents && calendarEvents.data && calendarEvents.data.result &&
    calendarEvents.data.result.forEach(master => {
        master.apps && master.apps.forEach(app => {
            if (app.is_confirmed !== APPLICATION_REJECTED) {
                const startDateWithTime = moment(dateStr + ' ' + app.appointed_time).toDate()
                const endDateWithTime = moment(dateStr + ' ' + app.finish_time).toDate()
                const obj = {
                    id: app.id,
                    title: app.name,
                    start: startDateWithTime,
                    end: endDateWithTime,
                    resourceId: master.master_id,
                    duration: app.duration,
                    total_sum: app.total_sum,
                    status: app.is_confirmed,
                    application: app,
                    onApprove: onApproveEvent,
                    onReject: onRejectEvent,
                    onSelectEvent: onSelectApp
                }
                events.push(obj)
            }

        })
        const obj = {
            resourceId: master.master_id,
            resourceTitle: master.master,
            apps: master.apps
        }
        resources.push(obj)
    })

    const closeModal = useCallback(() => {
        toggleModal(false)
        setItem({
            time: '',
            salon: null,
            employee: null,
            appointed_date: null
        })
    }, [])


    if (!calendarEvents || requested) {
        return <CommonSpinner isLoading={true} centered/>
    }

    return (
        <>
            {calendarEvents && (
                <div className="calendar">
                    <div className="calendar__header">

                        {filterSalons && (
                            <SelectForm
                                options={filterSalons}
                                className="calendar__select"
                                value={state.salon}
                                propertyName="salon"
                                handleChange={handleInputChange}
                            />
                        )}

                        {staffsList && filteredOptions && (
                            <SelectForm
                                defaultValue={{label: "Все специалисты", id: "*"}}
                                options={filteredOptions}
                                className="calendar__select"
                                value={state.master}
                                propertyName="master"
                                handleChange={handleInputChange}
                            />
                        )}

                        <div className="calendar__datepicker">
                            <button className="calendar__datepicker-arrow-btn" onClick={handPrevDay}>
                                <ArrowIcon width={16} height={16} color="#0084F4"/>
                            </button>
                            <span className="calendar__datepicker-date">
                        {convertDateWithMonthName(startDate)}
                    </span>
                            <button className="calendar__datepicker-arrow-btn"
                                    onClick={handNextDay}>
                                <ArrowIcon width={16} height={16} color="#0084F4"/>
                            </button>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={startDate}
                                onChange={onSelectDay}
                                className="admin-date-picker-root__date-field"
                                locale="ru"
                                customInput={<CustomInput/>}
                            />
                        </div>
                    </div>


                    {calendarEvents && calendarEvents.data && !calendarEvents.data.is_work && (
                        <div className="select-date-root__not-found" style={{marginTop: '40px', textAlign: 'center'}}>
                            <LargeCalendarIcon/>
                            <h3>Не рабочий день</h3>
                        </div>
                    )}

                    {!requested && calendarEvents && calendarEvents.data && calendarEvents.data.is_work && (
                        <CustomEventCalendar
                            events={events}
                            resources={resources}
                            startTimeAndDate={startTimeAndDate}
                            finishTimeAndDate={finishTimeAndDate}
                            date={startDate}
                            className="calendar-custom"
                            handleSelect={handleSelect}
                            breakStart={calendarEvents.data.break_start}
                            breakFinish={calendarEvents.data.break_finish}
                        />
                    )}
                </div>
            )}

            {isOpen && (
                <CalendarModal
                    isOpen={isOpen}
                    onClose={closeModal}
                    getEvents={getEvents}
                    initValues={selectedItem}/>
            )}

            {!!reserveInfoId && (
                <ReservationInfoModal
                    reserveInfoId={reserveInfoId}
                    isOpen={!!reserveInfoId}
                    handleClose={() => setReserveInfoId(null)}
                />
            )}
        </>
    );
};

const mapStateToProps = state => ({
    salons: state.core.salons,
    salon: state.core.salon,
    calendarEvents: state.calendar.calendarEvents,
    requested: state.calendar.requested,
    staffsList: state.staffs.staffsList
})

const mapDispatchToProps = dispatch => ({
    fetchCalendarEvents: params => dispatch(fetchCalendarEvents(params)),
    fetchStaffsGet: (params, salonIds) => dispatch(fetchStaffsGet(params, salonIds)),
    fetchChangeApplicationStatus: (applId, data) => dispatch(fetchChangeApplicationStatus(applId, data)),
    changeSalonAction: salon => dispatch(changeSalonAction(salon))
})

export default connect(mapStateToProps, mapDispatchToProps)(EventCalendar);