import React from 'react';

import './index.scss'

const AboutContact = ({number, gmail, location}) => {
    return (
        <div className="contact-salon information-salon">
            <h4>Контакты</h4>
            <ul>
                <li><a href={`tel:${number}`}>{number}</a></li>
                <li><a href={`mailto:${gmail}`}>{gmail}</a></li>
                <li>{location}</li>
            </ul>
        </div>
    );
};

export default AboutContact;