import React from 'react';
import './index.scss';
import Colors from "../../global/styles/Colors";
import CheckIcon from "../kit/Icons/CheckIcon";
import Button from "@material-ui/core/Button";

const SwalModal = ({title, subTitle, swal}) => {
    return (
        <div className="swal-modal-root" style={{backgroundColor: Colors.NEUTRAL['white']}}>
            <div className="swal-modal-root__icon" style={{backgroundColor: Colors.PRIMARY['purple_3']}}>
                <CheckIcon width={44} height={44} color={Colors.PRIMARY['purple']}/>
            </div>
            <h1 className="swal-modal-root__title" style={{color: Colors.NEUTRAL['black']}}>{title}</h1>
            <p className="swal-modal-root__sub-title" style={{color: Colors.TEXT['text_color']}}>
                {subTitle}
            </p>
            <button
                style={
                    {
                        color: Colors.NEUTRAL['white'],
                        backgroundColor: Colors.SEMANTIC['green']
                    }
                }
                onClick={() => swal.close()}
                className="swal-modal-root__btn">
                Ok
            </button>
        </div>
    );
};

export default SwalModal;