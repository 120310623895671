import React from 'react';

const CalendarIcon = ({color, width, height, className, opacity}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 20 22"
             fill="none" opacity={opacity}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3 4C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H17C17.5523 20 18 19.5523 18 19V5C18 4.44772 17.5523 4 17 4H3ZM0 5C0 3.34315 1.34315 2 3 2H17C18.6569 2 20 3.34315 20 5V19C20 20.6569 18.6569 22 17 22H3C1.34315 22 0 20.6569 0 19V5Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14 0C14.5523 0 15 0.447715 15 1V5C15 5.55228 14.5523 6 14 6C13.4477 6 13 5.55228 13 5V1C13 0.447715 13.4477 0 14 0Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6 0C6.55228 0 7 0.447715 7 1V5C7 5.55228 6.55228 6 6 6C5.44772 6 5 5.55228 5 5V1C5 0.447715 5.44772 0 6 0Z"
                  fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 9C0 8.44771 0.447715 8 1 8H19C19.5523 8 20 8.44771 20 9C20 9.55228 19.5523 10 19 10H1C0.447715 10 0 9.55228 0 9Z"
                  fill={color}/>
        </svg>
    );
};

export default CalendarIcon;