import React from "react";
import axios from "../Api/axios-api";
import { ROUTE_SERVICES } from "../Api/Routes";
import { NotificationManager } from "react-notifications";
import { ERROR_TEXT } from "../../global/Constants/Constants";
import SwalModal from "../../components/SwalModal/SwalModal";
import swal from "@sweetalert/with-react";
import { INITIAL_PAGE } from "../../containers/Pages/Applications/Applications";

export const FETCH_SERVICES_REQUEST = 'FETCH_SERVICES_REQUEST';
export const FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS';
export const FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE';
const fetchServicesRequest = () => ({type: FETCH_SERVICES_REQUEST})
const fetchServicesSuccess = services => ({type: FETCH_SERVICES_SUCCESS, services})
const fetchServicesFailure = () => ({type: FETCH_SERVICES_FAILURE})

export const fetchServicesGet = (page = 1, search, salonId) => {
  if (salonId) {

    const params = `?page=${ page }&search=${ search || "" }&salon=${ salonId }`

    return dispatch => {
      dispatch(fetchServicesRequest())
      return axios.get(ROUTE_SERVICES + params).then(
        response => {
          dispatch(fetchServicesSuccess(response.data))
        },
        () => {
          dispatch(fetchServicesFailure())
          NotificationManager.error(ERROR_TEXT)
        }
      ).catch(err => console.log(err))
    }
  }
}

export const FETCH_CREATE_SERVICE = 'FETCH_CREATE_SERVICE';
const createService = () => ({type: FETCH_CREATE_SERVICE})
export const fetchCreateService = data => {
  return dispatch => {
    dispatch(fetchServicesRequest())
    return axios.post(ROUTE_SERVICES, data).then(
      () => {
        dispatch(createService())
        const el = <SwalModal title="Успешно добавлено!" subTitle={ `Вы добавили услугу ${ data.name } успешно!` }
                              swal={ swal }/>;
        swal({buttons: false, content: el});
      },
      () => {
        dispatch(createService())
        NotificationManager.error(ERROR_TEXT)
      }
    ).catch(err => console.log(err))
  }
}

export const fetchEditService = (serviceId, data) => {
  return dispatch => {
    dispatch(fetchServicesRequest())
    return axios.put(ROUTE_SERVICES + serviceId + "/", data).then(
      () => {
        dispatch(createService())
        NotificationManager.info("Услуга успешно изменена")
      },
      () => {
        dispatch(createService())
        NotificationManager.error(ERROR_TEXT)
      }
    ).catch(err => console.log(err))
  }
}

export const fetchRemoveService = serviceId => {
  return dispatch => {
    return axios.delete(ROUTE_SERVICES + serviceId + "/").then(
      () => {
        NotificationManager.info("Услуга успешно удалена")
        dispatch(fetchServicesGet(INITIAL_PAGE))
      },
      () => {
        NotificationManager.error(ERROR_TEXT)
      }
    ).catch(err => console.log(err))
  }
}