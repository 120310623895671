import React from 'react';

const NoResultIcon = ({width = 100, height = 140}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 131 131">
            <g fill="none" fillRule="evenodd" transform="rotate(30 41.84 79.16)">
                <path fill="#70DAFF" d="M73.8 20L50 20 50 20 14 56 49.5 91.5 97.4 43.6z"
                      transform="rotate(-45 55.7 55.75)"/>
                <circle cx="22.689" cy="68.67" r="7.5" fill="#6C00BF" transform="matrix(0 1 1 0 -45.98 45.98)"/>
                <path fill="#011054"
                      d="M30.19 68.67c0 2.783-1.517 5.212-3.767 6.506l.02-13c2.24 1.297 3.746 3.72 3.746 6.494z"/>
                <path fill="#6C00BF"
                      d="M26.39 34.501l16.971-16.97V34.5h-16.97zM83.041 64.028l.031-.012-.323.116c.158-.054.314-.111.469-.17l.236-.094.088-.036.263-.112.1-.045c.108-.048.215-.097.32-.148l.05-.024c.102-.05.203-.1.303-.152l.03-.015c.323-.167.638-.347.943-.537l.128-.08c.083-.054.165-.107.246-.162l.032-.021c.095-.064.189-.129.281-.195l.03-.022.091-.065c.058-.042.115-.084.171-.127l.128-.096.151-.12.1-.079c.09-.072.178-.146.265-.22l.049-.042c.07-.06.141-.123.21-.185l.1-.09.167-.155.082-.078c.057-.055.114-.11.17-.167.052-.051.103-.103.153-.155l.156-.162.067-.072c.328-.353.636-.723.923-1.106l.016-.022 11.382 5.55 2.247-4.606-11.381-5.551.017-.062c.09-.335.167-.673.23-1.015l.02-.103.037-.214.024-.156c.033-.213.06-.428.083-.644l.017-.175.012-.145.014-.18c.022-.334.032-.67.03-1.008l-.003-.152c0-.062-.002-.124-.004-.185l-.011-.262c-.01-.195-.025-.39-.043-.584l-.015-.15c-.065-.616-.173-1.233-.326-1.848l-.004-.018-.02-.077c-.023-.093-.048-.185-.073-.277l-.026-.091c-.023-.082-.047-.163-.072-.244l-.046-.148c-.035-.11-.07-.218-.108-.327-2.465-7.16-10.267-10.964-17.426-8.5-7.16 2.466-10.965 10.268-8.5 17.427 2.466 7.159 10.268 10.964 17.427 8.5z"/>
                <path fill="#011054"
                      d="M76.6 64.778c-5.03-.626-9.516-4.03-11.277-9.145-2.466-7.16 1.34-14.961 8.499-17.426.928-.32 1.866-.534 2.802-.65L76.6 64.779z"/>
            </g>
        </svg>
    );
};

export default NoResultIcon;