import React from 'react';

const FolderIcon = ({color, width, height, className, opacity}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 38 34"
             fill="none" opacity={opacity}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.6665 3.66659C5.22448 3.66659 4.80055 3.84218 4.48799 4.15474C4.17543 4.4673 3.99984 4.89122 3.99984 5.33325V28.6666C3.99984 29.1086 4.17543 29.5325 4.48799 29.8451C4.80055 30.1577 5.22448 30.3333 5.6665 30.3333H32.3332C32.7752 30.3333 33.1991 30.1577 33.5117 29.8451C33.8242 29.5325 33.9998 29.1086 33.9998 28.6666V10.3333C33.9998 9.89123 33.8242 9.4673 33.5117 9.15474C33.1991 8.84218 32.7752 8.66659 32.3332 8.66659H17.3332C16.7759 8.66659 16.2555 8.38808 15.9464 7.92442L13.1079 3.66659H5.6665ZM2.13097 1.79772C3.06865 0.860036 4.34042 0.333252 5.6665 0.333252H13.9998C14.5571 0.333252 15.0775 0.611754 15.3866 1.07542L18.2251 5.33325H32.3332C33.6593 5.33325 34.931 5.86004 35.8687 6.79772C36.8064 7.7354 37.3332 9.00717 37.3332 10.3333V28.6666C37.3332 29.9927 36.8064 31.2644 35.8687 32.2021C34.931 33.1398 33.6593 33.6666 32.3332 33.6666H5.6665C4.34042 33.6666 3.06865 33.1398 2.13097 32.2021C1.19329 31.2644 0.666504 29.9927 0.666504 28.6666V5.33325C0.666504 4.00717 1.19329 2.7354 2.13097 1.79772Z"
                  fill={color}/>
        </svg>
    );
};

export default FolderIcon;