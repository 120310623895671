import {
    FETCH_CALENDAR_EVENTS_GET,
    FETCH_CALENDAR_EVENTS_REQUEST,
    FETCH_GET_AVAILABLE_CATEGORIES_SUCCESS
} from "./calendarActions";

const initialState = {
    requested: false,
    calendarEvents: null,

    availableCategories: false
};

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALENDAR_EVENTS_REQUEST:
            return {...state, requested: true, availableCategories: null}
        case FETCH_CALENDAR_EVENTS_GET:
            return {...state, calendarEvents: action.events, requested: false}
        case FETCH_GET_AVAILABLE_CATEGORIES_SUCCESS:
            return {...state, availableCategories: action.categories}
        default:
            return state;
    }
};

export default calendarReducer;
