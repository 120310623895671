import {FETCH_GET_FILTER_MASTER_REQUEST, FETCH_GET_FILTER_MASTER_SUCCESS} from "./filterMasterAction";

const initialState = {
    response: false,
    listMaster: null,

    paginationResponse: false,
    nextPage: null,
    previousPage: null,
}

export const filterPageMasterReducer = (state = initialState, action) => {
    switch (action.type){
        case FETCH_GET_FILTER_MASTER_REQUEST:
            return {...state, response: true}
        case FETCH_GET_FILTER_MASTER_SUCCESS:
            return {...state, response: false, listMaster: action.value }
        default: return state
    }
}