import React from 'react';
import Colors from "../../global/styles/Colors";
import FolderIcon from "../kit/Icons/FolderIcon";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";


const styles = theme => ({
    button: {
        backgroundColor: Colors.SEMANTIC['pink'],
        color: Colors.NEUTRAL['white'],
        borderRadius: '6px',
        padding: '14px 100px',
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'inherit',
        marginTop: '30px'
    }
});

const CreateBlock = ({title, subTitle, buttonText = 'Добавить', onSubmit, ...props}) => {
    const {classes} = props;
    return (
        <div className="dashboard-page-root__create-block">
            <div className="dashboard-page-root__create-block__folder"
                 style={{border: `1px dashed ${Colors.TEXT['icon']}`}}
            >
                <FolderIcon color={Colors.TEXT['icon']} width={36} height={33}/>
            </div>
            <h3 className="dashboard-page-root__create-block__title"
                style={{color: Colors.NEUTRAL['black']}}>
                {title}
            </h3>
            <p className="dashboard-page-root__create-block__subTitle"
               style={{color: Colors.TEXT['text_color']}}>
                {subTitle}
            </p>
            {onSubmit &&
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={onSubmit}
            >
                {buttonText}
            </Button>
            }
        </div>
    );
};

export default withStyles(styles)(CreateBlock);