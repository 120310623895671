import axios from '../Api/axios-api';
import {ROUTE_APPLICATIONS, ROUTE_CALENDAR_EVENTS, ROUTE_FREE_TIME_GET} from "../Api/Routes";
import {dateFormatter} from "../../global/Helpers/helpers";
import {NotificationManager} from "react-notifications";
import {ERROR_TEXT} from "../../global/Constants/Constants";
import SwalModal from "../../components/SwalModal/SwalModal";
import swal from "@sweetalert/with-react";
import React from "react";

export const FETCH_CALENDAR_EVENTS_REQUEST = 'FETCH_CALENDAR_EVENTS_REQUEST'
export const FETCH_CALENDAR_EVENTS_GET = 'FETCH_CALENDAR_EVENTS_GET'
const fetchCalendarEventsRequest = () => ({type: FETCH_CALENDAR_EVENTS_REQUEST})
const fetchCalendarEventsSuccess = events => ({type: FETCH_CALENDAR_EVENTS_GET, events});
export const fetchCalendarEvents = (params) => {
    return dispatch => {
        dispatch(fetchCalendarEventsRequest())
        const url = `?date=${dateFormatter(params.datetime)}&master=${params.masterId || ""}`
        return axios.get(ROUTE_CALENDAR_EVENTS + params.salonId + "/" + url).then(
            response => {
                dispatch(fetchCalendarEventsSuccess(response.data))
            }
        ).catch(err => console.log(err))
    }
}


export const FETCH_GET_AVAILABLE_CATEGORIES_SUCCESS = 'FETCH_GET_AVAILABLE_CATEGORIES_SUCCESS';
const fetchGetAvailableCategoriesSuccess = categories => ({type: FETCH_GET_AVAILABLE_CATEGORIES_SUCCESS, categories})
export const fetchGetAvailableCategories = (data) => {
    return dispatch => {
        return axios.post(ROUTE_FREE_TIME_GET, data).then(
            (response) => {
                dispatch(fetchGetAvailableCategoriesSuccess(response.data))
            },
            (error) => {
                dispatch(fetchGetAvailableCategoriesSuccess(null))
                NotificationManager.error((error.response && JSON.stringify(error.response.data)) || ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const fetchCreateApplication = data => {
    return dispatch => {
        return axios.post(ROUTE_APPLICATIONS, data).then(
            () => {
                const el = <SwalModal title="Успешно записан!"
                                      subTitle={`Вы записали клиента успешно`}
                                      swal={swal}/>;
                swal({buttons: false, content: el});
            },
            (error) => {
                NotificationManager.error(error.response.data && error.response.data.message)
            }
        ).catch(err => console.log(err))
    }
}