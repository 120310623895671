import React from 'react';

const WomanIcon = ({width, height, color, className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 16 16"
             fill={color}>
            <path
                d="M13.162 12.03L10.608 11.3913L10.4607 10.8013C12.2227 10.5047 13.1293 9.97669 13.1693 9.95334C13.264 9.898 13.3227 9.79734 13.3307 9.688C13.338 9.57866 13.2893 9.47134 13.2033 9.40266C13.1913 9.39334 12 8.40934 12 5C12 2.12466 11.3273 0.666656 10 0.666656H9.80334C9.32466 0.204 8.96534 0 8 0C6.74734 0 4 1.25934 4 5C4 8.40934 2.80866 9.39334 2.8 9.4C2.71134 9.46666 2.66134 9.57266 2.66666 9.68334C2.67266 9.79469 2.73266 9.89469 2.828 9.952C2.868 9.976 3.766 10.5067 5.53866 10.8027L5.39131 11.3913L2.83734 12.03C1.16666 12.448 0 13.9427 0 15.6667C0 15.8507 0.149344 16 0.333344 16H15.6667C15.8507 16 16 15.8493 16 15.6653C16 13.9427 14.8333 12.448 13.162 12.03Z"
                fill={color}/>
        </svg>
    );
};

export default WomanIcon;