import React from 'react';
import topImage from '../../../../assets/dybenko-095.png';
import radiance_logo from '../../../../assets/radiance_logo.png';
import Colors from "../../../../global/styles/Colors";
import ScissorsIon from "../../../../components/kit/Icons/ScissorsIon";
import WomanIcon from "../../../../components/kit/Icons/WomanIcon";
import { STEP_LOADING } from "../OnlineRecord";
import GeneralButton from "../../../../components/kit/GeneralButton/GeneralButton";
import ServiceIcon from "../../../../components/kit/Icons/ServiceIcon";
import ArrowIcon from "../../../../components/kit/Icons/ArrowIcon";
import ArrowLargeIcon from "../../../../components/kit/Icons/ArrowLargeIcon";
import {Navigate} from "react-big-calendar";
import {NavLink} from "react-router-dom";

const SelectBranches = ({ onSelect, onSelectId, salon, clientRecords, handleBackNav}) => {

    function onSelectBranches(id) {
        onSelect(STEP_LOADING)
        onSelectId(id)
    }

    return (
        <div className="select-service-root">
            <div className="select-service-root__top-info">
                <div style={{backgroundImage: `url(${salon.background || topImage})`}} className="select-service-root__top-info__top-image" />
                <img src={salon.logo || radiance_logo} alt="img"
                    className="select-service-root__top-info__company_logo" />
            </div>
            <h1 className="select-service-root__company_title" style={{ color: Colors.NEUTRAL['black'],
                position: 'relative' }}>
                {salon.name}
                <div onClick={handleBackNav} className="arrow" style={{position: 'absolute', left: '0', top: '0'}}>
                    <ArrowLargeIcon/>
                </div>
            </h1>
            <h4 className="select-service-root__company_address" style={{ color: Colors.TEXT['text_color'] }}>
                {salon.address}
            </h4>
            <div className="select-service-root__service-variant--branches">
                {salon.filials &&
                <div className="select-service-root__items">
                    {
                        salon.filials.map((item, index) => {
                            return (
                                <div className="select-service-root__service-variant--branches__item"
                                     onClick={() => onSelectBranches(item.id)}
                                    key={index}
                                >
                                    <div className="select-service-root__service-variant--branches__logo"
                                         style={{backgroundColor: Colors.SEMANTIC['pink']}}>
                                        <img src={item.logo || salon.logo || radiance_logo} alt="img"/>
                                    </div>
                                    <p className="select-service-root__service-variant--branches__title"
                                       style={{color: Colors.NEUTRAL['black']}}>
                                        <span>{item.name}</span>
                                        <span>{item.address}</span>
                                    </p>
                                    <span className="choose-master-root__arrow-icon">
                                        <ArrowIcon
                                            width={6}
                                            height={11}
                                            color={Colors.PRIMARY['purple']}/>
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                }
            </div>

        </div>
    );
};

export default SelectBranches;