import {
    FETCH_CATEGORIES_GET,
    FETCH_SALON_FAILURE,
    FETCH_SALON_REQUEST,
    FETCH_SALON_TIME_SUCCESS,
    FETCH_SALONS_GET,
    FETCH_SERVICES_GET,
    FETCH_SALON_SUCCESS,
    FETCH_SERVICES_REQUEST,
    FETCH_MASTER_AVAILABLE_REQUEST,
    FETCH_MASTER_AVAILABLE_TIMES,
    FETCH_CLEAR_SALON, CHANGE_SALON_ACTION,
    BTN_ACTIVE_SALON,
    BTN_ACTIVE_MASTER,
    SHOW_FILTER, HIDE_FILTER, FETCH_GET_BALANCE_REQUEST, FETCH_GET_BALANCE_SUCCESS, FETCH_GET_BALANCE_FAIL, FETCH_DATE_GET, FETCH_CURRENT_TARRIF, FETCH_AVIABLE_TARRIFS
} from "./coreActions";

const initialState = {
    salons: null,

    requested: false,
    salon: null,

    categories: null,

    serviceRequested: false,
    services: null,

    salonTimes: null,

    masterTimesRequested: false,
    masterTimes: null,

    balance: null,
    requestBalance: false,
    errorBalance: false,

    btnActive: 0,
    showFilter: false,
    // Страница Настройки-Тарифы
    avalibale_tarifs: null,
    current_tarif: null,
};

const coreReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SALONS_GET:
            const convertSalons = action.salons && action.salons.my_salons && action.salons.my_salons.map(salon => {
                return { ...salon, label: salon.name }
            })
            return { ...state, salons: convertSalons, salon: convertSalons[0] }
        case FETCH_CATEGORIES_GET:
            const convertCategories = action.categories && action.categories.map(category => {
                return { ...category, label: category.name }
            })
            return { ...state, categories: convertCategories }

        case FETCH_SERVICES_REQUEST:
            return { ...state, serviceRequested: true }
        case FETCH_DATE_GET:
            const days = action?.dates?.days || []
            return { ...state, days }
        case FETCH_SERVICES_GET:
            const convertServices = action.services && action.services.map(service => {
                return { ...service, label: service.name }
            })
            return { ...state, services: convertServices, serviceRequested: false }

        case FETCH_CLEAR_SALON:
            return { ...state, salon: null }
        case FETCH_SALON_REQUEST:
            return { ...state, requested: true };
        case FETCH_SALON_SUCCESS:
            return { ...state, requested: false, salon: action.salon }
        case FETCH_SALON_FAILURE:
            return { ...state, requested: false }

        case FETCH_SALON_TIME_SUCCESS:
            return { ...state, salonTimes: action.times, requested: false }

        case FETCH_MASTER_AVAILABLE_REQUEST:
            return { ...state, masterTimesRequested: true }
        case FETCH_MASTER_AVAILABLE_TIMES:
            return { ...state, masterTimes: action.times, masterTimesRequested: false }

        case FETCH_GET_BALANCE_REQUEST:
            return { ...state, requestBalance: true }
        case FETCH_GET_BALANCE_SUCCESS:
            return { ...state, requestBalance: false, balance: action.balance }
        case FETCH_GET_BALANCE_FAIL:
            return { ...state, requestBalance: false, errorBalance: true }

        case CHANGE_SALON_ACTION:
            return { ...state, salon: action.salon }
        case BTN_ACTIVE_MASTER:
            return { ...state, btnActive: 0 }
        case BTN_ACTIVE_SALON:
            return { ...state, btnActive: 1 }
        case SHOW_FILTER:
            return { ...state, showFilter: true }
        case HIDE_FILTER:
            return { ...state, showFilter: false }
        case FETCH_CURRENT_TARRIF:
            return { ...state, current_tarif: action.currentTarif }
        case FETCH_AVIABLE_TARRIFS:
            return { ...state, avalibale_tarifs: action.aviable_tarifs }
        default:
            return state;
    }
};

export default coreReducer;
