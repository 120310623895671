import React, { useEffect } from 'react'
import './index.scss';
import Carousel from 'react-multi-carousel';

import CommonSpinner from '../../../components/kit/CommonSpinner/CommonSpinner';
import TitleBlockMainPage from '../MainPage/TitleBlockMainPage/TitleBlockMainPage';
import { connect } from "react-redux";
import { fetchAviableTarifsGet, fetchChangeTarif, fetchCurrnetTarifGet } from '../../../store/core/coreActions';
import { Button } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';

const RESPONSIVE_CAROUSEL_SERVICES = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4.3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2.2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1.2
    }
};







const Tarifs = ({ fetchCurrnetTarifGet, avalibale_tarifs, current_tarif, fetchAviableTarifs, salon, fetchChangeTarif, ...props }) => {
    const salonId = props.match.params.salonId


    const [popUp, setPopUp] = useState({
        loading: false,
        show: false,
        text: null

    })


    const changeTarif = async (tarifId) => {
        setPopUp((prev) => ({ ...prev, loading: true }))
        const currentData = {
            tariff: tarifId,
            salon: salonId,
        }
        try {
            const { data } = await fetchChangeTarif(currentData)
            setPopUp((prev) => ({ ...prev, loading: false, show: true, text: data }))
        } catch {

        } finally {
            setPopUp((prev) => ({ ...prev, loading: false }))
        }
    }

    useEffect(() => {
        fetchCurrnetTarifGet(salonId)
        fetchAviableTarifs(salonId)
    }, [])

    if (popUp.loading || !avalibale_tarifs || !current_tarif) {
        return <CommonSpinner isLoading={popUp.loading} centered />
    }
    return (
        <>
            {popUp?.show &&
                <> <div onClick={() => setPopUp((prev) => ({ ...prev, show: false }))} className="popUpWrapper">

                    <div className='block' >
                        <p>{popUp?.text?.message}</p>
                        <Button className="settings-root__add_branch" variant="contained" onClick={() =>
                            window.open(popUp?.text?.payment_link, "_blank")}>
                            Оплатить
                        </Button>
                    </div>
                </div></>
            }
            <div className='tarifs'>

                <p className="main-title-header">Действующий тариф</p>
                <div className="current-tarif">
                    <h3>{current_tarif?.name}</h3>
                    <ul>
                        <li>цена за месяц - <b>{current_tarif?.month_price}</b></li>
                        <li>цена за год - <b>{current_tarif?.year_price}</b></li>
                        <li>макс кол-во сотрудников - <b>{current_tarif?.max_employee}</b></li>
                        <li>макс кол-во филиалов - <b>{current_tarif?.max_filials}</b></li>
                        {current_tarif?.is_mailing && <li className='cuption'>рассылка СМС/Whatsapp уведомлений клиентам</li>}
                    </ul>
                </div>



                <div className='tarifs__extra'>
                    <p className="main-title-header">Доступные тарифы</p>
                    <div className="list-tarifs">
                        <div className="salon-item" >
                            <Carousel
                                responsive={RESPONSIVE_CAROUSEL_SERVICES}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                infinite={true}
                            >
                                {avalibale_tarifs?.map((item, index) => {
                                    return (
                                        <div className="salon-item-link"
                                            to="/"
                                            key={index}>
                                            <h3 className="salon-item-name">{item.name}</h3>
                                            <div className="salons-item-rating">
                                                <span className="salon-cuption">цена за месяц - <b>{item?.month_price}</b></span>
                                                <span className="salon-cuption">цена за год- <b>{item?.year_price}</b></span>
                                                <span className="salon-cuption">макс кол-во сотрудников - <b>{item?.max_employee}</b></span>
                                                <span className="salon-cuption" >макс кол-во филиалов- <b>{item?.max_filials}</b></span>
                                                {item?.is_mailing && <span className="salon-rating" >рассылка СМС/Whatsapp уведомлений клиентам</span>}
                                                <Button className="settings-root__add_branch" variant="contained" onClick={() => changeTarif(item?.id)}>
                                                    Подключить
                                                </Button>
                                            </div>
                                        </div>

                                    )
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div></>
    )
}

// export default Tarifs
const mapStateToProps = state => ({
    current_tarif: state.core.current_tarif,
    avalibale_tarifs: state.core.avalibale_tarifs,
    salon: state.core.salon,
})

const mapDispatchToProps = dispatch => ({
    fetchCurrnetTarifGet: (salonId) => dispatch(fetchCurrnetTarifGet(salonId)),
    fetchAviableTarifs: (salonId) => dispatch(fetchAviableTarifsGet(salonId)),
    fetchChangeTarif: salonId => dispatch(fetchChangeTarif(salonId))

})

export default connect(mapStateToProps, mapDispatchToProps)(Tarifs);