import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {LINK_ABOUT_SALON_DEFAULT, LINK_ABOUT_SALON} from "../../../../global/Helpers/Links";
import './index.scss'

const PlaceWork = ({salon, defaultCheck}) => {

    const [salonDetail, setSalon] = useState()

    useEffect(() => {
        if(salon?.length){
            setSalon(salon[0])
        }
    }, [salon])

    return (
            <div className="place-work information-salon">
                <h4>Место работы</h4>

                <NavLink
                    to={defaultCheck ? LINK_ABOUT_SALON_DEFAULT
                        + salonDetail?.id : LINK_ABOUT_SALON + salonDetail?.id}
                >
                    <div className="place-work-information">
                        {salonDetail?.logo ?
                            <img src={salonDetail?.logo} alt=""/>
                        :
                            <div className="none-logo">
                                <span>{salonDetail?.name && salonDetail?.name[0]}</span>
                            </div>
                        }

                        <div className="place-work-description">
                            <span>{salonDetail?.main_category}</span>
                            <p>{salonDetail?.name}</p>
                        </div>

                    </div>
                </NavLink>

            </div>
    );
};

export default PlaceWork;