import React from 'react';

import './index.scss'

const AboutTitle = ({img, title, rating, reviews}) => {
    return (
        <div className="about-title-block information-salon" >
            <div className="about-image">
                {img ?
                    <img src={img} alt=""/>
                :
                    <div className="none-logo">
                        <span>{title && title[0]}</span>
                    </div>
                }
            </div>

            <div className="about-text">
                <h4>{title}</h4>
                <div>

                    <span className="rating">
                        {rating ? rating : '0'}
                    </span>

                    <span className="reviews">
                        {reviews ? reviews.length : '0'}
                    </span>

                </div>

            </div>

        </div>
    );
};

export default AboutTitle;