import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux'

import Arrow from "../../../../components/kit/Arrow/Arrow";
import SelectionButtons from "../../../../components/kit/SelectionButtons/SelectionButtons";
import AboutTitle from "../../../../components/kit/About/AboutTitle/AboutTitle";
import AboutContact from "../../../../components/kit/About/AboutContact/AboutContact";
import Description from "../../../../components/kit/About/Description/Description";
import Reviews from "../../../../components/kit/About/Reviews/Reviews";
import Services from "../../../../components/kit/About/Services/Services";
import PlaceWork from "../../../../components/kit/About/PlaceWork/PlaceWork";
import LoadingComponent from "../../OnlineRecord/LoadingComponent/LoadingComponent";
import {getMasterDetail} from '../../../../store/Main/detailMaster/detailedMasterAction'

import './index.scss'

const AboutMaster = ({detailMaster, getSalonDetail, paramsId, history, defaultCheck}) => {

    const [btnActive, setActive] = useState(0)
    const {response, masterDetail} = detailMaster

    useEffect(() => {
        getSalonDetail(paramsId)
    }, [])

    if(response) return <LoadingComponent />

    return (
        <div className="container">
            <div className="online-record-root">

                <Arrow
                    title="О мастере"
                    history={history}
                />

                <SelectionButtons
                    titles={['О мастере', 'Услуги']}
                    btnActive={btnActive}
                    setActive={setActive}
                />

                {!btnActive ?
                    <>
                        <AboutTitle
                            img={masterDetail?.image}
                            title={masterDetail?.name}
                            rating={masterDetail?.rating}
                            reviews={masterDetail?.reviews}
                        />

                        <AboutContact
                            number={masterDetail?.number}
                            // gmail={'aigerim@gmail.com'}
                        />

                        <PlaceWork
                            defaultCheck={defaultCheck}
                            salon={masterDetail?.salon}
                        />

                        <Description
                            title="О себе"
                            text={masterDetail?.description}
                        />

                        <Reviews
                            count={masterDetail?.reviews?.length}
                        />
                    </>

                    :

                    <Services
                        services={masterDetail?.service_list}
                    />
                }

            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getSalonDetail: (id) => {dispatch(getMasterDetail(id))}
})

export default connect(mapStateToProps, mapDispatchToProps)(AboutMaster);