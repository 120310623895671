import React from 'react';

import {NavLink} from "react-router-dom";

import './index.scss'

const ListServices = ({list, link}) => {

    if(!list?.count){
        return (
            <div className="load-page">
                <div className="lds-dual-ring"/>
            </div>
        )
    }

    return (
        <div className="wrapper-services-list">
            {list?.results.map(item => (
                <div className="services-list"
                     key={item.id}
                >
                    <NavLink to={link + item.salon.id}>
                        <div className="services-list__salon">
                            <div className="services-list__salon__img">
                                <img src={item.salon.logo} alt=""/>
                            </div>

                            <div className="services-list__salon__name">
                                {item.salon_name}
                            </div>
                        </div>

                        <div className="services-list__service">
                            <div className="services-list__service__category">
                                <p>Категория: {item.category_name}</p>

                                <p>Услуга: {item.name}</p>
                            </div>

                            <div className="services-list__service__category">
                                <p>Время: {item.time}</p>

                                <p>Стоимость: {item.price} сом</p>
                            </div>
                        </div>
                    </NavLink>
                </div>
            ))}
        </div>
    );
};

export default ListServices;