import React from 'react';
import {NavLink} from "react-router-dom";

const TitleBlockMainPage = ({title, link}) => {

    return (
        <div className="title-list">
            <h3>{title}</h3>

            <NavLink
                className="link-main-page"
                to={link}
            >
                См. все
            </NavLink>
        </div>
    );

};

export default TitleBlockMainPage;