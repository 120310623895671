import axios from "../Api/axios-api";
import {baseUrl, ROUTE_APPLICATION_CREATE, ROUTE_PAY_MBANK_METHOD, ROUTE_PAY_METHOD} from "../Api/Routes";
import {history} from "../configureStore";
import {NotificationManager} from "react-notifications";
import {ERROR_TEXT} from "../../global/Constants/Constants";
import axiosBase from "axios";

export const FETCH_SALON_PAY_METHOD_REQUEST = 'FETCH_SALON_PAY_METHOD_REQUEST';
export const FETCH_SALON_PAY_METHOD_SUCCESS = 'FETCH_SALON_PAY_METHOD_SUCCESS';
export const FETCH_SALON_PAY_METHOD_FAILURE = 'FETCH_SALON_PAY_METHOD_FAILURE';
const fetchSalonPayMethodRequest = () => ({type: FETCH_SALON_PAY_METHOD_REQUEST})
const fetchSalonGetPayMethodSuccess = payMethod => ({type: FETCH_SALON_PAY_METHOD_SUCCESS, payMethod});
const fetchSalonPayMethodFailure = () => ({type: FETCH_SALON_PAY_METHOD_FAILURE})

export const fetchSalonGetPayMethod = (salonId) => {
    return dispatch => {
        dispatch(fetchSalonPayMethodRequest())
        return axios.get(ROUTE_PAY_METHOD + '?salon=' + salonId).then(
            response => {
                dispatch(fetchSalonGetPayMethodSuccess(response.data))
            },
            () => {
                dispatch(fetchSalonPayMethodFailure())
                history.push('/')
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

export const FETCH_SALON_PAY_METHOD_MBANK_REQUEST = 'FETCH_SALON_PAY_METHOD_MBANK_REQUEST';
export const FETCH_SALON_PAY_METHOD_MBANK_SUCCESS = 'FETCH_SALON_PAY_METHOD_MBANK_SUCCESS';
export const FETCH_SALON_PAY_METHOD_MBANK_FAILURE = 'FETCH_SALON_PAY_METHOD_MBANK_FAILURE';
const fetchSalonPayMethodMBankRequest = () => ({type: FETCH_SALON_PAY_METHOD_MBANK_REQUEST})
const fetchSalonGetPayMethodMBankSuccess = payMethod => ({type: FETCH_SALON_PAY_METHOD_MBANK_SUCCESS, payMethod});
const fetchSalonPayMethodMBankFailure = () => ({type: FETCH_SALON_PAY_METHOD_MBANK_FAILURE})

export const fetchSalonPostPayMethod = (applicationId, phoneNumber) => {
    return dispatch => {
        dispatch(fetchSalonPayMethodMBankRequest())
        const data = {application_id: applicationId, phone_number: phoneNumber}
        return axios.post(ROUTE_PAY_MBANK_METHOD, data).then(
            response => {
                dispatch(fetchSalonGetPayMethodMBankSuccess(response.data))
            },
            () => {
                dispatch(fetchSalonPayMethodMBankFailure())
                history.push('/')
                NotificationManager.error(ERROR_TEXT)
            }
        ).catch(err => console.log(err))
    }
}

//create application with mBank
export const FETCH_CREATE_APPLICATIONS_SUCCESS = 'FETCH_CREATE_APPLICATIONS_SUCCESS';
export const FETCH_CREATE_APPLICATIONS_CLOSE = 'FETCH_CREATE_APPLICATIONS_CLOSE';
const fetchCreateApplicationSuccess = application => ({type: FETCH_CREATE_APPLICATIONS_SUCCESS, application})

export const fetchCreateApplicationByOnlineWithMBank = data => {
    const clientKey = localStorage.getItem('client_key');
    console.log('[here]')
    return dispatch => {
        return axiosBase.post(baseUrl + ROUTE_APPLICATION_CREATE, data, {
            headers: {
                Authorization: clientKey ? `Token ${clientKey}` : ""
            }
        }).then(
            (response) => {
                dispatch(fetchCreateApplicationSuccess(response.data))
                dispatch(fetchSalonPostPayMethod(response.data.id, response.data.number))
                if (response.data.token) {
                    localStorage.setItem('client_key', response.data.token)
                }
            },
            (error) => {
                NotificationManager.error(JSON.stringify(error.response.data))
            }
        ).catch(err => console.log(err))
    }
}


// export const FETCH_SALON_PAY_METHOD_CONFIRM_REQUEST = 'FETCH_SALON_PAY_METHOD_REQUEST';
// export const FETCH_SALON_PAY_METHOD_CONFIRM_SUCCESS = 'FETCH_SALON_PAY_METHOD_SUCCESS';
// export const FETCH_SALON_PAY_METHOD_CONFIRM_FAILURE = 'FETCH_SALON_PAY_METHOD_FAILURE';
// const fetchSalonPayMethodConfirmRequest = () => ({type: FETCH_SALON_PAY_METHOD_MBANK_REQUEST})
// const fetchSalonGetPayMethodConfirmSuccess = payMethod => ({type: FETCH_SALON_PAY_METHOD_MBANK_SUCCESS, payMethod});
// const fetchSalonPayMethodConfirmFailure = () => ({type: FETCH_SALON_PAY_METHOD_MBANK_FAILURE})
//
// export const fetchSalonPostPayMethodConfirm = (applicationId, phoneNumber) => {
//     return dispatch => {
//         dispatch(fetchSalonPayMethodMBankRequest())
//         const data = {application_id: applicationId, phone_number: phoneNumber}
//         return axios.post(ROUTE_PAY_MBANK_METHOD, data).then(
//             response => {
//                 dispatch(fetchSalonGetPayMethodMBankSuccess(response.data))
//             },
//             () => {
//                 dispatch(fetchSalonPayMethodMBankFailure())
//                 history.push('/')
//                 NotificationManager.error(ERROR_TEXT)
//             }
//         ).catch(err => console.log(err))
//     }
// }