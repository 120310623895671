import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";

import Arrow from "../../../components/kit/Arrow/Arrow";
import Filter from "../../../components/kit/Filter/Filter";
import FilterPage from "../FilterPage/FilterPage";
import LoadingComponent from "../OnlineRecord/LoadingComponent/LoadingComponent";
import {getListSalons, getListSalonPagination} from '../../../store/Main/listSalons/listSalonsAction'
import {setBtnActiveSalon} from "../../../store/core/coreActions";

import './index.scss'
import {getDefaultPage} from "../../../global/Helpers/helpers";
import {LINK_ABOUT_SALON, LINK_ABOUT_SALON_DEFAULT} from "../../../global/Helpers/Links";

const ListSalonsPage = ({
        listSalon,
        response,
        getListSalons,
        paginationResponse,
        nextPage,
        getListSalonPagination,
        history,
        setBtnActiveSalon,
        match
}) => {

    const [getResponse, setResponse] = useState(false)
    const [filterPageIsOpen, setFilterPage] = useState(false)
    const [searchTitle, setSearchTitle] = useState('')
    const [defaultCheck, setDefaultCheck] = useState(null)

    useEffect(() => {
        document.body.style.overflow = filterPageIsOpen ? 'hidden' : 'auto';
    }, [filterPageIsOpen])

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        getListSalons()
        setBtnActiveSalon()
        setDefaultCheck(getDefaultPage(match.params.default))

        return () => {
            document.removeEventListener('scroll', scrollHandler)
        }
    }, [])

    useEffect(() => {
        paginationPage()
    }, [getResponse])

    const scrollHandler = (e) => {
        if(e.target.documentElement.scrollHeight -
            (e.target.documentElement.scrollTop + window.innerHeight) < 100
        ){
            setResponse(true)
        }
    }

    const paginationPage = () => {
        if(getResponse && !(paginationResponse) && nextPage){
            getListSalonPagination(nextPage)
        }
        setResponse(false)
    }

    const setTitle = e => setSearchTitle(e.target.value)

    const onSubmitSearch = () => {
        history.push(`/search_result/salons/${defaultCheck ? 'default' : ''}?search=${searchTitle}`)
    }

    if(response) return <LoadingComponent />

    return (
        <div className="container">
            <div className="online-record-root">
                <Arrow
                    title="Салоны"
                    history={history}
                />

                <Filter
                    placeholder="Поиск салонов"
                    onClick={() => setFilterPage(!filterPageIsOpen)}
                    setTitle={setTitle}
                    onSubmit={onSubmitSearch}
                    value={searchTitle}
                />

                {filterPageIsOpen &&
                    <FilterPage
                        isOpen={filterPageIsOpen}
                        onClick={() => setFilterPage(!filterPageIsOpen)}
                        history={history}
                        btnActive={1}
                    />
                }

                <div className="list-discounts-page-salon">
                    {listSalon?.map((item, index) => {
                        return(
                            <div className="salon-item" key={index}>
                                <NavLink className="salon-item-link"
                                         to={(defaultCheck ? LINK_ABOUT_SALON_DEFAULT : LINK_ABOUT_SALON) + item.id}>
                                    {item.logo ?
                                        <img src={item.logo} className="salon-item-image" alt=""/>
                                    :
                                        <div className="salon-item-logo">
                                            <span>{item.name[0]}</span>
                                        </div>
                                    }

                                        <p className="salon-item-title">{item.main_category}</p>
                                        <h3 className="salon-item-name">{item.name}</h3>

                                        <div className="salons-item-rating">
                                            <span className="salon-rating" >{item.rating}</span>
                                            <span className="salon"/>
                                            <span className="salon-comments" >{item.reviews_count}</span>
                                        </div>
                                </NavLink>
                            </div>
                        )
                    })}
                </div>

                {nextPage ?
                    <div className="more-information">
                        {paginationResponse &&
                            <div className="lds-dual-ring" />
                        }
                    </div>
                    :
                    null
                }

            </div>
        </div>
    );

};

const mapStateToProps = state => ({
    listSalon: state.listSalon.listSalon,
    response: state.listSalon.response,
    paginationResponse: state.listSalon.paginationResponse,
    nextPage: state.listSalon.nextPage,
})

const mapDispatchToProps = dispatch => ({
    getListSalons: () => {dispatch(getListSalons())},
    getListSalonPagination: url => {dispatch(getListSalonPagination(url))},
    setBtnActiveSalon: () => {dispatch(setBtnActiveSalon())}
})

export default connect(mapStateToProps, mapDispatchToProps)(ListSalonsPage);