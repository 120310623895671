import React from 'react';

import './index.scss'

const SelectionButtons = ({titles, btnActive, setActive}) => {

    return (
        <div className="wrapper-btns">

            {titles.map((item, index) => {
                return(
                    <button
                        className={btnActive === index ? 'active' : ''}
                        onClick={() => {setActive(index)}}
                        key={index}
                    >
                        {item}
                    </button>
                )
            })}

        </div>
    );
};

export default SelectionButtons;