import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux'

import Arrow from "../../../../components/kit/Arrow/Arrow";
import ListNewRecording from "../../../../components/List/ListNewRecording/ListNewRecording";
import LoadingComponent from "../../OnlineRecord/LoadingComponent/LoadingComponent";
import {hideFilter, setBtnActiveMaster} from "../../../../store/core/coreActions";
import {getDefaultPage} from "../../../../global/Helpers/helpers";
import {getFilterSalon} from "../../../../store/Main/filterSalon/filterSalonAction";
import {getFilterMaster} from "../../../../store/Main/filterMaster/filterMasterAction";

import {
    LINK_ABOUT_MASTER,
    LINK_ABOUT_MASTER_DEFAULT,
    LINK_ABOUT_SALON,
    LINK_ABOUT_SALON_DEFAULT
} from "../../../../global/Helpers/Links";

import './index.scss'

const MASTERS = 'masters'
const SALONS = 'salons'

const ResultFilter = (props) => {

    const [defaultCheck, setDefaultCheck] = useState(null)
    const {filterMaster, filterSalon} = props
    const {history, match} = props
    const {fetchFilterSalon, fetchFilterMaster} = props
    const urlParams = new URLSearchParams(history.location.search);
    const paramsFilter = urlParams.get('id')
    const type = match.params.type === SALONS

    useEffect(() => {
        setDefaultCheck(getDefaultPage(match.params.default))
        type ? fetchFilterSalon(paramsFilter) : fetchFilterMaster(paramsFilter)
    }, [])

    if(filterMaster?.listMaster || filterSalon?.listSalon) {
        return (

            <div className="container">
                <div className="online-record-root">

                    <Arrow
                        title="Результат"
                        history={history}
                    />

                    <ListNewRecording
                        listMaster={type ? filterSalon?.listSalon?.results : filterMaster?.listMaster?.results}
                        nextPage={[filterMaster?.nextPage, filterSalon?.nextPage]}
                        btnActive={type ? 1 : 0}
                        links={defaultCheck ? [LINK_ABOUT_MASTER_DEFAULT, LINK_ABOUT_SALON_DEFAULT] : [LINK_ABOUT_MASTER, LINK_ABOUT_SALON]}
                    />

                </div>
            </div>

        );
    }
    else {
        return <LoadingComponent />
    }
};

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    hideFilter: () => {dispatch(hideFilter())},
    setBtnActiveMaster: () => {dispatch(setBtnActiveMaster())},
    fetchFilterSalon: (idServices) => {dispatch(getFilterSalon(idServices))},
    fetchFilterMaster: (idServices) => {dispatch(getFilterMaster(idServices))}
})

export default connect(mapStateToProps, mapDispatchToProps)(ResultFilter);