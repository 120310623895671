import React from 'react';

const ServiceIcon = ({width = 55, height = 55}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 80 80" fill="none">
            {/*<circle opacity="0.5" cx="40" cy="40" r="40" fill="#EEDFF2"/>*/}
            <path
                d="M51.25 25H45.5842C44.8819 24.2136 44.0214 23.5843 43.0591 23.1535C42.0968 22.7227 41.0544 22.5 40 22.5C38.9456 22.5 37.9032 22.7227 36.9409 23.1535C35.9786 23.5843 35.1181 24.2136 34.4158 25H28.75C28.0872 25.0008 27.4517 25.2644 26.9831 25.7331C26.5144 26.2017 26.2508 26.8372 26.25 27.5V53.75C26.2508 54.4128 26.5144 55.0483 26.9831 55.5169C27.4517 55.9856 28.0872 56.2492 28.75 56.25H51.25C51.9128 56.2492 52.5483 55.9856 53.0169 55.5169C53.4856 55.0483 53.7492 54.4128 53.75 53.75V27.5C53.7492 26.8372 53.4856 26.2017 53.0169 25.7331C52.5483 25.2644 51.9128 25.0008 51.25 25ZM40 25C41.3256 25.0015 42.5965 25.5288 43.5339 26.4661C44.4712 27.4035 44.9985 28.6744 45 30H35C35.0015 28.6744 35.5288 27.4035 36.4661 26.4661C37.4035 25.5288 38.6744 25.0015 40 25ZM45 45H35C34.6685 45 34.3505 44.8683 34.1161 44.6339C33.8817 44.3995 33.75 44.0815 33.75 43.75C33.75 43.4185 33.8817 43.1005 34.1161 42.8661C34.3505 42.6317 34.6685 42.5 35 42.5H45C45.3315 42.5 45.6495 42.6317 45.8839 42.8661C46.1183 43.1005 46.25 43.4185 46.25 43.75C46.25 44.0815 46.1183 44.3995 45.8839 44.6339C45.6495 44.8683 45.3315 45 45 45ZM45 40H35C34.6685 40 34.3505 39.8683 34.1161 39.6339C33.8817 39.3995 33.75 39.0815 33.75 38.75C33.75 38.4185 33.8817 38.1005 34.1161 37.8661C34.3505 37.6317 34.6685 37.5 35 37.5H45C45.3315 37.5 45.6495 37.6317 45.8839 37.8661C46.1183 38.1005 46.25 38.4185 46.25 38.75C46.25 39.0815 46.1183 39.3995 45.8839 39.6339C45.6495 39.8683 45.3315 40 45 40Z"
                fill="white"/>
        </svg>
    );
};

export default ServiceIcon;