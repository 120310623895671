import axios from "../../Api/axios-api";
import {ROUTE_FILTER_MASTER} from '../../Api/Routes'

export const FETCH_GET_FILTER_MASTER_REQUEST = 'FETCH_GET_FILTER_MASTER'
export const FETCH_GET_FILTER_MASTER_SUCCESS = 'FETCH_GET_FILTER_MASTER_SUCCESS'

const fetchGetFilterMasterRequest = () => ({type: FETCH_GET_FILTER_MASTER_REQUEST})
const fetchGetFilterMasterSuccess = data => ({type: FETCH_GET_FILTER_MASTER_SUCCESS, value: data})

export const getFilterMaster = (idServices) => {
    return dispatch => {
        dispatch(fetchGetFilterMasterRequest())
        return axios.get(ROUTE_FILTER_MASTER + idServices)
            .then(response => {
                dispatch(fetchGetFilterMasterSuccess(response.data))
            })
    }
}
