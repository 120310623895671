import React from 'react';
import {NavLink} from "react-router-dom";
import {LINK_RECORDS_SERVICES_DEFAULT, LINK_RECORDS_SERVICES} from "../../../global/Helpers/Links";
import './index.scss'

const ListServices = ({services, defaultCheck}) => {

    const listServices = services

    return (
        <div className="list-services">
            {listServices?.map((item, index) => {
                return(
                    <NavLink
                        key={index}
                        className="link-services"
                        to={defaultCheck ? `${LINK_RECORDS_SERVICES_DEFAULT + '/' + item.id}` :
                            `${LINK_RECORDS_SERVICES + '/' + item.id}`}
                    >
                        <div className="item-services">
                            <img src={item.image} alt=""/>
                            <p className="services-text">{item.name}</p>
                        </div>
                    </NavLink>
                )
            })}
        </div>
    );
};

export default ListServices;